import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputField from "components/ui/InputField";
import Button from "components/ui/Button";
import { selectAuthLoading, userLogin } from "redux/auth.slice";
import { useAppDispatch, useAppSelector } from "redux/store";
import classNames from "classnames";
import { dashboardAppointments, resetPassword } from "routes/paths";
import { fetchUserData } from "redux/user.slice";
import FormContainer from "../FormContainer";
import ActionsContainer from "../FormContainer/ActionsContainer";
import styles from "./styles.module.scss";

export default function LoginForm() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectAuthLoading);
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    dispatch(userLogin({ email, password }))
      .unwrap()
      .then(() => navigate(`/${dashboardAppointments}`))
      .then(() => dispatch(fetchUserData()))
      .catch((rejectedValueOrSerializedError) =>
        setError(rejectedValueOrSerializedError)
      );
  };

  return (
    <FormContainer>
      <h2 className="FormComponent-title">Log in to your HiDoc account</h2>
      <div className={classNames({ [styles.loadingCover]: isLoading })}>
        <InputField
          value={email}
          onChange={handleChangeEmail}
          label="Email"
          id="email"
          type="email"
          passwordError={error}
          showError={!!error}
        />
        <InputField
          value={password}
          onChange={handleChangePassword}
          label="Password"
          id="password"
          type="password"
          error={error}
          showError={!!error}
        />
      </div>
      <ActionsContainer>
        <Button
          text="Continue"
          size="large"
          color="blue"
          handleClick={handleLogin}
          withArrow
          isLoading={isLoading}
        />
      </ActionsContainer>
      <div className={styles.signUp_cta}>
        <span>Don’t have an account? </span>
        <Link to="/register">Sign up here</Link>
      </div>
      <div className={styles.tpLinks}>
        <Link to="terms-of-use">Terms of Service</Link>
        <span>|</span>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      <div className={styles.cc}>
        <p>{new Date().getFullYear()} Copyright HiDoc</p>
      </div>
    </FormContainer>
  );
}
