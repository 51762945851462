import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/store";
import { saveSecondStep, selectSecondStep } from "redux/registration.slice";
import Button from "components/ui/Button";
import InputField from "components/ui/InputField";
import useValidate from "utils/validation";
import { VALIDATE_EMAIL, VALIDATE_PASSWORD } from "utils/validation/constants";
import client, { Events } from "services/EventEmitter";
import ActionsContainer from "../FormContainer/ActionsContainer";
import PasswordErrors from "./PasswordErrors";

interface Props {
  nextStep: (stepId: number) => void;
}

export default function SecondStep({ nextStep }: Props) {
  const dispatch = useAppDispatch();
  const secondStep = useAppSelector(selectSecondStep);

  const [email, setEmail] = useState<string>(secondStep.email);
  const [password, setPassword] = useState<string>(secondStep.password);

  const [isEmailValid, emailErrors] = useValidate(email, VALIDATE_EMAIL);
  const [isPasswordValid, passwordErrors] = useValidate(
    password,
    VALIDATE_PASSWORD
  );

  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    setHaveErrors(!isEmailValid || !isPasswordValid);
  }, [isEmailValid, isPasswordValid]);

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handleContinue = () => {
    if (haveErrors) {
      client.emit(Events.SCROLL_TO_TOP);
      setShowErrors(true);
      return;
    }

    dispatch(
      saveSecondStep({
        email,
        password,
      })
    );
    nextStep(3);
  };

  return (
    <>
      <h2 className="FormComponent-title">Choose your login details</h2>
      <p className="FormComponent-description">
        These will be the credentials you use to log in to the service.
      </p>
      <InputField
        value={email}
        onChange={handleChangeEmail}
        error={emailErrors[0]}
        showError={showErrors}
        label="Email address"
        id="emailAddress"
        type="email"
      />
      <InputField
        value={password}
        onChange={handleChangePassword}
        showError={showErrors}
        passwordError={showErrors && passwordErrors[0]}
        label="Choose a password"
        id="password"
        type="password"
        hideForgotPasswordLink={true}
      />

      <PasswordErrors errors={passwordErrors} showErrors={showErrors} />

      <ActionsContainer>
        <Button
          text="Continue"
          descriptionText="Provide contact details"
          size="large"
          color="blue"
          handleClick={handleContinue}
          withArrow
        />
      </ActionsContainer>
    </>
  );
}
