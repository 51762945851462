import { MouseEvent } from "react";
import { ReactComponent as GreenCheckmark } from "assets/images/greenCheckmark.svg";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface Props {
  children: JSX.Element | JSX.Element[];
}

function OptionsWrapper({ children }: Props) {
  return <div className={styles.optionsWrapper}>{children}</div>;
}

export default OptionsWrapper;

interface OptionProps {
  id: string;
  title: string;
  description: string;
  icon: JSX.Element;
  active: boolean;
  disabled?: boolean;
  handleSelect: (event: MouseEvent<HTMLHeadingElement>) => void;
  additionalInfo?: JSX.Element | null;
}

export function Option({
  id,
  title,
  description,
  icon,
  active,
  disabled = false,
  handleSelect,
  additionalInfo,
}: OptionProps) {
  return (
    <div className={styles.option}>
      <div
        id={id}
        className={classNames([styles.singleOptionWrapper], {
          [styles.activeOption]: active,
          [styles.optionDisabled]: disabled,
        })}
        onClick={handleSelect}
        role="button"
        tabIndex={0}
      >
        {active ? <GreenCheckmark /> : icon}
        <div className={styles.optionInfo}>
          <p className={styles.optionTitle}>{title}</p>
          <span className={styles.optionDescription}>{description}</span>
        </div>
      </div>
      {additionalInfo}
    </div>
  );
}
