// Error messages for UI
export const ERROR_FIRST_NAME_EMPTY = "Please add a first name";
export const ERROR_LAST_NAME_EMPTY = "Please add a last name";
export const ERROR_DATE_OF_BIRTH_NOT_VALID_DATE =
  "Please add a valid date of birth";
export const ERROR_GENDER_NOT_SELECTED = "Please add your sex at birth";
export const ERROR_EMAIL_EMPTY =
  "Please enter a valid email address to continue";
export const ERROR_EMAIL_NOT_VALID =
  "Please enter a valid email address to continue";
export const ERROR_PASSWORD_ONE_NUMBER = "Password must contain one number";
export const ERROR_PASSWORD_ONE_UPPERCASE_LETTER =
  "Password must contain one uppercase letter";
export const ERROR_PASSWORD_ONE_SYMBOL =
  "Password must have at least one symbol (e.g. !@#$)";
export const ERROR_PASSWORD_LENGTH = "Password must be at least 8 characters";
export const ERROR_PHONE_EMPTY =
  "Please enter a valid phone number to continue";
export const ERROR_PHONE_ONLY_NUMBERS =
  "Please enter a valid phone number to continue";
export const ERROR_ADDRESS_EMPTY = "Please add a valid address";
export const ERROR_CITY_NOT_SELECTED = "Please add a town or city";
export const ERROR_STATE_NOT_SELECTED = "Plese select state";
export const ERROR_ZIP_EMPTY = "Please enter zip";
export const ERROR_INSURANCE_ID_EMPTY = "Please enter Insurance ID";
export const ERROR_ZIP_NOT_VALID_FORMAT = "Zip is not in valid format";
export const ERROR_PCP_NAME_EMPTY =
  "Please add your current primary care physician";
export const ERROR_PCP_ADDRESS_EMPTY =
  "Please add your primary care physician's address";
export const ERROR_APPOINTMENT_PHONE_EMPTY = "You need to enter phone number";
export const ERROR_HEALTH_CONCERN_DESCRIPTION_EMPTY =
  "Please enter your health concern";
export const ERROR_HEALTH_CONCERN_DESCRIPTION_OVER_2000 =
  "Health concern description should be less than 2000 characters long";
export const TIME_SLOT_NOT_SELECTED = "Time slot not selected";
export const INSURANCE_PROVIDER_NOT_SELECTED =
  "Please add your current insurance provider";
export const INSURANCE_NUMBER_NOT_SELECTED =
  "Please add your membership id or insurance number";
export const INSURANCE_REGISTERED_ADDRESS =
  "Please add your insurance registered address";
