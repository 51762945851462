import { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "components/ui/Button";
import { ReactComponent as AppointmentsProfileIcon } from "assets/images/appointmentsProfileIcon.svg";
import classNames from "classnames";
import { flowAppointment, whyToChooseUs } from "routes/paths";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  fetchUserTags,
  PatientTagsEnum,
  selectHideMedicalInformation,
  selectLoadingUserTags,
  selectTag,
  selectUser,
} from "redux/user.slice";
import Modal from "components/shared/Modal";
import { IModalData, SpeakToUs } from "components/forms/ChooseYourService";
import { removeItem } from "utils/localStorageUtils";
import styles from "./styles.module.scss";
import AppointmentsList from "./AppointmentsList";

// PCP not appropriate red design is commented out if any time in future
// it would be required to enable it
function Appointments() {
  const user = useAppSelector(selectUser);
  const tag = useAppSelector(selectTag) as PatientTagsEnum;
  const isLoadingTags = useAppSelector(selectLoadingUserTags);
  const medicalFormFilled = useAppSelector(selectHideMedicalInformation);
  const username = user ? `${user.firstName} ${user.lastName}` : null;
  const dispatch = useAppDispatch();
  const [modalData, setModalData] = useState<IModalData | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleClearPaymentError = () => {
    searchParams.delete("paymentError");
    searchParams.delete("slotReserved");
    setSearchParams(searchParams);
    removeItem("stripe_session_id");
  };

  useLayoutEffect(() => {
    if (searchParams.get("paymentError") && searchParams.get("slotReserved")) {
      setModalData({
        modalTitle: "Problem confirming appointment",
        modalDescription: (
          <SpeakToUs
            customText="Sorry, but there was a problem with your appointment booking. A payment has been taken, but don’t worry,
            we can fix it. You just need to contact us:"
            customContactUs
          />
        ),
      });
    }
    handleClearPaymentError();
  }, [searchParams]);

  const handleCloseModal = () => setModalData(null);

  return (
    <div className={styles.appointmentsDashboardPadding}>
      <h2 className="FormComponent-title">Appointments</h2>

      {/* profile box */}
      <section
        className={classNames(styles.appointments_profileBoxWrapper, {
          [styles.pcpConfirmed]: tag === PatientTagsEnum.PCP_CONFIRMED,
          // [styles.pcpNotAppropriate]:
          // tag === PatientTagsEnum.PCP_NOT_APPROPRIATE,
        })}
      >
        <div className={styles.svgFill}>
          <AppointmentsProfileIcon fill="#0A395C" />
        </div>
        <div className={styles.appointments_profileBoxData}>
          <p
            className={classNames(
              styles.appointments_profileBoxName,
              styles.userText
            )}
          >
            {username}
          </p>
          <UserDashboardDetails tag={tag} />
        </div>
      </section>

      {/* Add medical information */}
      {/* <div className={styles.appointments_addMedicalInformation}>
        <Link to="/">Add medical information</Link>
        <Link to="/">Add information</Link>
      </div> */}

      {/* Add insurance details */}
      {/* <div className={styles.appointments_addMedicalInformation}>
        <Link to="/">Add insurance details</Link> 
        <Link to="/">Add information</Link>
      </div> */}

      {!medicalFormFilled && (
        <div className={styles.appointments_addMedicalInformation}>
          <Link to="/medical-information">Add medical information</Link>
          <Link to="/medical-information">Add information</Link>
        </div>
      )}

      {/* Upcoming appointments */}
      <AppointmentsList />
      <div className={styles.appointment_ctaButton}>
        <Button
          text="Book appointment"
          size="small"
          color="blue"
          isLoading={isLoadingTags}
          handleClick={() =>
            dispatch(fetchUserTags()).then(() => navigate(flowAppointment))
          }
        />
      </div>
      <Modal
        isOpened={Boolean(modalData?.modalTitle)}
        handleClose={handleCloseModal}
        title={String(modalData?.modalTitle)}
        warningColor={modalData?.warningColor}
        buttons={
          <>
            {modalData?.buttons ? (
              <div className={styles.modalButtonSpacing}>
                <Button
                  text={modalData.buttons.buttonOneText}
                  color={
                    modalData?.warningColor ? "red_outline" : "outlined_blue"
                  }
                  size="small"
                  handleClick={modalData.buttons.buttonOneHandler}
                />
                <Button
                  text={modalData.buttons.buttonTwoText}
                  color="blue"
                  size="small"
                  handleClick={modalData.buttons.buttonTwoHandler}
                />
              </div>
            ) : (
              <Button
                text={modalData?.speakToUs ? "Continue" : "Ok, got it"}
                color="blue"
                size="small"
                handleClick={handleCloseModal}
              />
            )}
          </>
        }
      >
        <>{modalData?.modalDescription}</>
      </Modal>
    </div>
  );
}

const UserDashboardDetails = ({ tag }: { tag: PatientTagsEnum }) => {
  const [whyUsModalOpened, setWhyUsModalOpened] = useState<boolean>(false);

  const closeWhyUsModal = () => {
    setWhyUsModalOpened(false);
  };

  const openWhyUsExternalPage = () => {
    window.open(whyToChooseUs, "_blank", "noopener,noreferrer");
    closeWhyUsModal();
  };

  if (
    tag === PatientTagsEnum.PCP_PENDING ||
    tag === PatientTagsEnum.PCP_CONFIRMED ||
    tag === PatientTagsEnum.PCP_NOT_APPROPRIATE ||
    tag === PatientTagsEnum.UNDER_18
  ) {
    return (
      <>
        <p
          className={classNames(
            styles.appointments_profileBoxUserType,
            styles.userText
          )}
        >
          {getPCPStatusText(tag)}
        </p>
        {/* {tag === PatientTagsEnum.PCP_NOT_APPROPRIATE && (
          <>
            <div className={styles.paragraphDivWrapper}>
              <p className={styles.paragraphTextTitle}>
                <b>What happens next?</b>
              </p>
              <p className={styles.paragraphText}>
                Please speak to a member of our support team and we can fix this
                for you.
              </p>
            </div>
            <div className={styles.contactInfo}>
              <p className={styles.paragraphText}>
                <b>Call us:</b> (808) 400-4113
              </p>
              <p>
                <b>Email us:</b>{" "}
                <a
                  className={styles.link}
                  href="mailto:hidoc@cloudwellhealth.com"
                >
                  hidoc@cloudwellhealth.com
                </a>
              </p>
              <p />
            </div>
          </>
        )} */}
      </>
    );
  }

  return (
    <>
      <p className={styles.appointments_profileBoxUserType}>Telehealth user</p>
      <div className={styles.appointments_profileBoxButton}>
        <Button
          size="large"
          text="Make us your primary care provider"
          to="/pcp"
          color="gray"
          className={styles.appointments_profileBoxButton_button}
        />
      </div>
      <span
        className={styles.appointments_profileBoxLink}
        onClick={() => setWhyUsModalOpened(true)}
        tabIndex={0}
        role="button"
      >
        Why choose us as your primary care provider?
      </span>
      {/* 
      Modal  
      */}
      <Modal
        isOpened={whyUsModalOpened}
        handleClose={closeWhyUsModal}
        title="Why choose us as your primary care provider?"
        buttons={
          <>
            <Button
              text="Close"
              color="red"
              size="small"
              handleClick={closeWhyUsModal}
            />
          </>
        }
      >
        <>
          Welcome to HiDoc Online, a digital healthcare service run by Hawaiian
          doctors for the people of Hawaii. Unlike traditional Primary Care,
          HiDoc Online works around YOUR schedule with convenient on-demand
          access to our physicians and medical care team by phone or video, from
          anywhere, 7 days a week. Our team of local Hawaii doctors embody the
          Aloha Spirit and will become your long-term health care partner,
          offering safe and affordable medical care for you and your ‘Ohana,
          whenever you need it. All you need is a smartphone, tablet or laptop!
        </>
      </Modal>
    </>
  );
};

const getPCPStatusText = (tag: PatientTagsEnum) => {
  if (tag === PatientTagsEnum.PCP_PENDING) return "PCP user (pending)";

  if (tag === PatientTagsEnum.PCP_CONFIRMED) return "PCP user";

  // if (tag === PatientTagsEnum.PCP_NOT_APPROPRIATE)
  // return "We were unable to establish care";

  if (tag === PatientTagsEnum.UNDER_18) return "Telehealth user (child)";

  return "Telehealth user";
};

export default Appointments;
