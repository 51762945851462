import PageContainer from "components/layout/PageContainer";
import AppointmentReservedForm from "components/forms/AppointmentReserved";

function AppointmentReserved() {
  return (
    <div className="fullPage-blueBackground">
      <PageContainer>
        <AppointmentReservedForm />
      </PageContainer>
    </div>
  );
}

export default AppointmentReserved;
