import PageContainer from "components/layout/PageContainer";
import { Link } from "react-router-dom";

function NotFound(): JSX.Element {
  return (
    <div>
      <PageContainer>
        <h1>Page Not Found</h1>
        Please go to <Link to="/">homepage</Link>
      </PageContainer>
    </div>
  );
}

export default NotFound;
