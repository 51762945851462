import { useState, useEffect } from "react";
import { useLocation, Location } from "react-router-dom";
import Container from "@mui/material/Container";
import { hidePageContainer } from "utils/getActiveForm";
import classNames from "classnames";

interface Props {
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  className?: string | undefined;
}

function PageContainer({ children, className }: Props) {
  const location: Location = useLocation();
  const [activeForm, setActiveForm] = useState<string | false>(false);

  useEffect(() => {
    const form = hidePageContainer(location.pathname);
    setActiveForm(form);
  }, [location]);

  return (
    <Container
      maxWidth="xl"
      className={classNames([className], {
        "page-container": activeForm,
      })}
    >
      {children}
    </Container>
  );
}

export default PageContainer;
