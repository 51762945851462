import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as GoBackArrow } from "assets/images/goBackArrow.svg";
import userService from "services/userService";
import { AxiosError, AxiosResponse } from "axios";
import LoadingSpinner from "components/shared/LoadingSpinner";
import classNames from "classnames";
import { formatAppointment, isPastAppointment } from "utils/appointmentUtils";
import { useAppSelector } from "redux/store";
import { selectPhoneNumber, selectUsername } from "redux/user.slice";
import { formatToPhone } from "utils/transform/formatToPhone";
import { dashboardAppointments } from "routes/paths";
import { IModalData, SpeakToUs } from "components/forms/ChooseYourService";
import Modal from "components/shared/Modal";
import Button from "components/ui/Button";
import PrepareAppointment from "components/shared/Modal/PrepareAppointment";
import styles from "./styles.module.scss";
import { VideoAppointmentLink } from "./AppointmentListItem";
import { IAppointmentDTO } from "./AppointmentsList";

const AppointmentDetails = () => {
  const { id } = useParams();
  const { appointmentData, error } = useAppointmentDetails(id);
  const [modalData, setModalData] = useState<IModalData | null>(null);

  if (error)
    return (
      <div
        className={classNames([styles.appointmentDetails, styles.height100])}
      >
        <div className={styles.emptySpaceWrapper}>
          <span>Something went wrong</span>
        </div>
      </div>
    );

  if (!appointmentData)
    return (
      <div
        className={classNames([styles.appointmentDetails, styles.height100])}
      >
        <div className={styles.emptySpaceWrapper}>
          <LoadingSpinner size={30} classes={[styles.loadingSpinner]} />
        </div>
      </div>
    );

  const isPast = isPastAppointment(
    appointmentData.scheduledDate,
    appointmentData.appointmentType.duration
  );

  return (
    <>
      <div className={styles.appointmentDetails}>
        <Header />
        <h2 className={styles.title}>Appointment details</h2>
        <VideoAppointmentLink
          item={appointmentData}
          AppointmentDetailsComponent={
            <HideAppointmentLink
              isVideo={Boolean(appointmentData.zoomLink)}
              isPastAppointment={isPast}
              setModalData={setModalData}
            />
          }
        />
        <AppointmentDisplay
          data={appointmentData}
          isPastAppointment={isPast}
          setModalData={setModalData}
        />
      </div>
      <AppointmentsModal modalData={modalData} setModalData={setModalData} />
    </>
  );
};

const AppointmentDisplay = ({
  data,
  isPastAppointment,
  setModalData,
}: {
  data: IAppointmentDTO;
  isPastAppointment: boolean;
  setModalData: any;
}) => {
  const username = useAppSelector(selectUsername);
  const phoneNumber = useAppSelector(selectPhoneNumber);

  const { contactType, date, hours } = formatAppointment(data);
  const isPhone = contactType === "Phone appointment";

  const handleRescheduleModal = () => {
    setModalData({
      modalTitle: "Rescheduling appointments",
      modalDescription: (
        <SpeakToUs customText="Need to reschedule or cancel this appointment?" />
      ),
      speakToUs: true,
    });
  };

  const handleHowToReschedule = () => {
    setModalData({
      modalTitle: "How to prepare for and join a consultation",
      modalDescription: <PrepareAppointment />,
    });
  };

  return (
    <>
      <div className={styles.appointmentData}>
        <div className={styles.section}>
          <span className={styles.sectionTitle}>Patient name</span>
          <span>{username}</span>
        </div>
        <div className={styles.section}>
          <span className={styles.sectionTitle}>Appointment type</span>
          <span>
            <span>{contactType}</span>
            {isPhone && <span>{` ${formatToPhone(phoneNumber)}`}</span>}
            {/* {isPhone && !isPastAppointment && (
              <button
                className={classNames([
                  styles.changeNumber,
                  styles.buttonWrapper,
                  styles.cursorPointer,
                ])}
              >
                Change number
              </button>
            )} */}
          </span>
        </div>
        <div className={styles.section}>
          <span className={styles.sectionTitle}>Appointment date and time</span>
          <span className={styles.tooLongDescription}>
            <span>{`${date}, ${hours}`}</span>
            {!isPastAppointment && (
              <button
                onClick={handleRescheduleModal}
                className={classNames([
                  styles.changeNumber,
                  styles.buttonWrapper,
                  styles.cursorPointer,
                  styles.tooLongDescription,
                ])}
              >
                Contact us to reschedule
              </button>
            )}
          </span>
        </div>
        <div className={styles.section}>
          <span className={styles.sectionTitle}>
            {data.healthConcern
              ? "Health concern"
              : "Appointment to establish PCP care."}
          </span>
          <span>{data.healthConcern}</span>
        </div>
      </div>
      {!isPastAppointment && (
        <div className={styles.appointmentData}>
          <div className={styles.section}>
            <span
              className={classNames([styles.sectionTitle, styles.helpfulLinks])}
            >
              Helpful links
            </span>
            <span
              className={classNames([styles.buttonLink, styles.helpfulLink])}
              role="button"
              tabIndex={0}
              onClick={handleHowToReschedule}
            >
              How to prepare for and join a consultation.
            </span>
          </div>
        </div>
      )}
    </>
  );
};

const Header = () => {
  const navigate = useNavigate();

  const handleGoBack = () => navigate(`/${dashboardAppointments}`);

  return (
    <div className={styles.header}>
      <div
        className={styles.goBackWrapper}
        role="button"
        tabIndex={0}
        onClick={handleGoBack}
      >
        <GoBackArrow />
        <button
          onClick={handleGoBack}
          className={classNames([
            styles.buttonWrapper,
            styles.cursorPointer,
            styles.buttonOverride,
          ])}
        >
          <span className={styles.goBackText}>Go back</span>
        </button>
      </div>
    </div>
  );
};

const HideAppointmentLink = ({
  isVideo,
  isPastAppointment,
  setModalData,
}: {
  isVideo: boolean;
  isPastAppointment: boolean;
  setModalData: any;
}) => {
  return (
    <div className={styles.linkSpace}>
      {isPastAppointment ? (
        <PastAppointmentText setModalData={setModalData} />
      ) : isVideo ? (
        <VideoPlaceholder />
      ) : (
        <PhonePlaceholder />
      )}
    </div>
  );
};

const VideoPlaceholder = () => (
  <span>
    A link to join this consultation will appear here{" "}
    <strong>20 minutes</strong> before the scheduled start time
  </span>
);

const PhonePlaceholder = () => (
  <span>
    The clinician will call you on your registered phone number at the scheduled
    appointment start time.
  </span>
);

const PastAppointmentText = ({ setModalData }: { setModalData: any }) => {
  const handleSpeakToUs = () => {
    setModalData({
      modalTitle: "Contact us",
      modalDescription: (
        <div className={styles.modalStyle}>
          Question About This Appointment? Please contact us on{" "}
          <span>(808) 400-4113</span> or <span>hidoc@cloudwellhealth.com</span>
        </div>
      ),
      speakToUs: true,
    });
  };

  return (
    <span>
      To access your visit notes, please{" "}
      <span
        tabIndex={0}
        role="button"
        className={classNames([styles.buttonWrapper, styles.buttonLink])}
        onClick={handleSpeakToUs}
      >
        contact our clinical co-ordinators.
      </span>
    </span>
  );
};

const AppointmentsModal = ({
  modalData,
  setModalData,
}: {
  modalData: IModalData | null;
  setModalData: any;
}) => {
  const handleCloseModal = () => setModalData(null);

  return (
    <Modal
      isOpened={Boolean(modalData?.modalTitle)}
      handleClose={handleCloseModal}
      title={String(modalData?.modalTitle)}
      warningColor={modalData?.warningColor}
      buttons={
        <>
          <Button
            text="Continue"
            color="blue"
            size="small"
            handleClick={handleCloseModal}
          />
        </>
      }
    >
      <>{modalData?.modalDescription}</>
    </Modal>
  );
};

const useAppointmentDetails = (id: string | undefined) => {
  const [error, setError] = useState<string | null>(null);
  const [appointmentData, setAppointmentData] =
    useState<IAppointmentDTO | null>(null);

  useEffect(() => {
    if (id)
      userService
        .getAppointment(id)
        .then((res: AxiosResponse<IAppointmentDTO>) =>
          setAppointmentData(res.data)
        )
        .catch((err: AxiosError) => {
          if (err.response?.status === 404) {
            setError("Appointment not found");
            return;
          }
          setError("Something went wrong");
        });
    else setError("Appointment not found");
  }, [id]);

  return { appointmentData, error };
};

export default AppointmentDetails;
