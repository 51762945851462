const insuranceProviders = [
  {
    value: "Aetna",
    display: "Aetna",
  },
  {
    value: "Alohacare",
    display: "Alohacare",
  },
  {
    value: "Blue Cross Blue Shield",
    display: "Blue Cross Blue Shield",
  },
  {
    value: "Cigna",
    display: "Cigna",
  },
  {
    value: "HCHA",
    display: "HCHA",
  },
  {
    value: "HMA",
    display: "HMA",
  },
  {
    value: "HMAA",
    display: "HMAA",
  },
  {
    value: "HMSA",
    display: "HMSA",
  },
  {
    value: "HMSA Quest",
    display: "HMSA Quest",
  },
  {
    value: "Humana",
    display: "Humana",
  },
  {
    value: "MDX",
    display: "MDX",
  },
  {
    value: "Medicaid",
    display: "Medicaid",
  },
  {
    value: "Medicare",
    display: "Medicare",
  },
  {
    value: "Multiplan",
    display: "Multiplan",
  },
  {
    value: "Ohana",
    display: "Ohana",
  },
  {
    value: "PHCS",
    display: "PHCS",
  },
  {
    value: "PSWA",
    display: "PSWA",
  },
  {
    value: "Tricare",
    display: "Tricare",
  },
  {
    value: "UHA",
    display: "UHA",
  },
  {
    value: "United Health Care",
    display: "United Health Care",
  },
  {
    value: "Blue Cross",
    display: "Blue Cross",
  },
  {
    value: "Other",
    display: "Other",
  },
];

export default insuranceProviders;
