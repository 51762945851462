import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/store";
import { setUser, selectUser } from "redux/user.slice";
import Button from "components/ui/Button";
import userService from "services/userService";
import classNames from "classnames";
import styles from "./styles.module.scss";

function DeleteAccount({ handleClose }: { handleClose: Function }) {
  const user = useAppSelector(selectUser);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>("Delete Account");
  const handleDeleteAccount = () => {
    setIsLoading(true);
    userService.deleteAccount().finally(() => {
      setIsLoading(false);
      setIsDisabled(true);
      setButtonText("Request Sent");
    });
  };

  return (
    <>
      <div className={classNames({ [styles.loadingCover]: isLoading })}></div>
      <div className={styles.buttonWrapper}>
        <Button
          text={buttonText}
          size="large"
          color="blue"
          handleClick={handleDeleteAccount}
          isLoading={isLoading}
          disabled={isDisabled}
        />
      </div>
    </>
  );
}

export default DeleteAccount;
