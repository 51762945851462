import { useState, useEffect } from "react";
import DobPicker from "components/ui/DobPicker";
import InputField from "components/ui/InputField";
import useValidate from "utils/validation";
import {
  VALIDATE_DATE_OF_BIRTH,
  VALIDATE_FIRST_NAME,
  VALIDATE_IS_GENDER_SELECTED,
  VALIDATE_LAST_NAME,
} from "utils/validation/constants";
import { Gender } from "redux/registration.slice";
import client, { Events } from "services/EventEmitter";
import Dropdown from "components/ui/Dropdown";
import { useAppSelector } from "redux/store";
import { selectUser } from "redux/user.slice";
import Button from "components/ui/Button";
import Modal from "components/shared/Modal";
import styles from "./styles.module.scss";

const transformDate = (date: string): string => {
  const arr = date.split("-");
  return `${arr[1]}/${arr[2]}/${arr[0]}`;
};
function PersonalDetails({ handleClose }: { handleClose: Function }) {
  const user = useAppSelector(selectUser);

  const [contactUsModalOpened, setContactUsModalOpened] =
    useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(user?.firstName as string);
  const [lastName, setLastName] = useState<string>(user?.lastName as string);
  const [dateOfBirth, setDateOfBirth] = useState<string>(
    transformDate(user?.dateOfBirth as string)
  );
  const [gender, setGender] = useState<Gender>(user?.sexAtBirth as Gender);

  const [isFirstNameValid, firstNameErrors] = useValidate(
    firstName,
    VALIDATE_FIRST_NAME
  );

  const [isLastNameValid, lastNameErrors] = useValidate(
    lastName,
    VALIDATE_LAST_NAME
  );

  const [isDobValid, dobErrors] = useValidate(
    dateOfBirth,
    VALIDATE_DATE_OF_BIRTH
  );

  const [isGenderSelected, genderErrors] = useValidate(
    gender,
    VALIDATE_IS_GENDER_SELECTED
  );

  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    setHaveErrors(
      !isFirstNameValid || !isLastNameValid || !isDobValid || !isGenderSelected
    );
  }, [isFirstNameValid, isLastNameValid, isDobValid, isGenderSelected]);

  const handleChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleChangeDateOfBirth = (dob: string) => {
    setDateOfBirth(dob);
  };

  const handleChangeGender = (selected: Gender) => {
    setGender(selected);
  };

  const genders = [
    { value: Gender.Male, display: Gender.Male },
    { value: Gender.Female, display: Gender.Female },
  ];

  const handleEditPersonalDetail = () => {
    if (haveErrors) {
      client.emit(Events.SCROLL_TO_TOP);
      setShowErrors(true);
    }

    // save()
  };

  return (
    <>
      <p className={styles.dataNotEditable}>
        Personal details added during sign up can’t be changed.
      </p>
      <p className={styles.contactUs}>
        Need to edit these details? Please{" "}
        <span
          className="link"
          role="button"
          tabIndex={0}
          onClick={() => setContactUsModalOpened(true)}
        >
          contact us.
        </span>
      </p>

      <InputField
        value={firstName}
        onChange={handleChangeFirstName}
        error={firstNameErrors[0]}
        showError={showErrors}
        disabled={true}
        label="First name"
        id="firstName"
      />

      <InputField
        value={lastName}
        onChange={handleChangeLastName}
        error={lastNameErrors[0]}
        showError={showErrors}
        disabled={true}
        label="Last name"
        id="lastName"
      />
      <DobPicker
        initialValue={dateOfBirth}
        onChange={handleChangeDateOfBirth}
        label="Date of birth"
        error={dobErrors[0]}
        showError={showErrors}
        disabled={true}
      />
      <Dropdown
        initialValue={gender}
        options={genders}
        onChange={handleChangeGender}
        label="Sex at birth"
        error={genderErrors[0]}
        showError={showErrors}
        disabled={true}
      />

      <div className={styles.buttonWrapper}>
        <Button
          text="Cancel"
          handleClick={handleClose}
          size="small"
          color="outlined_blue"
        />
        <Button
          text="Save changes"
          handleClick={() => {}}
          size="small"
          color="blue"
          disabled
        />
      </div>
      {/* 
      Modal 
      */}
      <Modal
        isOpened={contactUsModalOpened}
        handleClose={() => setContactUsModalOpened(false)}
        title="Contact us"
        buttons={
          <>
            <Button
              text="Continue"
              color="blue"
              size="small"
              handleClick={() => setContactUsModalOpened(false)}
            />
          </>
        }
      >
        <>
          If you need to update your personal details then <br /> call us on
          (808) 400-4113 or email hidoc@cloudwellhealth.com so our patient
          support team can help you.
        </>
      </Modal>
    </>
  );
}

export default PersonalDetails;
