import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface Props {
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  stackButtons?: boolean;
}

export default function ActionsContainer({
  children,
  stackButtons = false,
}: Props) {
  return (
    <div className={styles.actionsContainer}>
      <div
        className={classNames([styles.buttons], {
          [styles.stackButtons]: stackButtons,
        })}
      >
        {children}
      </div>
    </div>
  );
}
