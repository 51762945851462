import PageContainer from "components/layout/PageContainer";
import RegisterForm from "components/forms/RegisterForm";

function Register() {
  return (
    <div className="fullPage-blueBackground">
      <PageContainer>
        <RegisterForm />
      </PageContainer>
    </div>
  );
}

export default Register;
