import { useState, useEffect } from "react";
import ValidationError from "components/shared/ValidationError";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { ReactComponent as DropDownArrow } from "assets/images/dropDownArrow.svg";
import useWindowResize, { Size } from "hooks/useWindowResize";
import IDropdownOption from "utils/@types";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface Props {
  initialValue?: IDropdownOption | any;
  options: IDropdownOption[];
  onChange: Function;
  error?: string | undefined;
  showError?: boolean;
  disabled?: boolean;
  label: string;
  classes?: any;
  description?: string | undefined;
  required?: boolean;
}

export default function Dropdown({
  initialValue,
  options,
  onChange,
  error,
  showError,
  disabled = false,
  label,
  classes,
  description,
  required = true,
}: Props) {
  const size: Size = useWindowResize();

  const [selected, setSelected] = useState(initialValue);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelected(initialValue);
  }, [initialValue]);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  useEffect(() => {
    setOpen(false);
  }, [size]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const displayLabel = `${label}${required ? "*" : " (optional)"}`;

  const DropdownInput = (
    <InputBase
      className={classNames([styles.dropdownInput], {
        [styles.expandedInput]: open,
        disabledArrow: disabled,
      })}
    />
  );

  return (
    <div
      className={classNames([styles.dropDownFieldWrapper], [classes], {
        [styles.dropdownError]: showError && error && !open,
      })}
    >
      <InputLabel className={styles.label} id={label}>
        {displayLabel}
      </InputLabel>
      {description && <span className={styles.description}>{description}</span>}
      <div className={styles.formControll}>
        <Select
          value={selected}
          onChange={handleChange}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          input={DropdownInput}
          IconComponent={DropDownArrow}
          labelId={label}
          displayEmpty
          disabled={disabled}
          className={classNames(styles.muiSelectDropdownFix, {
            disabledField: disabled,
          })}
          classes={{ disabled: "disabledInput" }}
          MenuProps={{
            classes: {
              paper: styles.MuiPaper,
              list: styles.MuiList,
            },
          }}
        >
          {options.map(({ value, display }) => (
            <MenuItem value={value} className={styles.menutItem}>
              {display ? display : value}
            </MenuItem>
          ))}
        </Select>
      </div>
      <ValidationError show={showError} error={error} />
    </div>
  );
}
