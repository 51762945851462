import { ChangeEvent } from "react";
import ValidationError from "components/shared/ValidationError";
import MuiCheckbox from "@mui/material/Checkbox";
import { ReactComponent as CheckboxChecked } from "assets/images/checkboxCheckedIcon.svg";
import { ReactComponent as CheckboxEmptyIcon } from "assets/images/checkboxEmptyIcon.svg";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface Props {
  checked: boolean;
  onChange: (state: boolean) => void;
  errorMessage: string;
  showError: boolean;
  disabled?: boolean;
  description: JSX.Element;
  classes?: string;
  id: string;
  value?: string;
}

function Checkbox({
  checked,
  onChange,
  errorMessage,
  showError,
  disabled = false,
  description,
  classes,
  id,
  value,
}: Props) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <div className={classNames([styles.checkboxFieldWrapper], [classes])}>
      <div className={styles.checboxContainer}>
        <MuiCheckbox
          checked={checked}
          onChange={handleChange}
          icon={
            <CheckboxEmptyIcon
              className={classNames({
                [styles.checkBoxErrorMessage]: showError && !checked,
              })}
            />
          }
          checkedIcon={<CheckboxChecked />}
          className={styles.checkbox}
          disabled={disabled}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        />
        <p className={styles.checkbox_label}>{description}</p>
      </div>
      <ValidationError show={showError && !checked} error={errorMessage} />
    </div>
  );
}

export default Checkbox;
