import { flowAppointment } from "routes/paths";

const getActiveForm = (url: any): string | false => {
  if (url === "/register") return "register";
  if (url === "/pcp") return "pcp";
  if (url === flowAppointment) return flowAppointment;
  return false;
};

export default getActiveForm;

// Hide page container if any form is active
export const hidePageContainer = (url: any): string | false => {
  if (url === "/register") return "register";
  if (url === "/pcp") return "pcp";
  if (url === "/") return "login";
  if (url === "/reset-password") return "reset-password";
  if (url === "/sign-up-complete") return "sign-up-complete";
  if (url === "/appointment-reserved") return "appointment-reserved";
  if (url === "/book-appointment") return "book-appointment";
  return false;
};
