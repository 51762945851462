import { useAppSelector, useAppDispatch } from "redux/store";
import { selectAuth, logout } from "redux/auth.slice";
import jwt_decode from "jwt-decode";

interface IDecodedToken {
  exp: number;
}

interface IAuth {
  isAuth: boolean;
}

function useAuth(): IAuth {
  const { jwt } = useAppSelector(selectAuth);

  if (!jwt) return { isAuth: false };

  return { isAuth: true };
}

export default useAuth;
