import { useState, useRef, Dispatch, SetStateAction } from "react";
import { DayPicker, SelectSingleEventHandler } from "react-day-picker";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import useOnClickOutside from "hooks/useOnClickOutside";
import useWindowResize, { Size } from "hooks/useWindowResize";
import { formatDate, getCurrentHawaiiDate } from "utils/transform/formatDate";
import enUS from "date-fns/locale/en-US";
import { isToday } from "date-fns";
import "react-day-picker/dist/style.css";
import styles from "./styles.module.scss";
import "./date-picker.scss";

interface Props {
  day: Date;
  onChange: Dispatch<SetStateAction<Date>>;
  label: string;
  required?: boolean;
}

function DatePicker({ day, onChange, label, required = true }: Props) {
  const size: Size = useWindowResize();

  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  const datePickerRef = useRef(null);
  useOnClickOutside(datePickerRef, () => setShowDatePicker(false));

  const displayLabel = `${label}${required ? "*" : " (optional)"}`;
  const displayDate = `${formatDate(day)} ${isToday(day) ? "(today)" : ""}`;

  const DropdownInput = <InputBase className={styles.dropdownInput} />;

  const handleSelectDate: SelectSingleEventHandler = (
    value: Date | undefined
  ) => {
    if (value) onChange(value);
    setShowDatePicker(false);
  };

  const numberOfDisplayedMonths = (size.width as number) > 750 ? 2 : 1;
  const disabledDays = { before: getCurrentHawaiiDate() };

  return (
    <div className={styles.inputFieldWrapper}>
      <InputLabel className={styles.label}>{displayLabel}</InputLabel>

      {showDatePicker ? (
        <div ref={datePickerRef}>
          <DayPicker
            mode="single"
            selected={day}
            onSelect={handleSelectDate}
            numberOfMonths={numberOfDisplayedMonths}
            className={styles.dayPicker}
            locale={enUS}
            disabled={disabledDays}
            modifiersClassNames={{
              selected: styles.selected,
              today: styles.today,
            }}
          />
        </div>
      ) : (
        <Select
          onOpen={() => {
            setShowDatePicker(true);
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          className={styles.inputField}
          IconComponent={KeyboardArrowDownRoundedIcon}
          input={DropdownInput}
        >
          <MenuItem>{displayDate}</MenuItem>
        </Select>
      )}
    </div>
  );
}

export default DatePicker;
