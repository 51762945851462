import { useEffect, useState } from "react";
import FileUpload from "components/shared/FileUpload";
import { ReactComponent as InsuranceFront } from "assets/images/insuranceFront.svg";
import { DocumentType } from "redux/insurance.slice";
import { IDocument } from "components/forms/Payment";
import Modal from "components/shared/Modal";
import Button from "components/ui/Button";
import userService from "services/userService";
import { ReactComponent as LayDocumentIcon } from "assets/images/layDocumentIcon.svg";
import { ReactComponent as FocusPhotoIcon } from "assets/images/focusPhotoIcon.svg";
import { ReactComponent as LightPhotoIcon } from "assets/images/lightPhotoIcon.svg";
import styles from "./styles.module.scss";

enum UploadMessage {
  READY = "Document ready for upload",
  COMPLETE = "Document Uploaded!",
}

function UploadDocuments({ handleClose }: { handleClose: Function }) {
  const [uploadMessage, setUploadMessage] = useState<string>(
    UploadMessage.READY
  );
  const [uploadComplete, setUploadComplete] = useState<boolean>(false);
  const [showSaveLoader, setShowSaveLoader] = useState<boolean>(false);
  const [infoModalOpened, setInfoModalOpened] = useState<boolean>(false);

  const [patientUploadFileId, setPatientUploadFileId] = useState<string>("");
  const [patientUploadFile, setPatientUploadFile] = useState<IDocument | null>(
    null
  );
  useEffect(() => {
    setUploadComplete(false);
    setUploadMessage(UploadMessage.READY);
  }, [patientUploadFile]);
  const closeInfoModal = () => setInfoModalOpened(false);

  const handleSaveData = () => {
    setShowSaveLoader(true);

    try {
      if (patientUploadFile) {
        userService.uploadDocument({
          ...patientUploadFile,
          insuranceId: "",
        });
      }
    } catch (error) {
      console.log("error while uploading documents");
    }

    setUploadMessage(UploadMessage.COMPLETE);
    setUploadComplete(true);
    setShowSaveLoader(false);
  };

  return (
    <div>
      <div className={styles.hrLine} />

      <p className={styles.uploadDescription}>
        Supported file types are PNG, JPEG, PDF. <span>&nbsp;</span>
        <span
          onClick={() => setInfoModalOpened(true)}
          onKeyPress={() => console.log("Upload help...")}
          role="button"
          tabIndex={0}
        >
          {" "}
          Need some help with it?
        </span>
      </p>

      {/* File uploads */}
      <div className={styles.tabContent_wrapper}>
        <FileUpload
          id={1}
          fileUrl={patientUploadFileId}
          setFileUrl={setPatientUploadFileId}
          title="Select Document"
          icon={<InsuranceFront />}
          uploadedText={uploadMessage}
          classes={styles.fieldUploadStyle}
          error="Please try again"
          showError={false}
          fileName="patientupload"
          fileData={patientUploadFile}
          setFileData={setPatientUploadFile}
          type={DocumentType.MISC}
          side={1}
        />
      </div>

      <div className={styles.buttonWrapper}>
        <Button
          text={uploadComplete ? "Close" : "Cancel"}
          size="small"
          color="outlined_blue"
          handleClick={handleClose}
        />
        {!uploadComplete && (
          <Button
            text="Upload"
            size="small"
            color="blue"
            handleClick={handleSaveData}
            isLoading={showSaveLoader}
          />
        )}
      </div>
      {/* Modal */}
      <Modal
        isOpened={infoModalOpened}
        handleClose={closeInfoModal}
        title="Uploading your insurance documents"
        classes={styles.modalStyle}
        buttons={
          <Button
            text="Ok, got it"
            color="blue"
            size="small"
            handleClick={closeInfoModal}
          />
        }
      >
        <>
          <p className={styles.modalDescription}>
            You can share you insurance documents by photographing them with
            your smartphone.
          </p>
          <div className={styles.modalItem}>
            <LayDocumentIcon />
            <span>
              Don’t obscure any info on the document. Lay the document down flat
              rather than holding it.
            </span>
          </div>
          <div className={styles.modalItem}>
            <FocusPhotoIcon />
            <span>
              Ensure the document is fully within your <br />
              camera view finder. Ensure that your photo is <br />
              in focus, and has no glare.
            </span>
          </div>
          <div className={styles.modalItem}>
            <LightPhotoIcon />
            <span>
              Ensure your document photo is taken in good light - natural
              sunlight if possible.
            </span>
          </div>
        </>
      </Modal>
    </div>
  );
}

export default UploadDocuments;
