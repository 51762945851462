import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCurrentHawaiiDate } from "utils/transform/formatDate";
import { RootState } from "./store";

export interface FirstPcpStep {
  alreadyHavePcp: boolean;
  currentPcpName: string;
  currentPcpAddress: string;
}

export interface SecondPcpStep {
  appointmentType: "video" | "phone" | null;
  appointmentPhone: string;
}

export interface ThirdPcpStep {
  healthConcern: "yes" | "no";
  description: string;
}

export interface FourthPcpStep {
  appointmentDate: Date;
  timeSlot: string;
}

export interface PcpState {
  pcpActiveStep: number;
  pcpGoBack: number;
  firstPcpStep: FirstPcpStep;
  secondPcpStep: SecondPcpStep;
  thirdPcpStep: ThirdPcpStep;
  fourthPcpStep: FourthPcpStep;
}

const initialState: PcpState = {
  pcpActiveStep: 1,
  pcpGoBack: 0,
  firstPcpStep: {
    alreadyHavePcp: true,
    currentPcpName: "",
    currentPcpAddress: "",
  },
  secondPcpStep: {
    appointmentType: null,
    appointmentPhone: "",
  },
  thirdPcpStep: {
    healthConcern: "yes",
    description: "",
  },
  fourthPcpStep: {
    appointmentDate: getCurrentHawaiiDate(),
    timeSlot: "",
  },
};

export const pcpFlowSlice = createSlice({
  name: "pcpFlow",
  initialState,
  reducers: {
    saveFirstPcpStep: (state, action: PayloadAction<FirstPcpStep>) => {
      state.firstPcpStep = action.payload;
    },
    saveSecondPcpStep: (state, action: PayloadAction<SecondPcpStep>) => {
      state.secondPcpStep = action.payload;
    },
    saveThirdPcpStep: (state, action: PayloadAction<ThirdPcpStep>) => {
      state.thirdPcpStep = action.payload;
    },
    saveFourthPcpStep: (state, action: PayloadAction<FourthPcpStep>) => {
      state.fourthPcpStep = action.payload;
    },
    setPcpActiveStep: (state, action: PayloadAction<number>) => {
      state.pcpActiveStep = action.payload;
    },
    setPcpGoBack: (state) => {
      state.pcpGoBack += 1;
    },
    clearPcpState: () => initialState,
  },
});

export const {
  saveFirstPcpStep,
  saveSecondPcpStep,
  saveThirdPcpStep,
  saveFourthPcpStep,
  setPcpActiveStep,
  setPcpGoBack,
  clearPcpState,
} = pcpFlowSlice.actions;

// selectors
export const selectFirstPcpStep = (state: RootState) =>
  state.pcpFlow.firstPcpStep;

export const selectSecondPcpStep = (state: RootState) =>
  state.pcpFlow.secondPcpStep;

export const selectThirdPcpStep = (state: RootState) =>
  state.pcpFlow.thirdPcpStep;

export const selectFourthPcpStep = (state: RootState) =>
  state.pcpFlow.fourthPcpStep;

export const selectPcpActiveStep = (state: RootState) =>
  state.pcpFlow.pcpActiveStep;

export const selectPcpGoBack = (state: RootState) => state.pcpFlow.pcpGoBack;

export default pcpFlowSlice.reducer;
