import { useState, MouseEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  dashboardAppointments,
  flowPcp,
  flowAppointment,
  whyToChooseUs,
} from "routes/paths";
import { ReactComponent as PcpIcon } from "assets/images/pcpIcon.svg";
import { ReactComponent as AppointmentIcon } from "assets/images/appointmentIcon.svg";
import { ReactComponent as StepsCompleteIcon } from "assets/images/stepsCompleteIcon.svg";
import { ReactComponent as GreenCheckmark } from "assets/images/greenCheckmark.svg";
import { ReactComponent as ErrorIcon } from "assets/images/errorIcon.svg";
import {
  fetchUserData,
  fetchUserTags,
  selectChildTag,
  selectLoadingUserTags,
} from "redux/user.slice";
import Button from "components/ui/Button";
import Modal from "components/shared/Modal";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "redux/store";
import FormContainer from "../FormContainer";
import ActionsContainer from "../FormContainer/ActionsContainer";
import styles from "./styles.module.scss";

const PCP = "PCP";
const APPOINTMENT = "APPOINTMENT";

function SignUpComplete() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const childTag = useAppSelector(selectChildTag);

  const [selectedFlow, setSelectedFlow] = useState<string>("");
  const [showErrorState, setShowErrorState] = useState<boolean>(false);
  const [whyUsModalOpened, setWhyUsModalOpened] = useState<boolean>(false);
  const [skipModal, setSkipModal] = useState<boolean>(false);

  const isLoadingTags = useAppSelector(selectLoadingUserTags);

  const isPcp = selectedFlow === PCP;
  const isAppointment = selectedFlow === APPOINTMENT;
  const continueLink = isPcp
    ? `/${flowPcp}`
    : isAppointment
    ? flowAppointment
    : "";

  const handleSelectFlow = (event: MouseEvent<HTMLHeadingElement>) => {
    if (event.currentTarget.id === PCP && childTag) return;
    setShowErrorState(false);
    setSelectedFlow(event.currentTarget.id);
  };

  const handleContinue = () => {
    if (!continueLink) {
      setShowErrorState(true);
      return;
    }

    navigate(`${continueLink}`);
  };

  const handleWhyToChooseUsClick = () => {
    setWhyUsModalOpened(true);
  };

  const closeWhyUsModal = () => {
    setWhyUsModalOpened(false);
  };

  const openWhyUsExternalPage = () => {
    window.open(whyToChooseUs, "_blank", "noopener,noreferrer");
    closeWhyUsModal();
  };

  const handleSkip = () => {
    if (childTag) {
      navigate(`/${dashboardAppointments}`);
      return;
    }
    setSkipModal(true);
  };

  const closeSkipModal = () => {
    setSkipModal(false);
  };

  useEffect(() => {
    dispatch(fetchUserData());
  }, []);

  return (
    <FormContainer>
      <div className={styles.completeStepper}>
        <StepsCompleteIcon />
        <GreenCheckmark />
      </div>
      <h2 className={classNames("FormComponent-title", [styles.title])}>
        Sign up complete
      </h2>
      <p className="FormComponent-description">
        You can now register for our primary care service or book an
        appointment.
      </p>

      <div className={styles.wrapper}>
        {/*
         Options - duplicated!
         */}
        <div className={styles.options}>
          <div
            id={PCP}
            onClick={handleSelectFlow}
            role="button"
            tabIndex={0}
            className={classNames([styles.pcp], {
              [styles.disabled]: childTag,
              [styles.activeOption]: isPcp,
              [styles.optionError]: showErrorState,
            })}
          >
            <div className={styles.optionIcon}>
              {isPcp ? <GreenCheckmark /> : <PcpIcon />}
            </div>
            <div>
              <div className={styles.optionTitle}>Register for PCP</div>
              <div className={styles.optionDescription}>
                Make HiDoc your primary care service
              </div>
            </div>
          </div>
          <div
            id={APPOINTMENT}
            onClick={handleSelectFlow}
            role="button"
            tabIndex={0}
            className={classNames([styles.book], {
              [styles.activeOption]: isAppointment,
              [styles.optionError]: showErrorState,
            })}
          >
            <div className={styles.optionIcon}>
              {isAppointment ? <GreenCheckmark /> : <AppointmentIcon />}
            </div>

            <div>
              <div className={styles.optionTitle}>Book an appointment</div>
              <div className={styles.optionDescription}>
                Talk to a doctor over video or phone
              </div>
            </div>
          </div>
        </div>
        {showErrorState && (
          <p className={styles.errorWrapper}>
            <ErrorIcon className={styles.errorIcon} />
            <p className={styles.error}>Plese select an option</p>
          </p>
        )}
        {childTag ? (
          <p className={styles.childrenError}>
            Children can’t register for HiDoc PCP
          </p>
        ) : (
          <div
            onClick={handleWhyToChooseUsClick}
            className={styles.whyLink}
            role="button"
            tabIndex={0}
          >
            Why choose us as your primary care provider?
          </div>
        )}
      </div>
      <ActionsContainer stackButtons={true}>
        <Button
          text="Continue"
          size="large"
          color="blue"
          handleClick={handleContinue}
          withArrow
        />
        <Button
          text="Skip and do this later"
          size="large"
          color="outlined_blue"
          handleClick={handleSkip}
          withArrow
        />
      </ActionsContainer>

      {/* 
      Modal  
      */}
      <Modal
        isOpened={whyUsModalOpened}
        handleClose={closeWhyUsModal}
        title="Why choose us as your primary care provider?"
        buttons={
          <>
            <Button
              text="Close"
              color="red"
              size="small"
              handleClick={closeWhyUsModal}
            />
          </>
        }
      >
        <>
          Welcome to HiDoc Online, a digital healthcare service run by Hawaiian
          doctors for the people of Hawaii. Unlike traditional Primary Care,
          HiDoc works around YOUR schedule with convenient on-demand access to
          our physicians and medical care team by phone or video, from anywhere,
          7 days a week. Our team of local Hawaii doctors embody the Aloha
          Spirit and will become your long-term health care partner, offering
          safe and affordable medical care for you and your ‘Ohana, whenever you
          need it. All you need is a smartphone, tablet or laptop!
        </>
      </Modal>
      {/* 
      Skip modal 
      */}
      <Modal
        isOpened={skipModal}
        handleClose={closeSkipModal}
        title="Make HiDoc your PCP?"
        stackButtons
        buttons={
          <>
            <Button
              text="Book appointment now"
              color="blue"
              size="small"
              isLoading={isLoadingTags}
              to={flowAppointment}
            />
            <Button
              text="Skip and book later"
              color="outlined_blue"
              size="small"
              to={`/${dashboardAppointments}`}
            />
          </>
        }
      >
        <>
          If you would like to make HiDoc your Primary Care Provider, you will
          need to have an initial appointment to establish care and complete
          your PCP registration.
          <p>
            If you skip this, a member of our clinical support team will contact
            you to arrange your first appointment in the coming days.
          </p>
        </>
      </Modal>
    </FormContainer>
  );
}

export default SignUpComplete;
