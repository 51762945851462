import FormContainer from "components/forms/FormContainer";
import PageContainer from "components/layout/PageContainer";
import styles from "./styles.module.scss";

function TermsPage() {
  return (
    <div className="fullPage-blueBackground">
      <PageContainer className={(styles.page, styles.general)}>
        <FormContainer>
          <h2 className="FormComponent-title">
            HiDoc Online Consent to Telehealth
          </h2>
          <h3 className={styles.subtitle}>
            {" "}
            Please read all the important information below because we explain:
          </h3>
          <ul>
            <li>
              {" "}
              How our medical team consists of doctors and nurse practitioners.
            </li>
            <li>
              {" "}
              How many states require you to do a video consultation with one of
              our doctors or nurse practitioners.
            </li>
            <li>When our duty of care begins.</li>
            <li>The benefits and risks of using our service.</li>
            <li>The importance of reading all the information we provide.</li>
            <li>
              {" "}
              The importance of answering all questions fully and truthfully.
            </li>
            <li> The risks of accepting our treatment plan.</li>
            <li> The risks to electronic health information.</li>
            <li>
              {" "}
              Promo code programs from an employer, health insurer or other
              organization.
            </li>
          </ul>
          Only use our service if you’ve read this information and subsequently
          made an informed decision that our service is right for you. If you
          have any questions, please send us a message through our contact page.
          <h3 className={styles.subtitle}>Background Information</h3> Our
          medical team is made up of doctors and nurse practitioners. Whenever
          we use the term ‘doctor’ we mean both our doctors and nurse
          practitioners. Many states require you to do a video consultation with
          our medical team. The video consultation allows you to ask any
          questions and allows our medical team to judge your suitability for
          our online model of care. We are an online doctor’s office and not a
          pharmacy. If you request that your medicines be delivered to you in
          the mail, we’ll arrange for an independent pharmacy to mail your
          medicines. If you want us to send your prescription to a local
          pharmacy in your area, please message or call us and we will do so at
          no extra charge. We do not send prescriptions to pharmacies based
          outside the U.S.A. HiDocOnline.com is owned by MD Care, however, the
          service is hosted and operated by WePrescribe Inc dba HiDoc Online.
          All information in this consent agreement applies to HiDoc.
          <h3 className={styles.subtitle}> Not for emergencies</h3>I understand
          that I should never use HiDoc in a medical or psychiatric emergency. I
          understand that in an emergency, I should dial 911 or go to an
          emergency department.{" "}
          <h3 className={styles.subtitle}>When our duty of care begins</h3> I
          understand that the doctor will take responsibility for my care only
          after I have created an account, answered all the required health
          questions and provided a photo and/or have had a video visit and made
          payment, and the doctor has subsequently reviewed my request for
          treatment and the health questions that I have completed and any
          photos and/or information received from a video visit, reviewed all my
          information, and then subsequently determined that I am a good
          candidate for the telehealth services. I understand that the duty of
          care does not begin at the point of me answering questions or making
          payment or starting a video visit but at the point at which the doctor
          accepts the duty of care. I understand that the doctor has the right
          to refuse to take responsibility for my care if the doctor makes a
          professional judgment that I am not a good candidate for this service.
          I understand that making a request for treatment (by completing a
          visit in the App or Website and making payment or by starting a video
          visit) or sending a message through the app does not in and of itself
          create a duty of care or create a doctor-patient relationship. I
          understand that there may be a delay until the next business day
          before a doctor reviews my request for treatment and any messages I
          send. I understand that the only content in the App or Website that
          constitutes professional medical advice is the personalized messages
          the doctor sends me (once I have completed the health questions and
          made payment, and the doctor has subsequently taken responsibility for
          my care) and any content that the doctor links to in such messages and
          advice that a doctor provides in a video visit. No other content in
          the App or Website constitutes professional medical advice.
          Specifically the information provided in our health questions about
          who we can and cannot treat does not constitute professional medical
          advice. I understand that all other content in the App or Website does
          not constitute professional medical advice and is instead for
          information purposes only. Never disregard professional medical advice
          or delay in seeking it because of something you have read on our App
          or Website.
          <h3 className={styles.subtitle}>
            Benefits and risks of using our service
          </h3>{" "}
          I understand that by using the service, I’m seeking care that’s
          convenient and affordable. I understand that important differences
          exist between HiDoc’s model of care and traditional healthcare.
          Specifically by using HiDoc I accept a greater responsibility to read
          and understand information throughout the App and Website about the
          limitations of HiDoc’s model of care, the risks of seeking care this
          way, and the risks and benefits of a proposed treatment plan. I
          understand that I must read and understand the Consent to Telehealth,
          the Terms of Use, the Privacy Policy, the FAQs, the information
          provided about a service before I answer health questions, the
          information provided in the health questions themselves, messages I
          receive from HiDoc, and, very importantly, content provided via links
          in the message that the doctor sends me after the doctor has reviewed
          all my information and recommended a treatment plan and, when
          appropriate, prescribed a medicine. I understand that to read
          important information I may need to both click on links and various
          titles to expand the information that’s visible below, and that
          without clicking on links and titles I will not be able to read
          important information that enables me to give my informed consent to a
          treatment. I understand that by using HiDoc, I accept the
          responsibility to provide full and truthful answers to all questions
          and, when requested, to provide unaltered photos of me that are taken
          at the time of using our service. I understand that the doctor is
          unable independently to verify the information and photos I provide
          and that the doctor will make a professional judgment based on the
          information and photos I provide. I understand that I won’t receive
          any other medical services that go beyond the diagnosis and treatment
          of acne, birth control, the diagnosis and treatment of a possible UTI,
          the diagnosis and treatment of male pattern baldness, the diagnosis
          and treatment of erectile dysfunction, the diagnosis and treatment of
          acute bacterial sinus infection, the diagnosis and treatment of acid
          reflux and the diagnosis and treatment of flu, the diagnosis and
          treatment of high cholesterol, the diagnosis and treatment of
          recurrent cold sores, the diagnosis and treatment of depression, the
          diagnosis and treatment of anxiety, the diagnosis and treatment of
          recurrent genital herpes, and stop smoking treatments. I need to seek
          other sources of care for my other medical needs. I understand that by
          using the service for a telemedical consultation, I won’t have an in
          person consultation and physical exam that might identify a medical
          condition that needs further investigation or immediate treatment. I
          understand that I must check the App or Website for messages because
          this is the way that the doctor will communicate important information
          to me. I understand that if I don’t check the App or Website
          regularly, then my care may be delayed. I understand that if I have
          any questions relating to my care that aren’t urgent, I can message
          the doctor through the App or Website. I understand that the doctor
          may not review and respond to my messages until the next business day.
          <h3 className={styles.subtitle}>
            Importance of reading all the information we provide
          </h3>{" "}
          I understand that HiDoc will provide detailed information in the App
          and Website to help me make an informed decision about whether to
          accept a proposed treatment plan. The most important information about
          a treatment plan is in the link that the doctor will send me when the
          doctor prescribes a treatment. This information includes detailed
          information to help me decide if the benefits of the treatment plan
          outweigh the risks, given the alternative options available to me,
          which includes the option of not taking any treatment. I understand
          the importance of reading the information the doctor provides about
          adverse events, including the signs and symptoms of serious side
          effects and common side effects from taking a medicine, as this will
          ensure that I seek appropriate medical attention in a timely manner.
          <h3 className={styles.subtitle}>
            Importance of answering all questions fully and truthfully
          </h3>{" "}
          I understand that by using HiDoc I seek to enter into a relationship
          where the doctor relies exclusively upon information and photos that I
          provide to decide whether or not treatment is safe and appropriate. I
          understand that the doctor has no way of verifying the information and
          photos that I provide and that the doctor will consider information to
          be accurate, true and complete, including my age, gender and all my
          answers to health questions, and the photos to be of me, taken at the
          time of me using the service, and unaltered. I understand that if I
          provide information that isn’t true and complete, then I’ll be at
          greater risk of adverse events from any treatment that the doctor
          prescribes and I may take a treatment that isn’t necessary,
          appropriate, or safe. I understand that if I provide photos that are
          altered, not of me or not taken at the time of me using the service,
          then I’ll be at greater risk of adverse events from any treatment that
          the doctor prescribes and I may take a treatment that isn’t necessary,
          appropriate, or safe. I understand that even if I provide information
          that is true and complete, I’m still at risk of adverse events from
          any treatment that the doctor prescribes. I understand that even if I
          provide photos that are unaltered, of me and taken at the time of
          using the service, I’m still at risk of adverse events from my
          treatment that the doctor prescribes. I understand that it is
          important that I don’t create more than one account. Creating more
          than one account makes it impossible for the doctor to see the full
          history of care that I’ve received from HiDoc. This increases the
          chances that the doctor will not have access to important information
          and photos in my medical record that could influence the doctor’s
          clinical decision. I understand that by using HiDoc I’m giving my
          explicit consent for the doctor to access medication history, where
          it’s available, from records provided by pharmacy databases via the
          services of Allscripts and/or Iprescribe. I understand that, if
          appropriate, the doctor may take this information into account when
          making a treatment and prescribing decision but this does not change
          how important it is that I provide full, true and complete information
          during the HiDoc visit.{" "}
          <h3 className={styles.subtitle}>
            isks of accepting our treatment plan
          </h3>
          R I understand that all the medicines that the doctor may prescribe or
          recommend, including over-the-counter medicines and
          ‘behind-the-counter’ medicines, can cause serious side effects and
          adverse events that include severe allergic reaction, permanent
          disability, and death. I understand that it is my responsibility to
          make an informed decision whether to accept a treatment plan that the
          doctor proposes after weighing the risks and benefits of the medicine
          being prescribed, alternative treatment options and the risks and
          benefits of such alternatives, and the option of not seeking any
          treatment. I understand the importance of reading the manufacturer’s
          instructions that come with a medicine, including an over-the-counter
          or behind-the-counter medicine, before I take a medicine because this
          includes important information about risks and warnings. I understand
          that adverse events can be caused by a number of things, including an
          allergic reaction, side effects, or interactions between a medicine
          that the doctor prescribes and any medical conditions I may have,
          other prescription medicines or other things (e.g., supplements,
          herbs, over-the-counter medicines, or recreational drugs) I’m taking,
          and lifestyle choices such as smoking tobacco products or drinking
          alcohol.<h3 className={styles.subtitle}>Acid Reflux</h3> I understand
          that adverse events from taking medicines to treat acid reflux include
          but aren’t limited to severe allergic reaction, permanent disability,
          and death. I understand that if my symptoms keep bothering me then I
          should have an endoscopy that looks in my esophagus and stomach. I
          understand that taking medicine for acid reflux may delay a diagnosis
          of and treatment for a different underlying problem that is the real
          cause of my symptoms. <h3 className={styles.subtitle}>Acne</h3> I
          understand that adverse events from taking antibiotics to treat acne
          include but aren’t limited to allergic reaction, permanent disability,
          and death. I understand that by using HiDoc to diagnose and treat
          acne, there’s a risk that the doctor misdiagnoses the cause of my skin
          problem resulting in a delay in diagnosing the true cause of my
          problem and that I’ll take prescription or over-the-counter medicines
          unnecessarily and expose myself to the risks of taking those
          medicines. I understand that by using HiDoc to diagnose and treat
          acne, the doctor won’t be able to do a complete and thorough skin exam
          of my whole body and that a doctor in person may conduct a more
          thorough skin exam. Because HiDoc doctors cannot do a thorough skin
          exam, there is a risk that they may not identify a medical condition
          that needs further investigation or immediate treatment. There is also
          a risk that the photos of my skin that I send to HiDoc are not
          representative of my condition and that based on the photos I send the
          HiDoc doctors misdiagnose my condition. I understand that even if
          HiDoc doctors correctly diagnose me with acne and initially prescribe
          treatment, it may subsequently be necessary for me to seek additional
          care with a doctor in person if the treatment is not effective or the
          doctors consider my acne to be too complicated to manage online.
          <h3 className={styles.subtitle}>
            Birth Control Pills, Patch and Ring
          </h3>{" "}
          I understand that adverse events from using hormonal birth control
          include but aren’t limited to stroke, heart attack, and death. I
          understand that hormonal birth control offered by HiDoc that contains
          estrogen – namely combination birth control pills, the birth control
          patch, and the birth control ring – has a higher risk of serious side
          effects and adverse events, including blood clots, stroke, permanent
          disability, and death, than birth control that does not contain
          estrogen. Progestin-only pills do not contain estrogen and therefore
          present a lower risk of serious side effects. There are also birth
          control methods that do not contain hormones, such as barrier methods.
          I understand that if I request a prescription for a combination pill,
          birth control patch or birth control ring – all of which contain
          estrogen – then it’s important that I provide a recent and accurate
          blood pressure measurement because it’s not appropriate to take birth
          control that contains estrogen if I have a history of elevated blood
          pressure. I understand that if I take a combination pill, birth
          control patch or birth control ring without knowing my blood pressure
          then I have a significant risk of blood clots, stroke, permanent
          disability, and death. I understand that I can request a prescription
          for many different types of birth control pills and that different
          types of pills have different risks of adverse events. I understand
          that if I request a prescription for a specific birth control, then I
          accept any increased risk of adverse events and serious side effects
          associated with that particular medicine.{" "}
          <h3 className={styles.subtitle}>Cholesterol</h3> I understand that
          HiDoc can help me manage my high cholesterol and that managing my
          cholesterol is only one part of looking after my heart and overall
          wellness. I understand it’s important to complement HiDoc with
          visiting a traditional doctor’s office when I need to. I understand
          that HiDoc only prescribes statin medicines and not other classes of
          medicine to treat high cholesterol. I understand that adverse events
          from taking statin medicines to treat high cholesterol include but
          aren’t limited to severe allergic reaction, permanent disability, and
          death. I understand that if HiDoc recommends treatment or increase
          dosage of statin medicine and I decline then I accept that I may be at
          increased risk of preventable heart attack or stroke.{" "}
          <h3 className={styles.subtitle}>Depression and Anxiety</h3> I
          understand that if I am threat to myself or to other people, I should
          not use HiDoc and should instead call 911 or call the National Suicide
          Prevention Hotline 1-800-273-8255. I understand that the prescription
          medicine class prescribed, selective serotonin inhibitors (SSRIs),
          norepinephrine serotonin reuptake inhibitors (SNRI) and atypical
          antidepressant (bupropion) are FDA approved for depression and/or
          anxiety and are prescribed in that manner. I understand that taking
          these types of medicines may cause sexual dysfunction (problems with
          orgasm, ejaculation, and/or erectile dysfunction), and also
          potentially life-threatening side effects, the risk of suicidal
          thoughts and behavior and can cause a severe allergic reaction that
          can lead to disability and even death. I understand that abruptly
          stopping any of the treatments may cause symptoms including but not
          limited to anxiety, irritability, agitation, dizziness, nausea and
          sensory disturbances such as the feeling of having an electric shock.
          I understand that as part of treatment that HiDoc doctors will require
          me to provide an emergency contact person as well as ensuring any
          weapons at home are both unloaded and locked. I understand as part of
          treatment that HiDoc doctors will require me to abstain from any
          alcohol or substance use including but not limited to marijuana,
          cocaine, and stimulants (uppers). I understand if I am unable to abide
          by these terms of treatments that I may not be an appropriate
          candidate for this service. I understand that there are alternatives
          to the prescription-only medicines available from HiDoc doctors, like
          cognitive behavioral therapy (CBT) for treatment of depression or
          anxiety. <h3 className={styles.subtitle}>Erectile Dysfunction</h3> I
          understand that adverse events from taking sildenafil (Viagra),
          tadalafil (Cialis) or vardenafil (Levitra) include but aren’t limited
          to a painful erection lasting more than 4 hours, sudden loss of vision
          in one or both eyes, sudden decrease or loss of hearing, allergic
          reaction, permanent disability, and death. I understand that if I have
          a condition where sex is not advised then I should not take sildenafil
          (Viagra), tadalafil (Cialis) or vardenafil (Levitra). I understand
          that if I am taking nitroglycerin or other medicines that contain
          nitrates then I should not take sildenafil (Viagra), tadalafil
          (Cialis) or vardenafil (Levitra) as this combination can lower blood
          pressure unexpectedly and could be fatal. I understand that sildenafil
          (Viagra), tadalafil (Cialis) and vardenafil (Levitra) are prescription
          medicines and not recreational drugs. I understand that by using HiDoc
          to diagnose and treat erectile dysfunction, the doctor won’t have the
          opportunity to conduct a detailed physical examination that would be
          possible if I were to see a doctor in person. Because HiDoc doctors
          cannot do a detailed physical examination there is a risk that they
          may not identify potential physical causes of my condition that they
          would be able to identify and investigate further if I were to see
          them in person. I understand that erectile dysfunction is often the
          first symptom of other health conditions, including cardiovascular
          disease and diabetes and that it’s important to have a range of
          investigative laboratory tests to look for the cause of my condition.
          I understand that HiDoc doctors can order a set of investigative
          tests, help me understand the tests results and advise me on next
          steps. I understand that it’s my responsibility to seek follow-up care
          and ongoing care from a doctor in person and that it’s unlikely that
          HiDoc doctors will be able to provide follow-up care and ongoing care
          for any potential health conditions highlighted by the tests. I
          understand that the investigative tests that HiDoc doctors will order
          do not include tests to identify potential hormonal causes of my
          erectile dysfunction. I understand that if I don’t follow the advise
          of HiDoc doctors to order investigative laboratory tests, then I will
          not be able to identify potentially serious underlying health
          conditions, which left untreated could result in premature death or
          permanent disability. I understand that Minnesota and South Carolina
          state laws prohibit HiDoc from offering our erectile dysfunction
          service in Minnesota and South Carolina.
          <h3 className={styles.subtitle}>Flu</h3> I understand that adverse
          events from taking antivirals to treat the flu include but aren’t
          limited to allergic reaction, permanent disability, and death. I
          understand that by using HiDoc to diagnose and treat a flu infection,
          there’s a risk that the doctor misdiagnoses the cause of my symptoms
          as the flu and not some other cause. If this happens then I’ll take an
          antiviral medicine unnecessarily, expose myself to the risks of taking
          that medicine, and potentially delay the investigation of a more
          serious condition. I understand that if my flu symptoms haven’t
          improved after 3 days of taking the antiviral medicine, then it’s
          important that I visit a doctor in person.
          <h3 className={styles.subtitle}>Hypothyroidism</h3> I understand that
          I should only request levothyroxine from HiDoc doctors if a medical
          professional has previously diagnosed me with low thyroid. I
          understand that only levothyroxine is prescribed from HiDoc doctors
          for hypothyroidism and will not be prescribed ‘off-label’ for other
          conditions or in combination with other thyroid treatments including
          but not limited to desiccated thyroid or triiodothyronine (T3). I
          understand that adverse events from taking levothyroxine include but
          aren’t limited to allergic reaction, stroke, heart attack, permanent
          disability, and death. I understand that by using HiDoc to diagnose
          and treat hypothyroidism, the doctor won’t have the opportunity to
          conduct a detailed physical examination that would be possible if I
          were to see a doctor in person. Because HiDoc doctors cannot do a
          detailed physical examination there is a risk that they may not
          identify potential physical causes of my condition that they would be
          able to identify and investigate further if I were to see them in
          person.{" "}
          <h3 className={styles.subtitle}>Premature Ejaculation (“PE”)</h3> I
          understand that PE medicines are not recreational drugs, and that they
          come with the risk of serious adverse events, including but not
          limited to gastrointestinal bleeding, seizures, coma, impairment of
          cognitive or motor functions, irritability, death and disability. I
          understand that if I have a condition where sex is not advised then I
          should not take PE medicines. I understand that HiDoc doctors may
          prescribe sertraline, sildenafil (Viagra), tadalafil (Cialis), or
          vardenafil (Levitra) to treat PE. I understand that the FDA has not
          licensed any medicines to treat PE and that HiDoc doctors will
          prescribe PE medicines ‘off-label’. I understand that sertraline is
          licensed by the FDA to treat anxiety and depression, not PE. I
          understand that I should not take sertraline for PE if I am taking
          another medicine to treat anxiety or depression (such as a selective
          serotonin reuptake inhibitor or tri-cyclic antidepressant), or if I
          have a history of suicidal thoughts, self-harming, bipolar disorder,
          or uncontrolled depression. Doing so may cause potentially
          life-threatening side effects. I understand that if I take sertraline,
          I have the option to take it as needed before sex, or on a daily
          basis. Studies have shown that it is more effective when taken on a
          daily basis, but I understand that this increases the likelihood of
          adverse events, and difficulties when I decide to stop taking it. I
          understand that abruptly stopping sertraline can cause symptoms
          including but not limited to anxiety, irritability, agitation,
          dizziness, nausea and sensory disturbances such as the feeling of
          having an electric shock. I understand that although the FDA has
          licensed sildenafil 25mg/50mg/100mg, tadalafil, and vardenafil to
          treat erectile dysfunction, the FDA has not licensed them to treat PE.
          I understand that the FDA has not licensed sildenafil 20mg tablets to
          treat ED. I understand that adverse events from taking sildenafil
          (Viagra), tadalafil (Cialis) or vardenafil (Levitra) include but
          aren’t limited to a painful erection lasting more than 4 hours, sudden
          loss of vision in one or both eyes, sudden decrease or loss of
          hearing, allergic reaction, permanent disability, and death. I
          understand that if I am taking nitroglycerin or other medicines that
          contain nitrates then I should not take sildenafil (Viagra), tadalafil
          (Cialis) or vardenafil (Levitra) as this combination can lower blood
          pressure unexpectedly and could be fatal. I understand that by using
          HiDoc to diagnose and treat PE, the doctor won’t have the opportunity
          to conduct a detailed in person physical examination that would be
          possible if I were to see a doctor in person. Because HiDoc doctors
          cannot do a detailed in person physical examination there is a risk
          that they may not identify potential physical causes of my condition
          that they would be able to identify and investigate further if I were
          to see them in person. I understand that Minnesota and South Carolina
          state laws prohibit HiDoc from offering our PE service.
          <h3 className={styles.subtitle}>Sinus Infection </h3> I understand
          that adverse events from taking antibiotics to treat an acute
          bacterial sinus infection include but aren’t limited to allergic
          reaction, permanent disability, and death. I understand that by using
          HiDoc to diagnose and treat a possible acute bacterial sinus
          infection, there’s a risk that the doctor misdiagnoses the cause of my
          infection as bacteria and not a virus or an allergy. I understand that
          there’s a risk that I’ll be misdiagnosed with an acute bacterial sinus
          infection when in fact my symptoms are caused by a virus. If this
          happens then I’ll take an antibiotic unnecessarily and expose myself
          to the risks of taking that antibiotic. I understand that if my
          symptoms haven’t improved after 3 days of taking the antibiotic
          treatment, then it’s important that I send HiDoc doctors a message or
          visit a doctor in person.{" "}
          <h3 className={styles.subtitle}>Stop Smoking</h3> I understand that
          there are alternatives to the prescription-only stop smoking medicines
          available from HiDoc doctors, and that over-the-counter alternatives
          (such as nicotine patches, gum and lozenges) are less likely to cause
          serious side effects than the prescription-only alternatives.
          Prescription-only stop smoking medicines can increase the risk of
          suicidal thoughts and behavior and can cause a severe allergic
          reaction that can lead to disability and even death.
          <h3 className={styles.subtitle}>UTI</h3> I understand that adverse
          events from taking antibiotics to treat a UTI include but aren’t
          limited to allergic reaction, permanent disability, and death. I
          understand that by using HiDoc to diagnose and treat a possible UTI,
          the doctor won’t be able to conduct a live interview and physical exam
          that might identify a medical condition that needs further
          investigation or immediate treatment. Also, the doctor won’t be able
          to carry out 3 tests that would help determine if I have a UTI or
          whether a different condition is the cause of my symptoms.
          Specifically, these 3 tests are a urine dipstick test, a urinalysis
          and a urine culture. Without carrying out these tests and solely
          relying on my answers to the questionnaire in the app, the doctor will
          correctly diagnose approximately 80% of patients who have a UTI and
          80% of patients who don’t have a UTI. 20% of patients who actually
          have a UTI and 20% of patients who don’t actually have a UTI will be
          incorrectly diagnosed without these additional tests. I understand
          that in cases where the doctor makes an incorrect diagnosis of a UTI,
          the doctor may prescribe an antibiotic treatment that isn’t
          appropriate for my specific needs. This means I may take an antibiotic
          that I don’t need to take. I understand that if my symptoms haven’t
          disappeared after 3 days of taking the antibiotic treatment, then it’s
          important that I visit a doctor in person for a physical examination.
          <h3 className={styles.subtitle}>
            RISKS TO ELECTRONIC HEALTH INFORMATION
          </h3>{" "}
          I understand that although HiDoc implements a wide range of
          administrative, physical, and technical safeguards to protect my
          health information and comply with HIPAA, HiDoc cannot guarantee the
          privacy and confidentiality of my health information. For more details
          about how HiDoc protects and uses your health information and complies
          with HIPAA, see our Privacy Policy.
        </FormContainer>
      </PageContainer>
    </div>
  );
}

export default TermsPage;
