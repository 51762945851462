import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import userService from "services/userService";
import createNewUserFromState from "utils/transform/createNewUserFromState";
import { RootState } from "./store";
import { setCredentials } from "./auth.slice";

export enum Gender {
  Male = "Male",
  Female = "Female",
}

interface FirstStep {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: Gender | null;
}

interface SecondStep {
  email: string;
  password: string;
}

interface ThirdStep {
  phoneNumber: string;
  secondaryPhoneNumber: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  insuranceId: string;
}

export interface Registration {
  isLoading: boolean;
  registrationActiveStep: number;
  registrationGoBack: number;
  firstStep: FirstStep;
  secondStep: SecondStep;
  thirdStep: ThirdStep;
}

export const userRegister = createAsyncThunk(
  "registration/userRegister",
  async (data, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {
    const state = getState() as RootState;
    const { registration } = state;
    const newUser = createNewUserFromState(registration);

    const res = (await userService.register(newUser)) as
      | {
          data: { jwtToken: string; refreshToken: string };
          request: { status: number; statusText: string };
        }
      | any;

    if (res.data?.jwtToken && res.data?.refreshToken) {
      const { jwtToken, refreshToken } = res.data;
      dispatch(setCredentials({ jwtToken, refreshToken }));
    } else if (
      res.response.data.message === "Provided insuranceId not valid."
    ) {
      return rejectWithValue(res.response.data.message);
    } else {
      console.log("Not successful registration");
    }
  }
);

const initialState: Registration = {
  isLoading: false,
  registrationActiveStep: 1,
  registrationGoBack: 0,
  firstStep: {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: null,
  },
  secondStep: {
    email: "",
    password: "",
  },
  thirdStep: {
    phoneNumber: "",
    secondaryPhoneNumber: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    insuranceId: "",
  },
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    saveFirstStep: (state, action: PayloadAction<FirstStep>) => {
      state.firstStep = action.payload;
    },
    saveSecondStep: (state, action: PayloadAction<SecondStep>) => {
      state.secondStep = action.payload;
    },
    saveThirdStep: (state, action: PayloadAction<ThirdStep>) => {
      state.thirdStep = action.payload;
    },
    setRegistrationActiveStep: (state, action: PayloadAction<number>) => {
      state.registrationActiveStep = action.payload;
    },
    setRegistrationGoBack: (state) => {
      state.registrationGoBack += 1;
    },
    clearRegistrationState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(userRegister.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userRegister.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(userRegister.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  saveFirstStep,
  saveSecondStep,
  saveThirdStep,
  setRegistrationActiveStep,
  setRegistrationGoBack,
  clearRegistrationState,
} = registrationSlice.actions;

// selectors
export const selectFirstStep = (state: RootState) =>
  state.registration.firstStep;

export const selectSecondStep = (state: RootState) =>
  state.registration.secondStep;

export const selectThirdStep = (state: RootState) =>
  state.registration.thirdStep;

export const selectRegistrationActiveStep = (state: RootState) =>
  state.registration.registrationActiveStep;

export const selectRegistrationGoBack = (state: RootState) =>
  state.registration.registrationGoBack;

export const selectRegistrationLoading = (state: RootState) =>
  state.registration.isLoading;

export default registrationSlice.reducer;
