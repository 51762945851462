// dashboard
const dashboardAppointments = "dashboard/appointments";
const medicalInformation = "/medical-information";
const resetPassword = "/reset-password";
const appointmentReserved = "/appointment-reserved";

// flows
const flowPcp = "pcp";
const flowAppointment = "/book-appointment";

// externals
const whyToChooseUs = "https://hidoconline.com/virtual-primary-care/";
const virtualPrimaryCare = "https://hidoconline.com/virtual-primary-care/";

// pages
const primaryCare = "/primary-care";
const medicalCare = "/medical-care";
const telephysical = "/telephysical";
const mentalHealth = "/mental-health";
const wellnessPrograms = "/wellness-programs";
const whoWeAre = "/who-we-are";
const pressRoom = "/press-room";
const healthResources = "/health-resources";
const faq = "/faq";
const contactUs = "/contact-us";

// user
const user = "user";
const tags = "user/tags";

// insurance
const insurance = "insurance";

// documents
const documentGet = "document";
const documentPost = "document/upload";
const profileDocuments = "document/Profile";

// stripe
const stripe = "stripe";
const getStripeStatusPath = (sessionId: string) => `stripe/status/${sessionId}`;
const processingStripe = "processing-payment";

// appointments
const appointmentsData = "appointment/user";
const getAppointmentData = (id: string) => `appointment/${id}`;

export {
  dashboardAppointments,
  medicalInformation,
  resetPassword,
  appointmentReserved,
  flowPcp,
  flowAppointment,
  whyToChooseUs,
  virtualPrimaryCare,
  primaryCare,
  medicalCare,
  telephysical,
  mentalHealth,
  wellnessPrograms,
  whoWeAre,
  pressRoom,
  healthResources,
  faq,
  contactUs,
  user,
  documentGet,
  documentPost,
  profileDocuments,
  insurance,
  tags,
  appointmentsData,
  getAppointmentData,
  stripe,
  processingStripe,
  getStripeStatusPath,
};
