import { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiButton from "@mui/material/Button";
import Button from "components/ui/Button";
import { ReactComponent as HamburgerMenu } from "assets/images/hamburgerMenu.svg";
import { ReactComponent as ProfileIcon } from "assets/images/drawerProfileIcon.svg";
import { useAppDispatch, useAppSelector } from "redux/store";
import useAuth from "hooks/useAuth";
import { logout } from "redux/auth.slice";
import classNames from "classnames";
import { flowAppointment } from "routes/paths";
import { fetchUserTags, selectLoadingUserTags } from "redux/user.slice";
import styles from "./styles.module.scss";

export default function AppDrawer() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { pathname }: { pathname: string } = useLocation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoadingTags = useAppSelector(selectLoadingUserTags);

  const { isAuth } = useAuth();

  const handleLogout = () => {
    dispatch(logout());
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsOpen(open);
    };

  const Profile = (
    <div className={styles.drawer_profile}>
      <div className={styles.drawer_profileBox}>
        {isAuth ? (
          <div
            className={styles.loggedInUserWrapper}
            role="button"
            tabIndex={0}
            onClick={() => navigate("/dashboard/appointments")}
          >
            <ProfileIcon />
            <p>My account</p>
          </div>
        ) : (
          <>
            <Button text="Sign up" to="/register" size="small" color="blue" />
            <Button text="Log in" to="/" size="small" color="outlined_blue" />
          </>
        )}
      </div>
    </div>
  );

  const BookAppointment = isAuth ? (
    <div className={styles.drawer_buttonWrapper}>
      <Button
        text="Book appointment"
        size="small"
        color="blue"
        isLoading={isLoadingTags}
        handleClick={
          !isAuth
            ? () => navigate("/register")
            : () =>
                dispatch(fetchUserTags()).then(() => navigate(flowAppointment))
        }
      />
    </div>
  ) : null;

  const HomeNav = (
    <>
      {/* How it works */}
      <NavLink to="how-it-works" className={styles.drawer_menuItem}>
        How It Works
      </NavLink>
      <NavLink to="how-it-works" className={styles.drawer_subItem}>
        Primary care
      </NavLink>
      <NavLink to="how-it-works" className={styles.drawer_subItem}>
        Medical care
      </NavLink>
      <NavLink to="how-it-works" className={styles.drawer_subItem}>
        Telephysical
      </NavLink>
      <NavLink to="how-it-works" className={styles.drawer_subItem}>
        Mental health
      </NavLink>
      <NavLink to="how-it-works" className={styles.drawer_subItem}>
        Wellness programs
      </NavLink>
      {/* What We Treat */}
      <NavLink to="what-we-treat" className={styles.drawer_menuItem}>
        What We Treat
      </NavLink>
      <NavLink to="what-we-treat" className={styles.drawer_subItem}>
        Who we are
      </NavLink>
      <NavLink to="what-we-treat" className={styles.drawer_subItem}>
        Press room
      </NavLink>
      <NavLink to="what-we-treat" className={styles.drawer_subItem}>
        Health resources
      </NavLink>
      <NavLink to="what-we-treat" className={styles.drawer_subItem}>
        FAQs
      </NavLink>
      <NavLink to="what-we-treat" className={styles.drawer_subItem}>
        Contact us
      </NavLink>
      {/* About Us */}
      <NavLink to="about" className={styles.drawer_menuItem}>
        About Us
      </NavLink>
      {/* Insurance & Pricing */}
      <NavLink to="insurance-and-pricing" className={styles.drawer_menuItem}>
        Insurance & Pricing
      </NavLink>
    </>
  );

  const Logout = (
    <>
      {isAuth && (
        <NavLink
          onClick={handleLogout}
          to="/dashboard/appointments"
          className={classNames(
            [styles.drawer_menuItem],
            [styles.drawer_menuItem_Logout]
          )}
        >
          Logout
        </NavLink>
      )}
    </>
  );

  type NavlinkClassNames =
    | string
    | ((props: { isActive: boolean }) => string | undefined)
    | undefined;

  const getClassNames: NavlinkClassNames = ({
    isActive,
  }: {
    isActive: boolean;
  }) => {
    return classNames([styles.drawer_menuItem], {
      [styles.active_navLink]: isActive,
    });
  };

  const DashboardNav = (
    <>
      <NavLink to="dashboard/appointments" className={getClassNames}>
        Appointments
      </NavLink>
      {/* <NavLink to="dashboard/documents" className={getClassNames}>
        Documents
      </NavLink>
      <NavLink to="dashboard/medical-information" className={getClassNames}>
        Medical information
      </NavLink> */}
      <NavLink to="dashboard/profile" className={getClassNames}>
        My profile
      </NavLink>
    </>
  );

  const homeDrawer = (
    <>
      {Profile}
      {BookAppointment}
      {HomeNav}
      {Logout}
    </>
  );

  const dashboardDrawer = (
    <>
      {Profile}
      {BookAppointment}
      {DashboardNav}
      {Logout}
    </>
  );

  const composeDrawer = () => {
    if (pathname.includes("/dashboard")) {
      return dashboardDrawer;
    }
    return homeDrawer;
  };

  const list = () => (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className={styles.drawerWrapper}
    >
      {composeDrawer()}
    </Box>
  );

  return (
    <nav className={styles.drawerIcon}>
      <MuiButton onClick={toggleDrawer(true)}>
        <HamburgerMenu />
      </MuiButton>
      <Drawer
        classes={{ paper: styles.drawerPaper }}
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    </nav>
  );
}
