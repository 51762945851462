import {
  IFourthBookingAppointmentStep,
  IThirdBookingAppointmentStep,
} from "redux/bookAppointmentFlow.slice";
import { FourthPcpStep, ThirdPcpStep } from "redux/pcpFlow.slice";
import { store } from "redux/store";
import bookingService from "services/bookingService";
import { defaultFormatDate, formatTimeSlotTime } from "./formatDate";

export const createAppointmentType = (
  thirdStep: IThirdBookingAppointmentStep | ThirdPcpStep,
  fourthStep: IFourthBookingAppointmentStep | FourthPcpStep,
  appointmentType: "video" | "phone" | null,
  phoneNumber: string,
  isPcp: boolean
) => {
  if (!appointmentType)
    return Promise.reject(new Error("Wrong appointment type"));

  const bookingObject = generateBookingObject(
    thirdStep,
    fourthStep,
    appointmentType,
    phoneNumber,
    isPcp
  );

  return bookingService.bookAppointment(bookingObject);
};

export const generateBookingObject = (
  thirdStep: IThirdBookingAppointmentStep | ThirdPcpStep,
  fourthStep: IFourthBookingAppointmentStep | FourthPcpStep,
  appointmentType: string,
  phoneNumber: string,
  isPcp: boolean
) => {
  const { appointmentDate, timeSlot } = fourthStep;
  const date = defaultFormatDate(appointmentDate);
  const scheduledDate = `${date} ${formatTimeSlotTime(timeSlot)}`;
  const timezone = "Pacific/Honolulu";
  const { description } = thirdStep;
  const oldPhoneNumber = store.getState().user.user?.phoneNo[0];

  const bookingObject = {
    scheduledDate,
    timezone,
    appointmentType,
    healthConcern: description,
    selfPay: false,
    stripeCheckoutSessionId: "",
    establishPcp: isPcp,
  };

  if (isPcp && oldPhoneNumber !== phoneNumber)
    return {
      ...bookingObject,
      establishPcp: true,
      newPhoneNumber: phoneNumber,
    };

  // if (isPcp)
  //   return {
  //     ...bookingObject,
  //     establishPcp: true,
  //   };

  if (oldPhoneNumber !== phoneNumber)
    return {
      ...bookingObject,
      newPhoneNumber: phoneNumber,
    };

  return bookingObject;
};
