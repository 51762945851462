import { useState, useEffect, ChangeEvent } from "react";
import Button from "components/ui/Button";
import RadioGroup from "components/ui/RadioGroup";
import Checkbox from "components/ui/Checkbox";
import TextArea from "components/ui/TextArea";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  saveThirdPcpStep,
  selectThirdPcpStep,
  ThirdPcpStep,
} from "redux/pcpFlow.slice";
import useValidate from "utils/validation";
import { VALIDATE_HEALTH_CONCERN_DESCRIPTION } from "utils/validation/constants";
import client, { Events } from "services/EventEmitter";
import {
  IThirdBookingAppointmentStep,
  saveThirdBookingAppointmentStep,
  selectThirdBookingAppointmentStep,
} from "redux/bookAppointmentFlow.slice";
import ActionsContainer from "../FormContainer/ActionsContainer";
import styles from "./styles.module.scss";

interface Props {
  nextStep: (stepId: number) => void;
  bookingAppointment?: boolean;
}

function HealthConcern({ nextStep, bookingAppointment = false }: Props) {
  const dispatch = useAppDispatch();
  const isPcp = !bookingAppointment;
  const thirdStep = useAppSelector<IThirdBookingAppointmentStep | ThirdPcpStep>(
    isPcp ? selectThirdPcpStep : selectThirdBookingAppointmentStep
  );

  type ConcernStatus = "yes" | "no";

  const [withConcern, setWithConcern] = useState<ConcernStatus>("yes");
  const [description, setDescription] = useState<string>(thirdStep.description);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const [isDescriptionValid, descriptionErrors] = useValidate(
    description,
    VALIDATE_HEALTH_CONCERN_DESCRIPTION
  );

  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    if (withConcern === "no") {
      setDescription("");
      setHaveErrors(false);
    } else {
      setHaveErrors(!isDescriptionValid);
    }

    if (!termsAccepted) setHaveErrors(true);
  }, [isDescriptionValid, withConcern, termsAccepted]);

  const handleChangeWithConcern = (value: ConcernStatus) => {
    setWithConcern(value);
  };

  const handleChangeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const options = [
    { value: "yes", label: "Yes - I have a current health concern" },
    {
      value: "no",
      label: "No - just book me for my introductory PCP appointment",
    },
  ];

  const handleContinue = () => {
    if (haveErrors) {
      client.emit(Events.SCROLL_TO_TOP);
      setShowErrors(true);
      return;
    }

    if (isPcp)
      dispatch(
        saveThirdPcpStep({
          healthConcern: withConcern,
          description,
        })
      );
    else
      dispatch(
        saveThirdBookingAppointmentStep({
          description,
        })
      );

    nextStep(4);
  };

  return (
    <>
      <h2 className="FormComponent-title">Health concern</h2>
      <p className="FormComponent-description">
        {!isPcp
          ? "What do you want to discuss? Please provide us with as much information as you can."
          : "Do you have a health concern that you’d like to discuss with one of our team?"}
      </p>
      {isPcp && (
        <RadioGroup
          options={options}
          activeValue={withConcern}
          groupLabel="Health concern"
          onChange={handleChangeWithConcern}
        />
      )}

      {withConcern === "yes" && (
        <TextArea
          value={description}
          onChange={handleChangeDescription}
          error={descriptionErrors[0]}
          showError={showErrors}
          label="Please describe your symptoms in as much detail as you can"
          description="Please include length of time symptoms have persisted."
          id="description"
        />
      )}

      {withConcern === "no" && (
        <p className={styles.thanksMessage}>
          <p>Thanks.</p>
          <div>
            You’ll still need to speak with one of our medical professionals.
            They’ll ask you questions about your medical history and confirm
            your suitability for telehealth
          </div>
        </p>
      )}

      <Checkbox
        description={
          <>
            I give my consent for HiDoc to provide me with telehealth services
            under
            <a
              href="https://hidoconline.com/consent-to-telehealth/"
              target="_blank"
              rel="noreferrer"
            >
              the terms of use found here
            </a>
            <span>.</span>
          </>
        }
        checked={termsAccepted}
        onChange={setTermsAccepted}
        errorMessage="Please agree to the terms and conditions and privacy policy"
        showError={showErrors}
        id="termsAccepted"
      />

      <ActionsContainer>
        <Button
          text="Continue"
          descriptionText="Select time and date"
          size="large"
          color="blue"
          handleClick={handleContinue}
          withArrow
        />
      </ActionsContainer>
    </>
  );
}

export default HealthConcern;
