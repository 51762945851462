import { AppointmentTypeNameEnum } from "components/forms/ChooseYourService";
import {
  ISecondBookingAppointmentStep,
  IThirdBookingAppointmentStep,
  selectFirstBookingAppointmentStep,
  selectSecondBookingAppointmentStep,
  selectThirdBookingAppointmentStep,
} from "redux/bookAppointmentFlow.slice";
import {
  SecondPcpStep,
  selectSecondPcpStep,
  ThirdPcpStep,
  selectThirdPcpStep,
} from "redux/pcpFlow.slice";
import { useAppSelector } from "redux/store";
import { selectIsPcpConfirmed, selectPhoneNumber } from "redux/user.slice";
import { generateAppointmentTypeName } from "utils/appointmentUtils";

const useAppointmentData = (bookingAppointment: boolean = false) => {
  const isPcp = !bookingAppointment;
  const firstBookingStep = useAppSelector(selectFirstBookingAppointmentStep);
  const secondStep = useAppSelector<
    ISecondBookingAppointmentStep | SecondPcpStep
  >(isPcp ? selectSecondPcpStep : selectSecondBookingAppointmentStep);
  const thirdStep = useAppSelector<IThirdBookingAppointmentStep | ThirdPcpStep>(
    isPcp ? selectThirdPcpStep : selectThirdBookingAppointmentStep
  );

  const isPcpConfirmed = useAppSelector(selectIsPcpConfirmed);

  const phoneNumber = useAppSelector(selectPhoneNumber);

  const { appointmentType: appointmentContactType } = secondStep;

  const appointmentType = isPcp
    ? AppointmentTypeNameEnum.TELEPHYSICAL
    : firstBookingStep.name;

  const appointmentTypeName = generateAppointmentTypeName(
    appointmentContactType,
    appointmentType,
    isPcpConfirmed,
    isPcp
  );
  return {
    isPcpConfirmed,
    appointmentType,
    appointmentTypeName,
    thirdStep,
    phoneNumber,
    appointmentContactType,
  };
};

export default useAppointmentData;
