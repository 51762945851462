import FormContainer from "components/forms/FormContainer";
import PageContainer from "components/layout/PageContainer";
import styles from "./styles.module.scss";

function PrivacyPolicyPage() {
  return (
    <div className="fullPage-blueBackground">
      <PageContainer className={(styles.page, styles.general)}>
        <FormContainer>
          <h2 className="FormComponent-title">Privacy Policy</h2>
          Our website address, https://HiDocOnline.com, is owned by MD Care and
          hosted/operated by WePrescribe Inc dba HiDoc.{" "}
          <h3 className={styles.subtitle}>Comments</h3> When visitors leave
          comments on the site we collect the data shown in the comments form,
          and also the visitor’s IP address and browser user agent string to
          help spam detection. An anonymized string created from your email
          address (also called a hash) may be provided to the Gravatar service
          to see if you are using it. The Gravatar service privacy policy is
          available here: https://automattic.com/privacy/. After approval of
          your comment, your profile picture is visible to the public in the
          context of your comment. <h3 className={styles.subtitle}>Media</h3> If
          you upload images to the website, you should avoid uploading images
          with embedded location data (EXIF GPS) included. Visitors to the
          website can download and extract any location data from images on the
          website. <h3 className={styles.subtitle}>Contact forms & Cookies</h3>{" "}
          If you leave a comment on our site you may opt-in to saving your name,
          email address and website in cookies. These are for your convenience
          so that you do not have to fill in your details again when you leave
          another comment. These cookies will last for one year. If you have an
          account and you log in to this site, we will set a temporary cookie to
          determine if your browser accepts cookies. This cookie contains no
          personal data and is discarded when you close your browser. When you
          log in, we will also set up several cookies to save your login
          information and your screen display choices. Login cookies last for
          two days, and screen options cookies last for a year. If you select
          “Remember Me”, your login will persist for two weeks. If you log out
          of your account, the login cookies will be removed. If you edit or
          publish an article, an additional cookie will be saved in your
          browser. This cookie includes no personal data and simply indicates
          the post ID of the article you just edited. It expires after 1 day.
          <h3 className={styles.subtitle}>
            Embedded content from other websites
          </h3>
          Articles on this site may include embedded content (e.g. videos,
          images, articles, etc.). Embedded content from other websites behaves
          in the exact same way as if the visitor has visited the other website.
          These websites may collect data about you, use cookies, embed
          additional third-party tracking, and monitor your interaction with
          that embedded content, including tracking your interaction with the
          embedded content if you have an account and are logged in to that
          website.{" "}
          <h3 className={styles.subtitle}>How long we retain your data</h3>
          If you leave a comment, the comment and its metadata are retained
          indefinitely. This is so we can recognize and approve any follow-up
          comments automatically instead of holding them in a moderation queue.
          For users that register on our website (if any), we also store the
          personal information they provide in their user profile. All users can
          see, edit, or delete their personal information at any time (except
          they cannot change their username). Website administrators can also
          see and edit that information.{" "}
          <h3 className={styles.subtitle}>
            What rights you have over your data
          </h3>
          If you have an account on this site, or have left comments, you can
          request to receive an exported file of the personal data we hold about
          you, including any data you have provided to us. You can also request
          that we erase any personal data we hold about you. This does not
          include any data we are obliged to keep for administrative, legal, or
          security purposes.
          <h3 className={styles.subtitle}>Where we send your data</h3>
          Visitor comments may be checked through an automated spam detection
          service.
          <h3 className={styles.subtitle}>
            {" "}
            HEALTH INFORMATION PRIVACY POLICY
          </h3>
          Last updated: March 1, 2021{" "}
          <h3 className={styles.subtitle}>Overview</h3>
          State laws and US federal law govern how WePrescribe Inc., a Delaware
          Professional Corporation, and HiDoc can use and disclose health
          information that ‘individually identifies’ you. This information is
          called ‘protected health information’ or ‘PHI’. We collect PHI when
          you use our App and Website and when you communicate with us. State
          law and US federal law specify the rights you have over your PHI. This
          Privacy Policy helps you understand a) how we’ll use and share your
          PHI, b) what rights you have over your PHI and how to exercise your
          rights, c) what to do if you think we’re not complying with our legal
          obligations, and d) what other information we collect and share when
          you use the mobile application (‘the App’) or Website. If you have any
          questions, please send us a message through the App or Website or call
          808-468-6728 between 8:00am to 5:00pm Hawaii Standard Time Monday to
          Friday.{" "}
          <h3 className={styles.subtitle}>
            {" "}
            How we use and disclose your PHI
          </h3>{" "}
          We use and disclose your PHI to provide you with care, to run our
          healthcare operations, to take payment and to comply with state and US
          federal regulations. We implement a range of technical, administrative
          and physical safeguards to protect your PHI. We use your email address
          to send you a range of different types of emails, including emails
          that alert you that there is a message waiting for you in the App or
          Website, to send you emails asking for feedback on your experience of
          using the service, and to send you emails if you create an account but
          fail to complete a visit. We will send you marketing emails to notify
          you of the services we provide. We will share your email and first
          name with third party email platforms to enable us to send you emails.
          We use your telephone number to call you if we have any questions, to
          leave you voice messages if you don’t answer the telephone when we
          call you, and to call you to ask for feedback on your experience of
          using the service. We also use your telephone number to send you
          SMS/text message notifications. We may send you SMS notifications to
          update you on the progress of your visit. To provide treatment and to
          run our healthcare operations and to take payment we share your PHI
          with selected organizations that provide us with services. As
          examples, we share your PHI with organizations that help us run and
          maintain the technology and security infrastructure that supports the
          App and Website and the care we provide. We also may share your health
          information with medical staff who help ensure that we are providing a
          service that meets the appropriate standard of care. We share your PHI
          and card details with our trusted online payment processors. If you
          choose to save your payment details to make future payments easier,
          our trusted payment provider will store them on our behalf. We share
          your PHI with the pharmacy if we give you a prescription and with
          intermediaries who enable us to send prescriptions electronically. If
          you request that we organize for a third party mail order pharmacy to
          deliver your medicines, then we will share your PHI with and send your
          prescription to one or more mail order pharmacies so that there is
          flexibility in terms of which pharmacy mails your first deliver and
          which pharmacy mails any subsequent deliveries. We share your
          telephone number with the company that helps us send SMS/text
          messages. If we need to telephone you then we share your telephone
          number with our telecommunication provider. We store information you
          provide us with whether you complete a visit or whether you do not
          complete a visit. We take the same precautions over your data
          regardless of whether you complete a visit. We do not record video
          visits between our patients and our doctors. We may, however, capture
          a still image from the video and store that image in your medical
          record. We may use your PHI to tell you about health-related products
          and services. We may share your PHI with a third party if we merge,
          are acquired or undergo an asset sale. Wherever possible we strive to
          make sure that any third parties with whom we share your PHI are
          legally bound by the restrictions of this Privacy Policy. Owing to
          federal and state privacy laws we are generally unable to delete
          information from your medical record. At your request we can
          de-activate your secure account so that you and others can no longer
          access it with your username and password.{" "}
          <h3 className={styles.subtitle}>Other information we collect</h3>{" "}
          Separate to the health information we collect, we collect technical
          information about how you use our App and Website. We use this
          information to help us improve the overall quality of the App and
          Website, improve the service we provide, and improve our advertising
          and marketing campaigns. Technical information we collect and may
          share includes information about your mobile device or computer,
          including but not limited to unique device identifiers and the
          operating system and browser you use, and information about when and
          how you use the App or Website, including specific pages you visit,
          and information about your general location when you use the App and
          Website, including but not limited to your internet protocol address
          and MAC address. We use 3rd party vendor re-marketing tracking
          cookies, including the Google Adwords and Facebook tracking cookies.
          This means we will continue to show ads to you across the internet.
          The third-party vendors will place cookies on web browsers in order to
          serve ads based on past visits to our website. Third party vendors,
          including Google and Facebook, use cookies to serve ads based on a
          user’s prior visits to your website. This allows us to make special
          offers and continue to market our services to those who have shown
          interest in our service. We do not respond to ‘do not track’ signals
          in your browser.{" "}
          <h3 className={styles.subtitle}>
            Risk of sending unencrypted emails
          </h3>{" "}
          The emails we send you are not secure because they are unencrypted.
          Other people may be able to read and forward the emails we send you
          and the emails you send us. Emails we send you may include a wide
          range of identifiers that include but aren’t limited to your name,
          your email address, your visit number, your patient number, the date
          you used our service etc. When you create an account on the App or
          Website we ask you to give us your email address. We send an email to
          the email address you give us. If you give us an incorrect email
          address we will unknowingly send an email to the wrong person.{" "}
          <h3 className={styles.subtitle}>
            Risk of sending unencrypted SMS/text messages
          </h3>{" "}
          The SMS/text messages we send you are not secure because they are
          unencrypted. Other people may be able to read and SMS/text messages we
          send you and any SMS/text messages you send us. SMS/text messages we
          send you will include your telephone number. It will be clear that
          SMS/text messages we send you have come from Lemonaid.{" "}
          <h3 className={styles.subtitle}>
            Risk of storing PHI on your mobile
          </h3>{" "}
          When you use the App or Website there is a risk that your PHI will be
          stored unencrypted on your mobile. We take a variety of technical
          safeguards to make sure that your PHI does not leak onto your mobile
          but we cannot guarantee that these safeguards work.{" "}
          <h3 className={styles.subtitle}>
            Risk of our systems getting hacked and compromised
          </h3>{" "}
          Numerous administrative, technical and physical safeguards are in
          place to look after the PHI that held electronically on servers. But
          despite these safeguards, no system is full-proof and it cannot
          guarantee that the systems and your PHI will not be hacked or
          otherwise compromised by unauthorized third parties.
          <h3 className={styles.subtitle}>
            The rights you have over your PHI
          </h3>{" "}
          Right to obtain a copy of your medical record. We are allowed to
          charge you a fee if we think it’s appropriate. Right to request that
          we limit how we use and share your PHI. There may be occasions when we
          cannot agree to your request. Right to request that we change or
          update information held in your medical record. There may be occasions
          when we cannot agree to your request. Right to request how we send you
          PHI. The electronic nature of our service limits our ability to agree
          to such requests. Right to an accounting of the disclosure of your
          PHI. You are entitled to one ‘disclosure accounting’ in a 12 month
          period at no charge. An accounting does not include disclosures to
          carry out treatment, healthcare operations or payment. We are allowed
          to charge a fee for any additional accounting in a 12 month period.
          Right to a paper copy of this Privacy Policy. The electronic nature of
          our service limits our ability to agree to such requests.{" "}
          <h3 className={styles.subtitle}>
            How to contact us to Use your Rights
          </h3>{" "}
          Contact us in writing to: HiDoc c/o WePrescribe Inc, The Privacy
          Officer, 1110 Nuuanu Ave, # A1-298, Honolulu, HI, 96817{" "}
          <h3 className={styles.subtitle}>How to complain</h3> Submit a
          complaint to us in writing to: HiDoc c/o WePrescribe Inc, The Privacy
          Officer, 1110 Nuuanu Ave, # A1-298, Honolulu, HI, 96817 In addition
          you can complain to: Secretary of the U.S. Department of Health and
          Human Services Office of Civil Rights Attention: Regional Manager 50
          United Nations Plaza, Room 322 San Francisco, California 94102 For
          additional information, call (800) 368-1019 or U.S Office of Civil
          Rights (866) 627-7748 (Voice) or (866) 788-4989 (TTY) No retaliation
          We will not retaliate against you for filing a complaint.
          <h3 className={styles.subtitle}>Effective date</h3> This Notice is
          effective dated March 1, 2021.
          <h3 className={styles.subtitle}>Changes to this Notice</h3> If we
          change the terms of this Privacy Policy then we will post the new
          Privacy Policy on our App and Website. Any new Privacy Policy will
          apply to all PHI that we maintain, including PHI that was created
          prior to the change.
        </FormContainer>
      </PageContainer>
    </div>
  );
}

export default PrivacyPolicyPage;
