import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  clearRegistrationState,
  selectRegistrationGoBack,
  setRegistrationActiveStep,
} from "redux/registration.slice";
import client, { Events } from "services/EventEmitter";
import Stepper from "components/ui/Stepper";
import Button from "components/ui/Button";
import Modal from "components/shared/Modal";
import scrollToTop from "utils/scrollToTop";
import FormContainer from "../FormContainer";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";

function RegisterForm() {
  const dispatch = useAppDispatch();
  const triggerGoBack = useAppSelector(selectRegistrationGoBack);
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState<number>(1);
  const [isExitModalOpened, setIsExitModalOpened] = useState<boolean>(false);

  const handleChangeActiveStep = (nextStepid: number) => {
    dispatch(setRegistrationActiveStep(nextStepid));
    setActiveStep(nextStepid);
  };

  useEffect(() => {
    dispatch(setRegistrationActiveStep(1));
  }, []);

  useEffect(() => {
    if (activeStep > 1) {
      handleChangeActiveStep(activeStep - 1);
    }
  }, [triggerGoBack]);

  useEffect(() => {
    client.on(Events.REGISTER_OPEN_EXIT_MODAL, openModal);
    return () => {
      client.remove(Events.REGISTER_OPEN_EXIT_MODAL, openModal);
      client.emit(Events.ENABLE_SCROLL, 0);
    };
  }, []);

  const openModal = () => setIsExitModalOpened(true);

  const closeExitModal = () => {
    setIsExitModalOpened(false);
  };

  const exitRegisterForm = () => {
    dispatch(clearRegistrationState());
    navigate("/");
  };

  const steps = [
    {
      stepId: 1,
      form: <FirstStep nextStep={handleChangeActiveStep} />,
      isActive: activeStep === 1,
    },
    {
      stepId: 2,
      form: <SecondStep nextStep={handleChangeActiveStep} />,
      isActive: activeStep === 2,
    },
    {
      stepId: 3,
      form: <ThirdStep nextStep={handleChangeActiveStep} />,
      isActive: activeStep === 3,
    },
  ];

  const ActiveStep = steps[activeStep - 1].form;

  useEffect(() => {
    scrollToTop();
  }, [activeStep]);

  return (
    <FormContainer>
      <Stepper steps={steps} activeStep={activeStep} form="register" />
      {ActiveStep}
      <Modal
        isOpened={isExitModalOpened}
        handleClose={closeExitModal}
        title="Exit sign up?"
        buttons={
          <>
            <Button
              text="Cancel"
              color="outlined_blue"
              size="small"
              handleClick={closeExitModal}
            />
            <Button
              text="Exit sign up"
              color="red"
              size="small"
              handleClick={exitRegisterForm}
            />
          </>
        }
      >
        <>
          Are you sure you want to exit this process? The information you have
          entered will not be saved.
        </>
      </Modal>
    </FormContainer>
  );
}

export default RegisterForm;
