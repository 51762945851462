import { useState, useEffect, ChangeEvent } from "react";
import InputField from "components/ui/InputField";
import classNames from "classnames";
import Button from "components/ui/Button";
import useValidate from "utils/validation";
import { selectEmail } from "redux/user.slice";
import { VALIDATE_EMAIL, VALIDATE_PASSWORD } from "utils/validation/constants";
import { useAppDispatch, useAppSelector } from "redux/store";
import ValidationError from "components/shared/ValidationError";
import PasswordErrors from "components/forms/RegisterForm/PasswordErrors";
import { logout } from "redux/auth.slice";
import userService from "services/userService";
import styles from "./styles.module.scss";

function ChangeEmail({ handleClose }: { handleClose: Function }) {
  const dispatch = useAppDispatch();
  const currentEmail = useAppSelector(selectEmail);

  const [newEmail, setNewEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [requestError, setRequestError] = useState<string>("");
  const [showDontMatchError, setShowDontMatchError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setRequestError("");
    setNewEmail(e.target.value);
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handleChangeConfirmPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setConfirmPassword(e.target.value);

  const [isEmailValid, emailErrors] = useValidate(newEmail, VALIDATE_EMAIL);

  const [isPasswordValid, passwordErrors] = useValidate(
    password,
    VALIDATE_PASSWORD
  );

  useEffect(() => {
    setHaveErrors(!isEmailValid || !isPasswordValid);
  }, [isEmailValid, isPasswordValid]);

  const handleSaveChanges = () => {
    setShowDontMatchError(false);

    if (currentEmail === newEmail) {
      setShowErrors(true);
      setRequestError("Email address shouldn't be the same");
      return;
    }

    if (password !== confirmPassword) {
      setShowErrors(true);
      console.log("password doesn't match");
      setShowDontMatchError(true);
      return;
    }

    if (haveErrors) {
      // client.emit(Events.SCROLL_TO_TOP);
      setShowErrors(true);
      return;
    }

    setIsLoading(true);

    userService
      .changeEmail(newEmail, password)
      .then((res: any) => {
        dispatch(logout());
      })
      .catch((err: any) => {
        setRequestError(err.response.data.message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className={classNames({ [styles.loadingCover]: isLoading })}>
        <p className={classNames(styles.title, styles.mt30)}>
          Current email address
        </p>
        <p className={styles.currentEmail}>{currentEmail}</p>
        <InputField
          value={newEmail}
          onChange={handleChangeEmail}
          error={emailErrors[0] || requestError}
          showError={showErrors}
          label="Enter your NEW email address"
          id="newEmail"
          type="email"
          hideForgotPasswordLink={true}
        />

        <p className={styles.note}>
          <span>Please note:</span> in order to change your registered email
          address, we also require you to change your password. Once updated,
          you will be logged out and will need to log back in.
        </p>
        <InputField
          value={password}
          onChange={handleChangePassword}
          showError={showErrors}
          passwordError={showErrors && passwordErrors[0]}
          label="NEW password"
          id="password"
          type="password"
          hideForgotPasswordLink={true}
        />
        <InputField
          value={confirmPassword}
          onChange={handleChangeConfirmPassword}
          showError={showErrors}
          passwordError={showErrors && passwordErrors[0]}
          label="Confirm NEW password"
          id="password"
          type="password"
          hideForgotPasswordLink={true}
        />
        {showDontMatchError ? (
          <ValidationError
            show={showDontMatchError}
            error="Passwords do not match"
          />
        ) : (
          <PasswordErrors errors={passwordErrors} showErrors={showErrors} />
        )}
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          text="Cancel"
          size="small"
          color="outlined_blue"
          handleClick={handleClose}
        />
        <Button
          text="Save changes"
          size="small"
          color="blue"
          handleClick={handleSaveChanges}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}

export default ChangeEmail;
