import { createTheme } from "@mui/material/styles";
import { Link, LinkProps as RouterLinkProps } from "react-router-dom";
import { forwardRef } from "react";
import { LinkProps } from "@mui/material/Link";

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <Link data-testid="custom-link" ref={ref} to={href} {...other} />;
});

const theme = createTheme({
  typography: {
    fontFamily: ["Work Sans", "sans-serif"].join(","),
  },
  components: {
    MuiLink: {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});

export default theme;
