import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown";
import InputField from "components/ui/InputField";
import FileUpload from "components/shared/FileUpload";
import Modal from "components/shared/Modal";
import insuranceProviders from "utils/insuranceProviders";
import { ReactComponent as InsuranceFront } from "assets/images/insuranceFront.svg";
import { ReactComponent as InsuranceBack } from "assets/images/insuranceBack.svg";
import { ReactComponent as IdFront } from "assets/images/idFront.svg";
import { ReactComponent as IdBack } from "assets/images/idBack.svg";
import { ReactComponent as LayDocumentIcon } from "assets/images/layDocumentIcon.svg";
import { ReactComponent as FocusPhotoIcon } from "assets/images/focusPhotoIcon.svg";
import { ReactComponent as LightPhotoIcon } from "assets/images/lightPhotoIcon.svg";
import {
  appointmentReserved,
  dashboardAppointments,
  insurance,
  processingStripe,
  stripe,
} from "routes/paths";
import useValidate from "utils/validation";
import {
  VALIDATE_INSURANCE_NUMBER_SELECTED,
  VALIDATE_INSURANCE_PROVIDER_SELECTED,
  VALIDATE_INSURANCE_REGISTERED_ADDRESS,
} from "utils/validation/constants";
import client, { Events } from "services/EventEmitter";
import { useAppDispatch, useAppSelector } from "redux/store";

import {
  selectFourthPcpStep,
  clearPcpState,
  setPcpGoBack,
} from "redux/pcpFlow.slice";
import {
  DocumentType,
  fetchUserInsurance,
  InsuranceDTORequest,
  InsuranceDTOResponse,
  selectInsuranceData,
  updateInsuranceData,
} from "redux/insurance.slice";
import { AxiosError, AxiosResponse } from "axios";
import { httpPost } from "services/httpClient";
import userService from "services/userService";
import { getUserTags } from "redux/user.slice";
import {
  clearBookingAppointmentState,
  selectFourthBookingAppointmentStep,
  setBookingAppointmentGoBack,
} from "redux/bookAppointmentFlow.slice";
import {
  createAppointmentType,
  generateBookingObject,
} from "utils/transform/createAppointmentType";
import useAppointmentData from "hooks/useAppointmentData";
import { generateAppointmentTypeName } from "utils/appointmentUtils";
import { setItem } from "utils/localStorageUtils";
import { stripeCancelUrl } from "components/stripe";
import ActionsContainer from "../FormContainer/ActionsContainer";
import styles from "./styles.module.scss";
import SelfPayTab from "./SelfPayTab";
import PaymentModal from "./PaymentModal";

interface Props {
  nextStep: (stepId: number) => void;
  bookingAppointment?: boolean;
}

export interface IDocument {
  title: string;
  file: File;
  type: DocumentType;
  side: number;
}
export interface IDocumentDTO extends IDocument {
  insuranceId: string;
}

export interface ServicePrice {
  price: number;
}

function Payment({ nextStep, bookingAppointment }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isPcp = !bookingAppointment;

  const {
    isPcpConfirmed,
    appointmentType,
    phoneNumber,
    appointmentTypeName,
    thirdStep,
    appointmentContactType,
  } = useAppointmentData(bookingAppointment);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [slotTaken, setSlotTaken] = useState<boolean>(false);
  const [isLoadingPrice, setIsLoadingPrice] = useState(false);

  const existingInsuranceData = useAppSelector(selectInsuranceData);

  const [insuranceProvider, setInsuranceProvider] = useState<string>("");
  const [insuranceNumber, setInsuranceNumber] = useState<string>("");
  const [address, setAddress] = useState<string>("");

  const [servicePrice, setServicePrice] = useState<ServicePrice | null>(null);

  const [frontIdFile, setFrontIdFile] = useState<IDocument | null>(null);
  const [backIdFile, setBackIdFile] = useState<IDocument | null>(null);
  const [frontInsuranceIdFile, setFrontInsuranceIdFile] =
    useState<IDocument | null>(null);
  const [backInsuranceIdFile, setBackInsuranceIdFile] =
    useState<IDocument | null>(null);

  const [frontId, setFrontId] = useState<string>("");
  const [backId, setBackId] = useState<string>("");
  const [frontInsuranceId, setFrontInsuranceId] = useState<string>("");
  const [backInsuranceId, setBackInsuranceId] = useState<string>("");

  const [tabActive, setTabActive] = useState<"useInsurance" | "selfPay">(
    "useInsurance"
  );

  // if user pays by insurance
  const overrideAppointmentTypeName: string | null =
    tabActive === "useInsurance"
      ? generateAppointmentTypeName(
          "video",
          appointmentType,
          isPcpConfirmed,
          isPcp
        )
      : appointmentTypeName;

  const fourthStep = useAppSelector(
    isPcp ? selectFourthPcpStep : selectFourthBookingAppointmentStep
  );
  const [infoModalOpened, setInfoModalOpened] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [bookingError, setBookingError] = useState<boolean>(false);

  const [isProviderSelected, providerErrors] = useValidate(
    insuranceProvider,
    VALIDATE_INSURANCE_PROVIDER_SELECTED
  );

  const [isInsuranceNumberValid, insuranceNumberErrors] = useValidate(
    insuranceNumber,
    VALIDATE_INSURANCE_NUMBER_SELECTED
  );

  const [isInsuranceAddressValid, insuranceAddressErrors] = useValidate(
    address,
    VALIDATE_INSURANCE_REGISTERED_ADDRESS
  );

  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    setHaveErrors(
      !isProviderSelected || !isInsuranceNumberValid || !isInsuranceAddressValid
    );
  }, [insuranceProvider, insuranceNumber]);

  useEffect(() => {
    dispatch(fetchUserInsurance())
      .unwrap()
      .then(({ data }: AxiosResponse) => {
        if (data.length) {
          setInsuranceProvider(data[0].provider.name);
          setInsuranceNumber(data[0].membershipId);
          setAddress(data[0].registeredAddress);
        }
      });

    return () => {
      client.emit(Events.ENABLE_SCROLL, 0);
    };
  }, []);

  useEffect(() => {
    if (overrideAppointmentTypeName) {
      setIsLoadingPrice(true);
      userService
        .getArticlePrice(overrideAppointmentTypeName)
        .then(({ data }: AxiosResponse<ServicePrice>) => {
          setServicePrice(data);
        })
        .finally(() => {
          setIsLoadingPrice(false);
        });
    }
  }, [overrideAppointmentTypeName, tabActive]);

  const handleChangeInsuranceNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInsuranceNumber(e.target.value);
  };

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };

  const toggleTab = (e: any) => {
    setTabActive(e.target.id);
  };

  const handleChangeProvider = (selected: string) => {
    setInsuranceProvider(selected);
  };

  const createOrGetInsuranceProvider = async (): Promise<
    AxiosResponse<InsuranceDTOResponse> | { data: { id: string } }
  > => {
    if (
      existingInsuranceData &&
      existingInsuranceData?.provider?.name === insuranceProvider &&
      existingInsuranceData?.membershipId === insuranceNumber &&
      existingInsuranceData?.registeredAddress === address
    )
      return Promise.resolve({
        data: { id: existingInsuranceData.id },
      });

    const insuranceDto: InsuranceDTORequest = {
      membershipId: insuranceNumber,
      provider: insuranceProvider,
      registeredAddress: address,
      rank: 1,
    };
    return httpPost(insurance, insuranceDto).then(
      (res: AxiosResponse<InsuranceDTOResponse>) =>
        new Promise((resolve) => {
          dispatch(updateInsuranceData(res.data));
          resolve(res);
        })
    );
  };

  const handleContinue = async () => {
    if (haveErrors) {
      client.emit(Events.SCROLL_TO_TOP);
      setShowErrors(true);
      return;
    }

    openConfirmBookingModal();
  };

  // const handleConfirmInsurance = () => {
  //   setIsLoading(true);
  //   createOrGetInsuranceProvider()
  //     .then(
  //       (res: AxiosResponse<InsuranceDTOResponse> | { data: { id: string } }) =>
  //         prepareDocumentsForUpload(
  //           res.data.id,
  //           frontIdFile,
  //           backIdFile,
  //           frontInsuranceIdFile,
  //           backInsuranceIdFile
  //         )
  //     )
  //     .then((documentArray: IDocumentDTO[]) => {
  //       return Promise.allSettled(
  //         documentArray.map((document) => userService.uploadDocument(document))
  //       );
  //     })
  //     .then(() =>
  //       createAppointmentType(
  //         thirdStep,
  //         fourthStep,
  //         overrideAppointmentTypeName,
  //         phoneNumber,
  //         isPcp
  //       )
  //         .then(() => {
  //           setIsLoading(false);
  //           dispatch(getUserTags());
  //           if (isPcp) dispatch(clearPcpState());
  //           else dispatch(clearBookingAppointmentState());
  //           return navigate(appointmentReserved);
  //         })
  //         .catch((err: AxiosError) => {
  //           setIsLoading(false);
  //           if (err.response?.status === 409) {
  //             setSlotTaken(true);
  //             return;
  //           }
  //           setBookingError(true);
  //         })
  //     )
  //     .catch(() => {
  //       setIsLoading(false);
  //     });
  // };

  // const handleConfirmSelfPay = () => {
  //   setIsLoading(true);
  //   const { origin } = window.location;
  //   const stripeRedirectUrl = `${origin}/${processingStripe}`;

  //   const stripePayload = {
  //     appointmentType: appointmentTypeName,
  //     successUrl: stripeRedirectUrl,
  //     cancelUrl: stripeCancelUrl,
  //   };

  //   if (!overrideAppointmentTypeName) return;

  //   const bookingPayload = generateBookingObject(
  //     thirdStep,
  //     fourthStep,
  //     overrideAppointmentTypeName,
  //     phoneNumber,
  //     isPcp
  //   );

  //   userService
  //     .initiateStripe(stripe, stripePayload)
  //     .then(({ data }: AxiosResponse<{ id: string; url: string }>) => {
  //       setItem("stripe_session_id", {
  //         session_id: data.id,
  //         selfPay: true,
  //         stripeCheckoutSessionId: data.id,
  //         ...bookingPayload,
  //       });
  //       window.location.assign(data.url);
  //     })
  //     .catch(() => {
  //       setIsLoading(false);
  //       setBookingError(true);
  //     });
  // };

  // const handleConfirm = () => {
  //   if (!appointmentTypeName) {
  //     setBookingError(true);
  //   }

  //   if (tabActive === "selfPay") {
  //     handleConfirmSelfPay();
  //     return;
  //   }

  //   handleConfirmInsurance();
  // };

  const closeInfoModal = () => setInfoModalOpened(false);
  const handleOpenInfoModal = () => setInfoModalOpened(true);
  const openConfirmBookingModal = () => setShowConfirmationModal(true);
  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
    setBookingError(false);
  };

  const exitForm = () => {
    if (isPcp) dispatch(clearPcpState());
    else dispatch(clearBookingAppointmentState());
    navigate(`/${dashboardAppointments}`);
  };

  const handleGoBack = () => {
    if (isPcp) {
      dispatch(setPcpGoBack());
      return;
    }
    dispatch(setBookingAppointmentGoBack());
  };

  return (
    <>
      <div
        className={classNames([styles.paymentWrapper], {
          [styles.withoutActionContainer]: tabActive === "selfPay",
        })}
      >
        <h2 className="FormComponent-title">How do you want to pay?</h2>
        <div
          className={classNames([styles.paymemtFormContainer], {
            [styles.marginBottomFix]: tabActive === "useInsurance",
          })}
        >
          <div className={styles.tabs_container}>
            <div
              className={classNames([styles.tab_useInsurance], {
                [styles.activeTab]: tabActive === "useInsurance",
              })}
              role="button"
              tabIndex={0}
              id="useInsurance"
              onClick={toggleTab}
            >
              Use insurance
            </div>
            <div
              className={classNames([styles.tab_selfPay], {
                [styles.activeTab]: tabActive === "selfPay",
              })}
              role="button"
              tabIndex={0}
              id="selfPay"
              onClick={toggleTab}
            >
              Self-pay
            </div>
          </div>
          <div className={styles.tabs_contentWrapper}>
            {tabActive === "useInsurance" ? (
              // useInsurance tab
              <div className={styles.singleTabContent}>
                <p className={styles.subTitle}>
                  Please share your insurer details
                </p>
                <div className={styles.tabContent_wrapper}>
                  <Dropdown
                    initialValue={insuranceProvider}
                    label="Choose insurance provider"
                    classes={styles.insuranceProviderStyle}
                    options={insuranceProviders}
                    onChange={handleChangeProvider}
                    error={providerErrors[0]}
                    showError={showErrors}
                  />
                  <InputField
                    value={insuranceNumber}
                    onChange={handleChangeInsuranceNumber}
                    error={insuranceNumberErrors[0]}
                    showError={showErrors}
                    label="Membership ID or insurance number"
                    id="insurnaceNumber"
                  />
                  {/* <InputField
                    value={address}
                    onChange={handleChangeAddress}
                    error={insuranceAddressErrors[0]}
                    showError={showErrors}
                    label="Registered address"
                    classes={styles.addressFieldInput}
                    id="address"
                  /> */}
                  {/* <CheckBox
                    description={<>Same as my home address</>}
                    checked={isSameAddress}
                    onChange={setIsSameAddress}
                    errorMessage="Please agree to the terms and conditions and privacy policy"
                    showError={false}
                    classes={styles.checkboxStyle}
                    id="sameAddress"
                  /> */}
                </div>
                <p className={styles.uploadDetailsTitle}>
                  Please share your insurance documents
                </p>
                <p className={styles.uploadDetailsHelp}>
                  You can do this later if you if like. Supported file types are
                  png and jpeg.
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={handleOpenInfoModal}
                  >
                    Need some help with it?
                  </span>
                </p>
                {/* Modal */}
                <Modal
                  isOpened={infoModalOpened}
                  handleClose={closeInfoModal}
                  title="Uploading your insurance documents"
                  classes={styles.modalStyle}
                  buttons={
                    <Button
                      text="Ok, got it"
                      color="blue"
                      size="small"
                      handleClick={closeInfoModal}
                    />
                  }
                >
                  <>
                    <p className={styles.modalDescription}>
                      You can share you insurance documents by photographing
                      them with your smartphone.
                    </p>
                    <div className={styles.modalItem}>
                      <LayDocumentIcon />
                      <span>
                        Don’t obscure any info on the document. Lay the document
                        down flat rather than holding it.
                      </span>
                    </div>
                    <div className={styles.modalItem}>
                      <FocusPhotoIcon />
                      <span>
                        Ensure the document is fully within your <br />
                        camera view finder. Ensure that your photo is <br />
                        in focus, and has no glare.
                      </span>
                    </div>
                    <div className={styles.modalItem}>
                      <LightPhotoIcon />
                      <span>
                        Ensure your document photo is taken in good light -
                        natural sunlight if possible.
                      </span>
                    </div>
                  </>
                </Modal>

                {/* File uploads */}
                <div className={styles.tabContent_wrapper}>
                  <FileUpload
                    id={1}
                    fileUrl={frontInsuranceId}
                    setFileUrl={setFrontInsuranceId}
                    title={
                      <>
                        Upload <b>front</b> of insurance ID card
                      </>
                    }
                    icon={<InsuranceFront />}
                    uploadedText="Front of insurance ID added!"
                    classes={styles.fieldUploadStyle}
                    error="Please upload the requested document"
                    showError={false}
                    fileName="insuranceFront"
                    fileData={frontInsuranceIdFile}
                    setFileData={setFrontInsuranceIdFile}
                    type={DocumentType.INSURANCE}
                    side={1}
                  />
                  <FileUpload
                    id={2}
                    fileUrl={backInsuranceId}
                    setFileUrl={setBackInsuranceId}
                    title={
                      <>
                        Upload <b>back</b> of insurance ID card
                      </>
                    }
                    icon={<InsuranceBack />}
                    uploadedText="Back of insurance ID added!"
                    error="Please upload the requested document"
                    showError={false}
                    fileName="insuranceBack"
                    fileData={backInsuranceIdFile}
                    setFileData={setBackInsuranceIdFile}
                    type={DocumentType.INSURANCE}
                    side={2}
                  />
                  <FileUpload
                    id={3}
                    fileUrl={frontId}
                    setFileUrl={setFrontId}
                    title={
                      <>
                        Upload <b>front</b> of picture ID
                      </>
                    }
                    description="(e.g. a passport or driving license)"
                    icon={<IdFront />}
                    uploadedText="Front of ID card added!"
                    error="Please upload the requested document"
                    showError={false}
                    fileName="idFront"
                    fileData={frontIdFile}
                    setFileData={setFrontIdFile}
                    type={DocumentType.MISC}
                    side={1}
                  />
                  <FileUpload
                    id={4}
                    fileUrl={backId}
                    setFileUrl={setBackId}
                    title={
                      <>
                        Upload <b>back</b> of picture ID
                      </>
                    }
                    icon={<IdBack />}
                    uploadedText="Back of ID card added!"
                    error="Please upload the requested document"
                    showError={false}
                    fileName="idBack"
                    fileData={backIdFile}
                    setFileData={setBackIdFile}
                    type={DocumentType.MISC}
                    side={2}
                  />
                </div>
              </div>
            ) : (
              <SelfPayTab
                openConfirmBookingModal={openConfirmBookingModal}
                price={servicePrice}
                isLoading={isLoadingPrice}
              />
            )}
          </div>
        </div>
      </div>
      {tabActive === "useInsurance" && (
        <ActionsContainer>
          <Button
            text="Confirm booking"
            size="large"
            color="blue"
            handleClick={handleContinue}
            withArrow
          />
        </ActionsContainer>
      )}
      {/* 
      Modal selfpay 
      */}
      {/* <PaymentModal
        bookingAppointment={bookingAppointment}
        handleCancel={closeConfirmationModal}
        handleCancelSecondary={exitForm}
        handleConfirm={handleConfirm}
        handleConfirmSecondary={handleGoBack}
        isLoading={isLoading}
        slotTaken={slotTaken}
        showModal={showConfirmationModal}
        tabActive={tabActive}
        bookingError={bookingError}
        appointmentType={appointmentContactType}
      /> */}
    </>
  );
}

export default Payment;

// util
export const prepareDocumentsForUpload = (
  insuranceId: string,
  ...args: Array<IDocument | null>
): IDocumentDTO[] => {
  const documentFiles = args;
  const documentArray = new Array<IDocumentDTO>();

  documentFiles.forEach((documentFile) => {
    if (documentFile) documentArray.push({ ...documentFile, insuranceId });
  });

  return documentArray;
};
