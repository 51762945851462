import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface Props {
  size: number;
  classes?: string[];
}

function LoadingSpinner({ size, classes }: Props) {
  return (
    <CircularProgress
      size={size}
      className={classNames([styles.loadingSpinner], [classes])}
    />
  );
}

export default LoadingSpinner;
