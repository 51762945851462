import classNames from "classnames";
import styles from "./styles.module.scss";

function FormCard({
  title,
  description = "",
  children,
}: {
  title: string;
  description?: string;
  children: JSX.Element;
}) {
  return (
    <div className={styles.expandedFormWrapper}>
      <div
        className={classNames([styles.expandableHead], [styles.expandedHeader])}
      >
        <div className={styles.titleWrapper}>
          <p className={styles.title}>{title}</p>
        </div>
        {description && <p className={styles.description}>{description}</p>}
      </div>
      <div className={styles.formContainer}>
        <div>{children}</div>
      </div>
    </div>
  );
}

export default FormCard;
