import PageContainer from "components/layout/PageContainer";
import TelephysicalBooking from "components/forms/@flow/TelephysicalBooking";

function FlowBookingAppointment() {
  return (
    <div className="fullPage-blueBackground">
      <PageContainer>
        <TelephysicalBooking />
      </PageContainer>
    </div>
  );
}

export default FlowBookingAppointment;
