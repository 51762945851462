import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { dashboardAppointments, medicalInformation } from "routes/paths";
import { ReactComponent as StepsCompleteIcon } from "assets/images/appointmentStepsCompleted.svg";
import { ReactComponent as GreenCheckmark } from "assets/images/greenCheckmark.svg";
import Button from "components/ui/Button";
import Modal from "components/shared/Modal";
import PrepareAppointment from "components/shared/Modal/PrepareAppointment";
import { useAppSelector } from "redux/store";
import { selectHideMedicalInformation } from "redux/user.slice";
import classNames from "classnames";
import FormContainer from "../FormContainer";
import ActionsContainer from "../FormContainer/ActionsContainer";
import styles from "./styles.module.scss";

function AppointmentReserved() {
  const navigate = useNavigate();
  const [prepareModalOpened, setPrepareModalOpened] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const loadingButton = useRef(false);
  const medicalFormFilled = useAppSelector(selectHideMedicalInformation);

  const handleContinue = () => {
    if (!loadingButton.current) {
      setButtonDisabled(true);
      setTimeout(() => {
        setButtonDisabled(false);
        navigate(`/${dashboardAppointments}`);
      }, 1500);
    }
  };

  const addMedicalInformation = () => {
    navigate(medicalInformation);
  };

  const openPrepareModal = () => setPrepareModalOpened(true);
  const closePrepareModal = () => setPrepareModalOpened(false);

  return (
    <FormContainer>
      <div className={styles.completeStepper}>
        <StepsCompleteIcon />
        <GreenCheckmark />
      </div>
      <h2 className={classNames("FormComponent-title", [styles.title])}>
        You’ve reserved an appointment slot!
      </h2>

      <div className={styles.wrapper}>
        <p className={styles.reservedSubtitle}>What happens next?</p>
        <p className={styles.reservedDescription}>
          Our clinical support team will contact you to confirm your
          appointment, please be ready to answer your phone. You can read about
          <span role="button" tabIndex={0} onClick={openPrepareModal}>
            how to prepare for and join a consultation here
          </span>
          . We’ll email you this information too – please ensure our email
          address <b>hidoc@cloudwellhealth.com</b> is on your approved list.
        </p>
      </div>

      {!medicalFormFilled && (
        <div className={styles.addInfoWrapper}>
          <p className={styles.reservedSubtitle}>Add medical information</p>
          <p className={styles.reservedDescription}>
            To maximise your time with the practitioner, you can
            <span
              className="link"
              role="button"
              tabIndex={0}
              onClick={addMedicalInformation}
            >
              fill in your medical information online
            </span>
            . If you don’t complete this now, our clinical support team will
            take the details over the phone or the practitioner will take them
            during the appointment.
          </p>
        </div>
      )}
      <ActionsContainer>
        <Button
          text="My account"
          size="large"
          color="blue"
          handleClick={handleContinue}
          withArrow
          isLoading={buttonDisabled}
        />
      </ActionsContainer>
      {/* Prepare modal */}
      <Modal
        isOpened={prepareModalOpened}
        handleClose={closePrepareModal}
        title="How to prepare for and join a consultation"
        buttons={
          <Button
            text="OK, got it"
            color="blue"
            size="small"
            handleClick={closePrepareModal}
          />
        }
      >
        <PrepareAppointment />
      </Modal>
    </FormContainer>
  );
}

export default AppointmentReserved;
