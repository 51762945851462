import { useNavigate } from "react-router-dom";
import { medicalInformation } from "routes/paths";
import styles from "./prepareAppointments.module.scss";

function PrepareAppointment() {
  const navigate = useNavigate();
  const addMedicalInformation = () => {
    navigate(medicalInformation);
  };
  return (
    <>
      <p className={styles.description}>
        Before your appointment begins, try to find a private and quiet place,
        and make sure you have a strong phone signal, or stable internet
        connection.
      </p>
      <p className={styles.subHeader}>Video consultations</p>
      <p className={styles.description}>
        You will receive an email 20 minutes before your appointment start time
        with a Zoom link. Click this to join the waiting room and the doctor
        will admit you when they’re ready to begin. Ensure your device ready by
        checking the following...
      </p>
      <ul className={styles.list}>
        <li>Safari (Not Chrome) must be used on Apple tablets and iPhones.</li>
        <li>Software and browser have been updated to the latest versions.</li>
        <li>
          Permissions for camera and microphone use enabled upon logging in.
        </li>
        <li>Cache and browser history has been cleared.</li>
      </ul>
      <p className={styles.subHeader}>Phone consultations</p>
      <p className={styles.description}>
        The doctor will call you directly at the time of your appointment on the
        number you have specified.
      </p>
      {/* <p className={styles.subHeader}>Add your medical information</p>
      <p className={styles.description}>
        Help us by making sure your profile is up-to-date.{" "}
        <span
          className="link"
          role="button"
          tabIndex={0}
          onClick={addMedicalInformation}
        >
          Add medical information
        </span>
      </p> */}
    </>
  );
}

export default PrepareAppointment;
