import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "components/ui/Button";
import DatePicker from "components/ui/DatePicker";
import BookingService from "services/bookingService";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  saveFourthPcpStep,
  selectFourthPcpStep,
  selectSecondPcpStep,
} from "redux/pcpFlow.slice";
import useValidate from "utils/validation";
import { appointmentReserved } from "routes/paths";
import { VALIDATE_TIME_SLOT } from "utils/validation/constants";
import client, { Events } from "services/EventEmitter";
import {
  defaultFormatDate,
  getCurrentHawaiiDate,
} from "utils/transform/formatDate";
import {
  saveFourthBookingAppointmentStep,
  selectFourthBookingAppointmentStep,
  selectSecondBookingAppointmentStep,
} from "redux/bookAppointmentFlow.slice";
import useAppointmentData from "hooks/useAppointmentData";
import { AxiosResponse } from "axios";
import ConfirmModal from "components/forms/Payment/ConfirmModal";
import { generateBookingObject } from "utils/transform/createAppointmentType";
import { isToday } from "date-fns";
import TimeSlotPicker from "../TimeSlotPicker";
import ActionsContainer from "../../forms/FormContainer/ActionsContainer";
import ValidationError from "../ValidationError";
import Modal from "../Modal";
import styles from "./styles.module.scss";

interface Props {
  nextStep: (stepId: number) => void;
  bookingAppointment?: boolean;
}

interface ITimeSlotsData {
  availableTimeslots: string[];
}

interface ICurrentlyAfterHoursData {
  isAfterHours: boolean;
}

interface ICurrentlyHolidayDateData {
  isHoliday: boolean;
}

function TimeAndDate({ nextStep, bookingAppointment = false }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isPcp = !bookingAppointment;

  const secondStep = useAppSelector(
    bookingAppointment
      ? selectSecondBookingAppointmentStep
      : selectSecondPcpStep
  );

  const [slotTaken, setSlotTaken] = useState<boolean>(false);

  const { appointmentTypeName, thirdStep, appointmentContactType } =
    useAppointmentData(bookingAppointment);

  const [selectedDay, setSelectedDay] = useState<Date>(getCurrentHawaiiDate());
  const [confirmModalOpened, setConfirmModalOpened] = useState<boolean>(false);

  const [slots, setSlots] = useState<string[]>([]);
  const [selectedSlot, setSelectedSlot] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAfterHours, setIsAfterHours] = useState(false);
  const [isHoliday, setIsHoliday] = useState(false);
  const [afterHoursModalOpened, setAfterHoursModalOpened] = useState(false);

  const [isTimeSlotValid, timeSlotErrors] = useValidate(
    selectedSlot,
    VALIDATE_TIME_SLOT
  );

  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    setHaveErrors(!isTimeSlotValid || !selectedDay);
  }, [isTimeSlotValid, selectedDay]);

  useEffect(() => {
    setIsLoading(true);
    const date = defaultFormatDate(selectedDay);

    BookingService.isAfterHours({})
      .then((res: AxiosResponse<ICurrentlyAfterHoursData>) => {
        setIsAfterHours(res.data.isAfterHours);
      })
      .catch(() => null);

    BookingService.isDateHoliday({ date: defaultFormatDate(selectedDay) })
      .then((res: AxiosResponse<ICurrentlyHolidayDateData>) => {
        setIsHoliday(res.data.isHoliday);
      })
      .catch(() => null);

    BookingService.findAppointmentByDate({
      date,
      timezone: "Pacific/Honolulu",
      appointmentType: appointmentTypeName,
    })
      .then((res: AxiosResponse<ITimeSlotsData>) => {
        const slots = res.data.availableTimeslots;
        const transformedSlots = slots.map(
          (slot: string) => `${slot}@${defaultFormatDate(selectedDay)}`
        );
        setSlots(transformedSlots);
      })
      .catch(() => null)
      .finally(() => setIsLoading(false));
  }, [selectedDay]);

  const handleSlotSelect = (slot: string) => setSelectedSlot(slot);

  const openConfirmModal = (status: boolean) => {
    setConfirmModalOpened(true);
  };

  const handleContinue = () => {
    if (haveErrors) {
      client.emit(Events.SCROLL_TO_TOP);
      setShowErrors(true);
      return;
    }

    if (isPcp) {
      dispatch(
        saveFourthPcpStep({
          appointmentDate: selectedDay,
          timeSlot: selectedSlot,
        })
      );
    } else {
      dispatch(
        saveFourthBookingAppointmentStep({
          appointmentDate: selectedDay,
          timeSlot: selectedSlot,
        })
      );
    }

    openConfirmModal(true);
  };

  const handleConfirm = () => {
    const { appointmentType: contactType, appointmentPhone: phoneNumber } =
      secondStep;

    if (!appointmentTypeName) return;

    const bookingObject = generateBookingObject(
      thirdStep,
      {
        appointmentDate: selectedDay,
        timeSlot: selectedSlot,
      },
      appointmentTypeName,
      phoneNumber,
      isPcp
    );
    setIsLoading(true);
    BookingService.bookAppointment(bookingObject)
      .then(() => navigate(appointmentReserved))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <h2 className="FormComponent-title">Time and date</h2>
      <p className="FormComponent-description">
        When do you want to speak to a doctor? Please select a date, and a time
        slot.
      </p>
      <DatePicker
        label="Select a date"
        day={selectedDay}
        onChange={setSelectedDay}
      />
      <TimeSlotPicker
        label="Select a time slot"
        slots={slots}
        selectedSlotId={selectedSlot}
        handleSlotSelect={handleSlotSelect}
        isLoading={isLoading}
      />
      <ValidationError show={showErrors} error={timeSlotErrors[0]} />

      {!isHoliday && isAfterHours && isToday(selectedDay) && (
        <p className={styles.afterHours}>
          For after hours appointment between 5 PM and 8 AM&nbsp;
          <span
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => setAfterHoursModalOpened(true)}
          >
            click here
          </span>
        </p>
      )}

      {isHoliday && isToday(selectedDay) && (
        <p className={styles.afterHours}>
          Today we are closed for holiday. For urgent care &nbsp;
          <span
            className="link"
            role="button"
            tabIndex={0}
            onClick={() => setAfterHoursModalOpened(true)}
          >
            click here
          </span>
        </p>
      )}
      <ActionsContainer>
        <Button
          text="Confirm booking"
          size="large"
          color="blue"
          handleClick={handleContinue}
          withArrow
        />
      </ActionsContainer>

      <Modal
        isOpened={confirmModalOpened}
        handleClose={() => setConfirmModalOpened(false)}
        title="Confirm booking?"
        buttons={
          <>
            <Button
              text="Go back"
              color="outlined_blue"
              size="small"
              handleClick={() => setConfirmModalOpened(false)}
            />

            <Button
              text="Confirm booking"
              color="blue"
              size="small"
              handleClick={handleConfirm}
              isLoading={isLoading}
            />
          </>
        }
      >
        <ConfirmModal
          timeSlot={selectedSlot}
          paymentType="selfPay"
          slotTaken={slotTaken}
          appointmentType={appointmentContactType}
        />
      </Modal>

      <Modal
        isOpened={afterHoursModalOpened}
        handleClose={() => setAfterHoursModalOpened(false)}
        title="For consults between 10pm to 6am:"
        buttons={
          <>
            <Button
              text="Cancel"
              color="outlined_blue"
              size="small"
              handleClick={() => setAfterHoursModalOpened(false)}
            />
            <Button
              text="Download Instructions"
              color="blue"
              size="small"
              handleClick={() =>
                window.open("https://hidoconline.com/mdliveinstructions/")
              }
            />
          </>
        }
      >
        <>
          <p>
            HiDoc online service is available 8AM to 5PM Monday through Friday
            (Except major holidays). Members can still see a physician or mental
            health professional <b>during these hours</b> through MDLive and
            receive 100% reimbursement when you provide a copy of your receipt
            to&nbsp;
            <a href="mailto:HMAAwellness@hmaa.com">HMAAwellness@hmaa.com</a>.
          </p>
        </>
      </Modal>
    </>
  );
}

export default TimeAndDate;
