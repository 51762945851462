import PageContainer from "components/layout/PageContainer";
import Pcp from "components/forms/@flow/Pcp";

function FlowPCP() {
  return (
    <div className="fullPage-blueBackground">
      <PageContainer>
        <Pcp />
      </PageContainer>
    </div>
  );
}

export default FlowPCP;
