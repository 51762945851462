import { ChangeEvent } from "react";
import InputLabel from "@mui/material/InputLabel";
import InputBase from "@mui/material/InputBase";
import { ReactComponent as ErrorIcon } from "assets/images/errorIcon.svg";
import styles from "./styles.module.scss";

interface Props {
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  required?: boolean;
  error?: string | undefined;
  showError?: boolean;
  rows?: number;
  description?: string;
  id: string;
}

function TextArea({
  value,
  onChange,
  label,
  required = true,
  error,
  showError = false,
  rows = 5,
  description = "",
  id,
}: Props) {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event);
  };

  const displayLabel = `${label}${required ? "*" : " (optional)"}`;

  return (
    <div className={styles.textArea_wrapper}>
      <InputLabel htmlFor={id} className={styles.textArea_label}>
        {displayLabel}
      </InputLabel>
      {description && <p className={styles.description}>{description}</p>}
      <InputBase
        onChange={handleChange}
        fullWidth
        rows={rows}
        multiline={true}
        id={id}
        disabled={false}
        className={styles.textArea_input}
        value={value}
      />
      {showError && error && (
        <p className={styles.errorWrapper}>
          <ErrorIcon className={styles.errorIcon} />
          <p className={styles.error}>{error}</p>
        </p>
      )}
    </div>
  );
}

export default TextArea;
