import { useEffect, useState } from "react";
import Button from "components/ui/Button";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import {
  checkVideoAppointmentLink,
  formatAppointmentTitle,
  isVideoAppointment,
  parseAppointmentType,
} from "utils/appointmentUtils";
import type { IAppointmentDTO } from "./AppointmentsList";
import styles from "./styles.module.scss";

interface IAppointmentListItem {
  item: IAppointmentDTO;
  type: "upcoming" | "previous";
}

const AppointmentListItem = ({ item, type }: IAppointmentListItem) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`${item.id}`);
  };

  const isCancelled = item.status === "Cancelled";

  return (
    <>
      <h4 className={styles.title}>
        {parseAppointmentType(item.appointmentType.name)}
      </h4>
      <span className={styles.description}>{formatAppointmentTitle(item)}</span>
      {isCancelled ? (
        <p className={styles.cancelledText}>Appointment cancelled</p>
      ) : (
        <div className={styles.openButtonWrapper}>
          <Button
            color="sky_blue"
            text="View details"
            size="small"
            className={styles.buttonStyle}
            handleClick={handleNavigate}
          />
        </div>
      )}
      {!isCancelled && type === "upcoming" && isVideoAppointment(item) && (
        <VideoAppointmentLink item={item} />
      )}
    </>
  );
};

export const VideoAppointmentLink = ({
  item,
  AppointmentDetailsComponent = null,
}: Pick<IAppointmentListItem, "item"> & {
  AppointmentDetailsComponent?: JSX.Element | null;
}) => {
  const [shouldBeDisplayed, setShouldBeDisplayed] = useState<boolean>(
    checkVideoAppointmentLink(item.scheduledDate, item.appointmentType.duration)
  );
  const [showCopyLink, setShowCopyLink] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShouldBeDisplayed(
        checkVideoAppointmentLink(
          item.scheduledDate,
          item.appointmentType.duration
        )
      );
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleToggleLink = () => {
    if (!showCopyLink) return;
    handleCopyLink();
    setShowCopyLink(false);
    setTimeout(() => {
      setShowCopyLink(true);
    }, 5000);
  };

  if (!shouldBeDisplayed && !AppointmentDetailsComponent) return null;
  if (!shouldBeDisplayed) return AppointmentDetailsComponent;
  if (!isVideoAppointment(item)) return null;

  const handleOpenVideo = () => {
    if (item.zoomLink) window.open(item.zoomLink, "_blank");
  };

  const handleCopyLink = () => {
    if (item.zoomLink) navigator.clipboard.writeText(item.zoomLink);
  };

  return (
    <div className={styles.videoAppointmentLink}>
      <Button
        color="sky_blue"
        text="Appointment link (Zoom)"
        size="large"
        className={styles.button}
        handleClick={handleOpenVideo}
      />
      <button
        onClick={handleToggleLink}
        className={classNames([styles.buttonWrapper, styles.cursorPointer])}
      >
        <span>{showCopyLink ? "Copy link" : "Link copied!"}</span>
      </button>
    </div>
  );
};

export default AppointmentListItem;
