import { useState } from "react";
import ExpandableForm from "components/shared/ExpandableForm/ExpandableForm";
import UploadDocument from "./UploadDocument";
import styles from "./styles.module.scss";

function Documents() {
  const [formExpanded, setFormExpanded] = useState<number>(0);

  const toggleForm = (id: number) => {
    setFormExpanded(id);
  };
  return (
    <div className={styles.myProfilePaddingWrapper}>
      <h2 className="FormComponent-title">Documents</h2>
      <p className="FormComponent-description"></p>
      <ExpandableForm
        id={1}
        title="Upload Document"
        description="Send HiDoc a document"
        form={<UploadDocument handleClose={() => setFormExpanded(0)} />}
        isExpanded={formExpanded === 1}
        readOnly={true}
        handleExpand={toggleForm}
      />
    </div>
  );
}

export default Documents;
