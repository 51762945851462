import { format } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { enUS } from "date-fns/locale";

// Example: Monday November 21st 2022
export const confirmBookingFormatDate = (isoString: string) =>
  format(new Date(isoString), "EEEE LLLL do yyyy", {
    locale: enUS,
  });

// Example: 07:30am
export const confirmBookingFormatHours = (isoString: string) =>
  format(new Date(isoString), "hh:mmaaa", {
    locale: enUS,
  });

export const formatTimeSlotTime = (time12h: string) => {
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_, time, modifier] = time12h.match(/(\d?\d:\d\d)\s*(\w{2})/i);

  // eslint-disable-next-line prefer-const
  let [hours, minutes]: [hours: string | number, minutes: string] =
    time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "pm") {
    // @ts-expect-error
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}:00`;
};

// Example yyyy-MM-dd
export const defaultFormatDate = (date: Date) =>
  format(date, "yyyy-MM-dd", {
    locale: enUS,
  });

// Example dd/MM/yyyy
export const formatDisplayDate = (date: Date) =>
  format(date, "dd/MM/yyyy", {
    locale: enUS,
  });

// Example Wednesday August 30th
export const formatDate = (date: Date) =>
  format(date, "EEEE MMMM do", {
    locale: enUS,
  });

export const getCurrentHawaiiDate = (): Date =>
  utcToZonedTime(new Date(), "Pacific/Honolulu");

export const convertDateToHawaii = (date: string) =>
  zonedTimeToUtc(new Date(date), "Pacific/Honolulu");
