import InputLabel from "@mui/material/InputLabel";
import classNames from "classnames";
import { confirmBookingFormatHours } from "utils/transform/formatDate";
import LoadingSpinner from "../LoadingSpinner";
import styles from "./styles.module.scss";

interface Props {
  label: string;
  slots: string[];
  selectedSlotId: string | undefined;
  handleSlotSelect: any;
  required?: boolean;
  isLoading: boolean;
}

export interface ISlot {
  id: string;
  scheduled_date: string;
  duration: number;
  physician: number;
  status: {
    status: string;
  };
}

const Slot = ({
  slot,
  onClickHandler,
  selectedSlotId,
}: {
  slot: string;
  onClickHandler: any;
  selectedSlotId: string | undefined;
}) => {
  const isAvailable = true;
  const selected: boolean = slot === selectedSlotId;

  const handleClick = () => {
    if (!isAvailable) return;
    onClickHandler(slot);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleClick}
      className={classNames([styles.timeSlot], {
        [styles.notAvailableSlot]: !isAvailable,
        [styles.selected]: selected,
      })}
    >
      {/* {confirmBookingFormatHours(slot)} */}
      {slot.split("@")[0]}
    </div>
  );
};

function TimeSlotPicker({
  label,
  slots,
  selectedSlotId,
  handleSlotSelect,
  required = true,
  isLoading,
}: Props) {
  const displayLabel = `${label}${required ? "*" : " (optional)"}`;

  const showSlots = slots.length > 0;

  return (
    <div className={styles.wrapper}>
      <InputLabel className={styles.label}>
        {displayLabel}{" "}
        <span className={styles.timeZoneMessage}>(All times shown in HST)</span>
      </InputLabel>
      <div
        className={classNames([styles.timePickerWrapper], {
          [styles.loading]: isLoading,
          [styles.noSlotsWrapper]: !isLoading && !showSlots,
        })}
      >
        {!isLoading &&
          showSlots &&
          slots.map((slot) => (
            <Slot
              slot={slot}
              onClickHandler={handleSlotSelect}
              selectedSlotId={selectedSlotId}
            />
          ))}
        {!isLoading && !showSlots && (
          <p className={styles.noSlotsMessage}>
            Sorry! There is no availabilty on the selected date. Please choose a
            different date.
          </p>
        )}
        {isLoading && (
          <>
            <span>Loading</span>
            <LoadingSpinner size={30} />
          </>
        )}
      </div>
    </div>
  );
}

export default TimeSlotPicker;
