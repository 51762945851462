import MuiButton from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LoadingSpinner from "components/shared/LoadingSpinner";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface Props {
  text: string;
  descriptionText?: string;
  to?: string | undefined;
  handleClick?: any;
  disabled?: boolean;
  size: ButtonSize;
  color: ButtonColor;
  className?: string | undefined;
  withArrow?: boolean;
  isLoading?: boolean;
  withoutLoadingSpinner?: boolean;
}

type ButtonSize = "small" | "large";

type ButtonColor =
  | "blue"
  | "light_blue"
  | "gray"
  | "light_gray"
  | "secondary"
  | "red"
  | "red_secondary"
  | "outlined_blue"
  | "outlined_light_blue"
  | "outlined_gray"
  | "outlined_light_gray"
  | "outlined_secondary"
  | "red_outline"
  | "sky_blue";

function Button({
  text,
  descriptionText = "",
  to = undefined,
  disabled = false,
  size,
  color,
  className,
  handleClick,
  withArrow = false,
  isLoading = false,
  withoutLoadingSpinner = false,
}: Props) {
  const classes: string = classNames(styles[size], styles[color], className, {
    [styles.disabled]: disabled,
  });

  const buttonHandleClick = () => {
    if (isLoading || !handleClick || disabled) return;
    handleClick();
  };

  return (
    <MuiButton
      onClick={buttonHandleClick}
      size="medium"
      variant="contained"
      href={to}
      className={classNames(classes, { [styles.loading]: isLoading })}
      disableRipple
      disableElevation
      classes={{ disabled: styles.disabled }}
    >
      {text}
      {descriptionText && (
        <span className={styles.descriptionText}>{`(${descriptionText})`}</span>
      )}
      {withArrow && <KeyboardArrowRightIcon className={styles.arrow} />}
      {isLoading && !withoutLoadingSpinner && <LoadingSpinner size={13} />}
    </MuiButton>
  );
}

export default Button;
