export function getItem(keyName: string) {
  try {
    return JSON.parse(localStorage.getItem(keyName) || "");
  } catch (error) {
    return null;
  }
}

export function setItem(keyName: string, value: any) {
  localStorage.setItem(keyName, JSON.stringify(value));
}

export function removeItem(keyName: string) {
  localStorage.removeItem(keyName);
}
