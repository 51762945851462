import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormContainer from "components/forms/FormContainer";
import PageContainer from "components/layout/PageContainer";
import InputField from "components/ui/InputField";
import Dropdown from "components/ui/Dropdown";
import TextArea from "components/ui/TextArea";
import RadioGroup from "components/ui/RadioGroup";
import DobPicker from "components/ui/DobPicker";
import Checkbox from "components/ui/Checkbox";
import ValidationError from "components/shared/ValidationError";
import Button from "components/ui/Button";
import ActionsContainer from "components/forms/FormContainer/ActionsContainer";
import userService from "services/userService";
import client, { Events } from "services/EventEmitter";
import FormCard from "./FormCard";
import styles from "./styles.module.scss";

function MedicalInformation() {
  const navigate = useNavigate();

  const genericErrorMessage = "Required field";

  const [emergencyContactName, setEmergencyContactName] = useState<string>("");
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState<string>("");
  const [preferredPharmacy, setPreferredPharmacy] = useState<string>("");
  const [relationship, setRelationship] = useState<string>("");
  const [recentPcp, setRecentPcp] = useState<string>("");
  const [specialistCare, setSpecialistCare] = useState<"yes" | "no" | null>(
    null
  );
  const [occupation, setOccupation] = useState<string>("");
  const [disabilities, setDisabilities] = useState<"yes" | "no" | null>(null);
  const [livingOption, setLivingOption] = useState<
    "alone" | "withSpouse" | "withFamily" | "other" | null
  >(null);
  const [medicalCondition, setMedicalCondition] = useState<"yes" | "no" | null>(
    null
  );
  const [surgery, setSurgery] = useState<"yes" | "no" | null>(null);

  const [familyHistory, setFamilyHistory] = useState<"yes" | "no" | null>(null);
  const [drugOption, setDrugOption] = useState<"yes" | "no" | null>(null);
  const [medications, setMedications] = useState<string>("");
  const [exercise, setExercise] = useState("");
  const [height, setHeight] = useState<string>("");
  const [systolicPressure, setSystolicPressure] = useState<string>();
  const [dateOfReading, setDateOfReading] = useState<string>("");
  const [smoking, setSmoking] = useState<string>("");
  const [alcohol, setAlcohol] = useState<string>("");
  const [drugsMarijuana, setDrugsMarijuana] = useState<boolean>(false);
  const [methamphetamine, setMethamphetamine] = useState<boolean>(false);
  const [heroin, setHeroin] = useState<boolean>(false);
  const [cocaine, setCocaine] = useState<boolean>(false);
  const [opiates, setOpiates] = useState<boolean>(false);
  const [none, setNone] = useState<boolean>(false);
  const [feeling, setFeeling] = useState<any>(null);
  const [worrying, setWorrying] = useState<any>(null);
  const [interes, setInteres] = useState<any>(null);
  const [down, setDown] = useState<any>(null);

  const [scValue, setScValue] = useState<string>("");
  const [disDetails, setDisDetails] = useState("");
  const [livDetails, setLivDetails] = useState("");
  const [mcDet, setMcDet] = useState("");
  const [surgeryDesc, setSurgeryDesc] = useState("");
  const [famDesc, setFamDesc] = useState("");
  const [drugAlDesc, setDrugAlDesc] = useState("");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<any>(null);

  const handleChangeEmergencyContactName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmergencyContactName(e.target.value);
  };
  const handleChangePreferredPharmacy = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPreferredPharmacy(e.target.value);
  };
  const handleChangeEmergencyPhoneNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmergencyPhoneNumber(e.target.value);
  };

  const handleChangeRelationship = (selected: string) => {
    setRelationship(selected);
  };

  const handleChangeRecentPcp = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRecentPcp(e.target.value);
  };

  const handleChangeSystolicPressure = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSystolicPressure(e.target.value);
  };

  const handleChangeSpecialistCare = (value: "yes" | "no" | null) => {
    setSpecialistCare(value);
  };

  const handleChangeOccupation = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOccupation(e.target.value);
  };

  const handleChangeHeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeight(e.target.value);
  };

  const handleChangeDisabilitiesOption = (value: "yes" | "no" | null) => {
    setDisabilities(value);
  };

  const handleChangeLivingOptions = (
    value: "alone" | "withSpouse" | "withFamily" | "other" | null
  ) => {
    setLivingOption(value);
  };

  const handleChangeMedicalCondition = (value: "yes" | "no" | null) => {
    setMedicalCondition(value);
  };

  const handleChangeSurgery = (value: "yes" | "no" | null) => {
    setSurgery(value);
  };

  const handleChangeFamilyHistory = (value: "yes" | "no" | null) => {
    setFamilyHistory(value);
  };

  const handleChangeDrugOption = (value: "yes" | "no" | null) => {
    setDrugOption(value);
  };

  const handleChangeMedications = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setMedications(e.target.value);
  };

  const handleChangeExercise = (selected: string) => {
    setExercise(selected);
  };

  const handleChangeDateOfReading = (dob: string) => {
    setDateOfReading(dob);
  };

  const handleChangeSmoking = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSmoking(e.target.value);
  };

  const handleChangeAlcohol = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAlcohol(e.target.value);
  };

  const handleChangeFeeling = (value: any) => {
    setFeeling(value);
  };

  const handleChangeWorrying = (value: any) => {
    setWorrying(value);
  };

  const handleChangeInteres = (value: any) => {
    setInteres(value);
  };

  const handleChangeDown = (value: any) => {
    setDown(value);
  };

  const specialistCareOptions = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" },
  ];
  const disabilitiesOptions = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" },
  ];

  const medicalConditionOptions = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" },
  ];

  const surgeriesOptions = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" },
  ];

  const exerciseOptions = [
    { value: "Daily", display: "Daily" },
    { value: "Several times a week", display: "Several times a week" },
    { value: "Once a week", display: "Once a week" },
    { value: "Less than once a week", display: "Less than once a week" },
    { value: "Never", display: "Never" },
  ];

  const relationshipOptions = [
    { value: "Spouse/Partner", display: "Spouse/Partner" },
    { value: "Parent", display: "Parent" },
    { value: "Sibling", display: "Sibling" },
    { value: "Grandparent", display: "Grandparent" },
    { value: "Relative", display: "Relative" },
    { value: "Friend", display: "Friend" },
    { value: "Other", display: "Other" },
  ];

  const familyHistoryOptions = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" },
  ];

  const feelingOptions = [
    { value: "not", label: "Not at all " },
    { value: "several", label: "Several days " },
    { value: "more", label: "More than half the days " },
    { value: "everyday", label: "Nearly everyday" },
  ];

  const worryingOptions = [
    { value: "not", label: "Not at all " },
    { value: "several", label: "Several days " },
    { value: "more", label: "More than half the days " },
    { value: "everyday", label: "Nearly everyday" },
  ];

  const interesOptions = [
    { value: "not", label: "Not at all " },
    { value: "several", label: "Several days " },
    { value: "more", label: "More than half the days " },
    { value: "everyday", label: "Nearly everyday" },
  ];

  const downOptions = [
    { value: "not", label: "Not at all " },
    { value: "several", label: "Several days " },
    { value: "more", label: "More than half the days " },
    { value: "everyday", label: "Nearly everyday" },
  ];

  const drugOptions = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" },
  ];

  const livingOptions = [
    { value: "alone", label: "Alone" },
    { value: "withSpouse", label: "With spouse" },
    { value: "withFamily", label: "With family " },
    { value: "other", label: "Other" },
  ];

  const drugsList = `${drugsMarijuana ? "Marijuana" : ""} ${
    methamphetamine ? "Methamphetamine" : ""
  } ${heroin ? "Heroin" : ""} ${cocaine ? "Cocaine" : ""} ${
    opiates ? "Opiates (oxycodone, morphine, hydrocodone, etc)" : ""
  } ${none ? "None" : ""}`;

  const saveData = () => {
    setShowError(false);

    const data = {
      emergencyContact: {
        name: emergencyContactName,
        phone: emergencyPhoneNumber,
        relationship,
      },
      generalWelness: {
        name: recentPcp,
        specialistCare: `${specialistCare} - ${scValue}`,
        occupation,
        preferredPharmacy,
        disabilities: `${disabilities} - ${disDetails}`,
        livingArrangements: `${livingOption} - ${livDetails}`,
      },
      medicalHistory: {
        medicalConditions: `${medicalCondition} - ${mcDet}`,
        surgeriesAndProcedures: `${surgery} - ${surgeryDesc}`,
        familyHistory: `${familyHistory} - ${famDesc}`,
        drugAllergies: `${drugOption} - ${drugAlDesc}`,
        currentMedications: medications,
      },
      socialHealthAndLifestyle: {
        exercise,
        height,
        bloodPressure: `${systolicPressure && systolicPressure} - ${
          dateOfReading && dateOfReading
        }`,
        smoking,
        alcohol,
        drugs: drugsList,
      },
      emotionalWellnes: {
        nervous: feeling,
        worrying,
        interest: interes,
        depressed: down,
      },
    };

    if (!validateAllFields()) {
      console.log("Not valid!");
      setShowError(true);
      client.emit(Events.SCROLL_TO_TOP);
      return;
    }

    setIsLoading(true);
    userService
      .saveMedicalData(data)
      .then((res) => navigate("/medical-information-complete"))
      .finally(() => setIsLoading(false))
      .catch((err) => console.log("Error", err));
  };

  const validateAllFields = () =>
    emergencyContactName &&
    emergencyPhoneNumber &&
    relationship &&
    recentPcp &&
    specialistCare &&
    occupation &&
    disabilities &&
    livingOption &&
    medicalCondition &&
    surgery &&
    familyHistory &&
    drugOption &&
    medications &&
    exercise &&
    height &&
    systolicPressure &&
    dateOfReading &&
    smoking &&
    alcohol &&
    drugsList &&
    feeling &&
    worrying &&
    interes &&
    down;

  return (
    <div className="fullPage-blueBackground">
      <PageContainer>
        <FormContainer>
          <h2 className="FormComponent-title">Medical information</h2>
          <p className="FormComponent-description">
            Please provide details on your medical history
          </p>{" "}
          <FormCard
            title="Emergency contact"
            description="Should you be taken seriously ill, who should we contact on your behalf?"
          >
            <>
              <InputField
                value={emergencyContactName}
                onChange={handleChangeEmergencyContactName}
                error={genericErrorMessage}
                showError={showError && !emergencyContactName}
                label="Emergency contact name"
                id="emergencyContactName"
              />
              <InputField
                value={emergencyPhoneNumber}
                onChange={handleChangeEmergencyPhoneNumber}
                error={genericErrorMessage}
                showError={showError && !emergencyPhoneNumber}
                label="Emergency phone number"
                id="emergencyPhoneNumber"
              />{" "}
              <Dropdown
                initialValue={relationship}
                label="Relationship to you"
                classes={styles.insuranceProviderStyle}
                options={relationshipOptions}
                onChange={handleChangeRelationship}
                error={genericErrorMessage}
                showError={showError && !relationship}
              />
            </>
          </FormCard>
          <FormCard title="General wellness">
            <>
              <TextArea
                value={recentPcp}
                onChange={handleChangeRecentPcp}
                label="Please provide the name and address of your most recent Primary Care Provider"
                id="recentPcp"
                error={genericErrorMessage}
                showError={showError && !recentPcp}
              />
              <RadioGroup
                options={specialistCareOptions}
                description={
                  <p>
                    Are you currently under the care of any other Health Care
                    Providers at this time (such as specialists or therapists)?
                    If <b>Yes</b>, please provide their name and discipline
                  </p>
                }
                activeValue={specialistCare}
                groupLabel="Specialist care"
                onChange={handleChangeSpecialistCare}
              />
              <ValidationError
                show={showError && !specialistCare}
                error={genericErrorMessage}
              />
              {specialistCare === "yes" && (
                <InputField
                  label="Details"
                  value={scValue}
                  onChange={(e: any) => setScValue(e.target.value)}
                  id="scValue"
                  showError={showError && !scValue}
                  error={genericErrorMessage}
                />
              )}
              <InputField
                value={preferredPharmacy}
                onChange={handleChangePreferredPharmacy}
                error={genericErrorMessage}
                showError={showError && !preferredPharmacy}
                label="Preferred Pharmacy Name and address"
                id="preferredPharmacy"
              />
              <InputField
                value={occupation}
                onChange={handleChangeOccupation}
                label="What’s your current or most recent occupation?"
                id="occupation"
                showError={showError && !occupation}
                error={genericErrorMessage}
              />{" "}
              <RadioGroup
                options={disabilitiesOptions}
                description={
                  <p>
                    Do you have any disabilities? If <b>Yes</b>, please provide
                    details
                  </p>
                }
                activeValue={disabilities}
                groupLabel="Disabilities"
                onChange={handleChangeDisabilitiesOption}
              />
              <ValidationError
                show={showError && !disabilities}
                error={genericErrorMessage}
              />
              {disabilities === "yes" && (
                <InputField
                  label="Details"
                  value={disDetails}
                  onChange={(e: any) => setDisDetails(e.target.value)}
                  id="scValue"
                  showError={showError && !disDetails}
                  error={genericErrorMessage}
                />
              )}
              <RadioGroup
                options={livingOptions}
                description={
                  <p>
                    What are your living arrangements? If <b>Other</b>, please
                    provide details
                  </p>
                }
                activeValue={livingOption}
                groupLabel="Living arrangements"
                onChange={handleChangeLivingOptions}
              />
              <ValidationError
                show={showError && !livingOption}
                error={genericErrorMessage}
              />
              {livingOption === "other" && (
                <InputField
                  label="Details"
                  value={livDetails}
                  onChange={(e: any) => setLivDetails(e.target.value)}
                  id="setLivDetails"
                  showError={showError && !livDetails}
                  error={genericErrorMessage}
                />
              )}
            </>
          </FormCard>
          <FormCard title="Medical history">
            <>
              <RadioGroup
                options={medicalConditionOptions}
                description={
                  <p>
                    Do you have any medical conditions we should know about? If{" "}
                    <b>Yes</b>, please provide details
                  </p>
                }
                activeValue={medicalCondition}
                groupLabel="Medical conditions"
                onChange={handleChangeMedicalCondition}
              />
              <ValidationError
                show={showError && !medicalCondition}
                error={genericErrorMessage}
              />
              {medicalCondition === "yes" && (
                <InputField
                  label="Details"
                  value={mcDet}
                  onChange={(e: any) => setMcDet(e.target.value)}
                  id="setLivDetails"
                  showError={showError && !mcDet}
                  error={genericErrorMessage}
                />
              )}

              <RadioGroup
                options={surgeriesOptions}
                description={
                  <p>
                    Have you had any surgeries or procedures done in the past?
                    If <b>Yes</b>, please provide an approximate year the
                    procedure was done and the Doctor that performed the
                    surgery.
                  </p>
                }
                activeValue={surgery}
                groupLabel="Surgeries and procedures"
                onChange={handleChangeSurgery}
              />
              <ValidationError
                show={showError && !surgery}
                error={genericErrorMessage}
              />
              {surgery === "yes" && (
                <InputField
                  label="Details"
                  value={surgeryDesc}
                  onChange={(e: any) => setSurgeryDesc(e.target.value)}
                  id="setLivDetails"
                  showError={showError && !surgeryDesc}
                  error={genericErrorMessage}
                />
              )}
              <RadioGroup
                options={familyHistoryOptions}
                description={
                  <p>
                    Are there any medical conditions that run in your family? If{" "}
                    <b>Yes</b>, please provide details
                  </p>
                }
                activeValue={familyHistory}
                groupLabel="Family history"
                onChange={handleChangeFamilyHistory}
              />
              <ValidationError
                show={showError && !familyHistory}
                error={genericErrorMessage}
              />
              {familyHistory === "yes" && (
                <InputField
                  label="Details"
                  value={famDesc}
                  onChange={(e: any) => setFamDesc(e.target.value)}
                  id="famDesc"
                  showError={showError && !famDesc}
                  error={genericErrorMessage}
                />
              )}

              <RadioGroup
                options={drugOptions}
                description={
                  <p>
                    Do you have any drug allergies that you know of? If{" "}
                    <b>Yes</b>, please provide details
                  </p>
                }
                activeValue={drugOption}
                groupLabel="Drug allergies"
                onChange={handleChangeDrugOption}
              />
              <ValidationError
                show={showError && !drugOption}
                error={genericErrorMessage}
              />
              {drugOption === "yes" && (
                <InputField
                  label="Details"
                  value={drugAlDesc}
                  onChange={(e: any) => setDrugAlDesc(e.target.value)}
                  id="drugAlDesc"
                  showError={showError && !drugAlDesc}
                  error={genericErrorMessage}
                />
              )}

              <TextArea
                value={medications}
                onChange={handleChangeMedications}
                error={genericErrorMessage}
                showError={showError && !medications}
                label="Current medications"
                description="Please provide a list of medications/supplements that you are currently taking, including name of medication, dosage, and frequency"
                id="medications"
              />
            </>
          </FormCard>
          <FormCard title="Social health and lifestyle">
            <>
              <Dropdown
                initialValue={exercise}
                label="How often you you exercise per week?"
                // classes={styles.insuranceProviderStyle}
                options={exerciseOptions}
                onChange={handleChangeExercise}
                showError={showError && !exercise}
                error={genericErrorMessage}
              />
              <InputField
                value={height}
                onChange={handleChangeHeight}
                showError={showError && !height}
                error={genericErrorMessage}
                label="Height"
                id="height"
              />
              <p className={styles.bloodPressure}>Blood pressure*</p>
              <p className={styles.bloodPressureDesc}>
                What was your most recent blood pressure reading? (with
                approximate date recorded)
              </p>
              <InputField
                value={systolicPressure as string}
                onChange={handleChangeSystolicPressure}
                showError={showError && !systolicPressure}
                error={genericErrorMessage}
                label="Systolic pressure"
                id="systolicpressure"
                required
                classes={styles.systolicpressure}
              />{" "}
              <DobPicker
                initialValue={dateOfReading}
                onChange={handleChangeDateOfReading}
                label="Date of reading"
                showError={showError && dateOfReading.includes("undefined")}
                error={genericErrorMessage}
              />
              {/* <ValidationError
                show={showError && !dateOfReading}
                error={genericErrorMessage}
              /> */}
              <InputField
                value={smoking}
                onChange={handleChangeSmoking}
                description="Do you have a history of smoking? Please provide details as required"
                showError={showError && !smoking}
                error={genericErrorMessage}
                label="Smoking"
                id="smoking"
              />
              <InputField
                value={alcohol}
                onChange={handleChangeAlcohol}
                description="Do you drink alcohol? Please provide details as required"
                showError={showError && !alcohol}
                error={genericErrorMessage}
                label="Alcohol"
                id="smoking"
              />
              <p className={styles.bloodPressure}>Recreational drugs*</p>
              <p className={styles.bloodPressureDesc}>
                What was your most recent blood pressure reading? (with
                approximate date recorded)
              </p>
              <Checkbox
                checked={drugsMarijuana}
                onChange={setDrugsMarijuana}
                id="drugsMarijuana"
                errorMessage=""
                showError={false}
                description={<p>Marijuana</p>}
                classes={styles.checkboxFormFix}
              />
              <Checkbox
                checked={methamphetamine}
                onChange={setMethamphetamine}
                id="methamphetamine"
                errorMessage=""
                showError={false}
                description={<p>Methamphetamine</p>}
                classes={styles.checkboxFormFix}
              />
              <Checkbox
                checked={heroin}
                onChange={setHeroin}
                id="heroin"
                errorMessage=""
                showError={false}
                description={<p>Heroin</p>}
                classes={styles.checkboxFormFix}
              />
              <Checkbox
                checked={cocaine}
                onChange={setCocaine}
                id="cocaine"
                errorMessage=""
                showError={false}
                description={<p>Cocaine</p>}
                classes={styles.checkboxFormFix}
              />
              <Checkbox
                checked={opiates}
                onChange={setOpiates}
                id="cocaine"
                errorMessage=""
                showError={false}
                description={
                  <p>Opiates (oxycodone, morphine, hydrocodone, etc)</p>
                }
                classes={styles.checkboxFormFix}
              />
              <Checkbox
                checked={none}
                onChange={setNone}
                id="none"
                errorMessage=""
                showError={false}
                description={<p>None</p>}
                classes={styles.checkboxFormFix}
              />
              <ValidationError
                show={showError && drugsList.trim().length === 0}
                error={genericErrorMessage}
              />
            </>
          </FormCard>
          <FormCard
            title="Emotional wellness"
            description="Please choose the best answers to describe how you’ve felt over the last two weeks"
          >
            <>
              <RadioGroup
                options={feelingOptions}
                description={
                  <p>
                    Please choose the best answers to describe how you’ve felt
                    over the last two weeks
                  </p>
                }
                activeValue={feeling}
                groupLabel="Feeling nervous, anxious, or on edge?"
                onChange={handleChangeFeeling}
              />
              <ValidationError
                show={showError && !feeling}
                error={genericErrorMessage}
              />
              <RadioGroup
                options={worryingOptions}
                activeValue={worrying}
                groupLabel="Unable to stop or control worrying?"
                onChange={handleChangeWorrying}
              />
              <ValidationError
                show={showError && !worrying}
                error={genericErrorMessage}
              />
              <RadioGroup
                options={interesOptions}
                activeValue={interes}
                groupLabel="Feeling little interest or pleasure in doing things?"
                onChange={handleChangeInteres}
              />
              <ValidationError
                show={showError && !interes}
                error={genericErrorMessage}
              />
              <RadioGroup
                options={downOptions}
                activeValue={down}
                groupLabel="Feeling down, depressed, or hopeless?*"
                onChange={handleChangeDown}
              />
              <ValidationError
                show={showError && !down}
                error={genericErrorMessage}
              />
            </>
          </FormCard>
          <ValidationError
            show={showError}
            error="Please check that all fields have been completed."
          />
          <ActionsContainer>
            <Button
              text="Submit your answers"
              size="large"
              color="blue"
              handleClick={saveData}
              withArrow
              isLoading={isLoading}
            />
          </ActionsContainer>
        </FormContainer>
      </PageContainer>
    </div>
  );
}

export default MedicalInformation;
