import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import PageContainer from "components/layout/PageContainer";
import DashboardShell from "components/layout/DashboardShell";
import { useAppSelector, useAppDispatch } from "redux/store";
import {
  fetchUserData,
  selectIsUserLoading,
  selectUser,
} from "redux/user.slice";
import Loading, { BackgroundWrapper } from "pages/Loading";

function Dashboard() {
  const isUserLoading = useAppSelector(selectIsUserLoading);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(fetchUserData());
  }, [pathname]);

  if (isUserLoading) {
    return <Loading text="Loading" />;
  }

  if (user) {
    return (
      <BackgroundWrapper>
        <PageContainer>
          <DashboardShell form={<Outlet />} />
        </PageContainer>
      </BackgroundWrapper>
    );
  }

  return <Loading withoutLoader text="Something went wrong" />;
}

export default Dashboard;
