import { useState, useEffect } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { ReactComponent as SucessCheckmark } from "assets/images/successCheckmark.svg";
import classNames from "classnames";
import { IDocument } from "components/forms/Payment";
import styles from "./styles.module.scss";
import ValidationError from "../ValidationError";

interface IProps {
  id: number;
  fileUrl: string;
  setFileUrl: Function;
  title: string | JSX.Element;
  description?: string;
  icon: JSX.Element;
  uploadedText: string;
  classes?: any;
  showError: boolean;
  error?: string;
  fileName: string;
  fileData: IDocument | null | undefined;
  setFileData: React.Dispatch<React.SetStateAction<IDocument | null>>;
}

interface IFileErrors {
  fileRejections: FileRejection[];
  showError: boolean;
  error: string | undefined;
  isUploadError: boolean;
  fileUrl: string;
}

function FileUpload({
  id,
  fileUrl,
  setFileUrl,
  title,
  description = "",
  icon,
  uploadedText,
  classes,
  showError,
  error,
  fileName,
  setFileData,
  side,
  type,
  fileData,
}: IProps & Pick<IDocument, "type" | "side">) {
  const [isUploadError, setIsUploadError] = useState<boolean>(false);
  const [style, setStyle] = useState<{}>({});
  const [isPdf, setIsPdf] = useState<boolean>(false);

  useEffect(() => {
    if (fileData?.title)
      setIsPdf(fileData.title.toLowerCase().endsWith(".pdf"));
  }, [fileData?.title]);

  const onDrop = (acceptedFiles: File[]) => {
    const droppedFile = acceptedFiles[0];
    if (droppedFile) {
      setFileData({ title: droppedFile.name, file: droppedFile, side, type });
      setFileUrl(URL.createObjectURL(droppedFile));
    }
  };
  const { getRootProps, getInputProps, fileRejections, isFocused } =
    useDropzone({
      onDrop,
      maxSize: 4194305,
      multiple: false,
      accept: {
        "image/jpeg": [],
        "image/png": [],
        "image/*": [],
        "application/pdf": [],
      },
    });
  const uploadError = fileRejections[0]?.errors[0].message;

  useEffect(() => {
    if (uploadError) setIsUploadError(true);
  }, [uploadError]);

  const handleDeleteUpload = (e: any) => {
    e.stopPropagation();
    setFileUrl("");
    setFileData(null);
  };

  const handleDragOver = (e: any) => {
    if (e.type === "dragover" || isFocused) {
      setStyle({ border: "1px solid #0A395C", outline: "1px solid #0A395C" });
    } else {
      setStyle({});
    }
  };

  return (
    <div
      className={classNames([styles.fileUpload_wrapper], [classes], {
        [styles.fileUploaded]: fileUrl,
      })}
      onDragOver={handleDragOver}
      onDragLeave={handleDragOver}
    >
      <div className={styles.fileUpload_header}>
        <div>{id}</div>
        <p>{title}</p>
      </div>
      {description && (
        <p className={styles.fileUpload_example}>{description}</p>
      )}
      <div
        {...getRootProps({ style })}
        className={classNames([styles.fileUpload_dropSpace], {
          [styles.withError]: (showError || uploadError) && !fileUrl,
        })}
      >
        <input {...getInputProps()} />
        {!fileUrl ? icon : <SucessCheckmark />}
        <p className={styles.fileUpload_link}>
          {!fileUrl ? (
            <>
              Drag and drop file here to upload or
              <span>upload from your device</span>
            </>
          ) : (
            <>
              <div className={styles.fileUploadedWrapper}>
                <>{uploadedText}</>
                <div className={styles.previewDocument}>
                  {!isPdf && (
                    <div className={styles.thumbnail}>
                      <img src={fileUrl} alt={fileName} />
                    </div>
                  )}
                  <div
                    onClick={handleDeleteUpload}
                    role="button"
                    tabIndex={0}
                    aria-hidden="true"
                    className={styles.deleteUploadButton}
                  >
                    Delete upload
                  </div>
                </div>
              </div>
            </>
          )}
        </p>
      </div>
      <FileUploadErrors
        fileRejections={fileRejections}
        fileUrl={fileUrl}
        isUploadError={isUploadError}
        showError={showError}
        error={error}
      />
    </div>
  );
}

const FileUploadErrors: Function = ({
  fileRejections,
  showError,
  error,
  fileUrl,
  isUploadError,
}: IFileErrors) => {
  const uploadErrors = fileRejections[0]?.errors;

  if (uploadErrors?.length > 0)
    return uploadErrors.map(({ message }) => {
      const formatedMessage = fileErrorMessageMapper(message);
      return (
        <ValidationError
          show={(showError || isUploadError) && !fileUrl}
          error={
            showError ? error : isUploadError ? formatedMessage : undefined
          }
        />
      );
    });
  return null;
};

const fileErrorMessageMapper = (message: string): string => {
  if (message === "File type must be image/jpeg,image/png")
    return "File type must be jpeg or png";
  // if (message === "File is larger than 4194305 bytes")
  return "File is larger than 4MB";
};

export default FileUpload;
