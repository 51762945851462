import { useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import userService from "services/userService";
import { AxiosResponse } from "axios";
import LoadingSpinner from "components/shared/LoadingSpinner";
import { AppointmentTypeNameEnum } from "components/forms/ChooseYourService";
import { addMinutes } from "date-fns";
import styles from "./styles.module.scss";
import AppointmentListItem from "./AppointmentListItem";

export interface IAppointmentDTO {
  appointmentType: {
    name: AppointmentTypeNameEnum;
    duration: number;
  };
  id: string;
  elationAppointmentId: string;
  elationPhysicianId: string;
  appointmentTypeFullName: string;
  scheduledDate: string;
  scheduledDateByTimezone: string;
  timezone: string;
  healthConcern: string;
  status: string;
  selfPay: false;
  zoomLink: string | null;
  createdAt: string;
}

interface IAppointmentsFormatted {
  upcoming: IAppointmentDTO[] | null;
  previous: IAppointmentDTO[] | null;
}

const AppointmentsList = () => {
  const [appointmentsTabActive, setAppointmentsTabActive] = useState<
    "upcoming" | "previous"
  >("upcoming");

  const [appointmentData, setAppointmentData] = useState<
    IAppointmentDTO[] | null
  >(null);
  const [appointmentsDataError, setAppointmentsDataError] =
    useState<boolean>(false);

  useEffect(() => {
    userService
      .getAppointments()
      .then(({ data }: AxiosResponse<IAppointmentDTO[]>) => {
        const sortedData = data.sort(sortAppointments);
        setAppointmentData(sortedData);
      })
      .catch(() => setAppointmentsDataError(true));
  }, []);

  const toggleTab = (e: any) => {
    setAppointmentsTabActive(e.target.id);
  };

  const upcomingAppointments: IAppointmentsFormatted | null = useMemo(
    () => formatAppointments(appointmentData),
    [appointmentData]
  );

  return (
    <div>
      <div className={styles.tabs_container}>
        <div
          className={classNames([styles.tab_upcomingTab], {
            [styles.activeTab]: appointmentsTabActive === "upcoming",
          })}
          role="button"
          tabIndex={0}
          id="upcoming"
          onClick={toggleTab}
        >
          Upcoming appointments
        </div>
        <div
          className={classNames([styles.tab_previousTab], {
            [styles.activeTab]: appointmentsTabActive === "previous",
          })}
          role="button"
          tabIndex={0}
          id="previous"
          onClick={toggleTab}
        >
          Previous appointments
        </div>
      </div>
      <div className={styles.tabs_contentWrapper}>
        {appointmentsDataError ? (
          <AppointmentDataError /> // data error handling here
        ) : appointmentsTabActive === "upcoming" ? (
          <UpcomingAppointments upcoming={upcomingAppointments.upcoming} />
        ) : (
          <PreviousAppointments previous={upcomingAppointments.previous} />
        )}
      </div>
    </div>
  );
};

const AppointmentDataError = () => (
  <AppointmentLayout>
    <div className={styles.errorWrapper}>
      <span className={styles.errorText}>Something went wrong</span>
    </div>
  </AppointmentLayout>
);

const AppointmentLayout = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.ListItemWrapper}>
    <div className={styles.ListItem}>
      <div className={styles.ContentWrapper}>{children}</div>
    </div>
  </div>
);

const UpcomingAppointments = ({
  upcoming,
}: Pick<IAppointmentsFormatted, "upcoming">) => {
  return upcoming ? (
    upcoming.length > 0 ? (
      <>
        {upcoming.map((upcomingItem) => (
          <AppointmentLayout key={upcomingItem.id}>
            <AppointmentListItem
              type="upcoming"
              item={upcomingItem}
              key={upcomingItem.id}
            />
          </AppointmentLayout>
        ))}
      </>
    ) : (
      <NoAppointments type="upcoming" />
    )
  ) : (
    <AppointmentsLoadingSpinner />
  );
};

const PreviousAppointments = ({
  previous,
}: Pick<IAppointmentsFormatted, "previous">) =>
  previous ? (
    previous.length > 0 ? (
      <>
        {previous.map((previousItem) => (
          <AppointmentLayout key={previousItem.id}>
            <AppointmentListItem
              type="previous"
              item={previousItem}
              key={previousItem.id}
            />
          </AppointmentLayout>
        ))}
      </>
    ) : (
      <NoAppointments type="previous" />
    )
  ) : (
    <AppointmentsLoadingSpinner />
  );

const AppointmentsLoadingSpinner = () => (
  <div className={styles.ListItemWrapper}>
    <div className={styles.ListItem}>
      <LoadingSpinner size={30} classes={[styles.loadingSpinner]} />
    </div>
  </div>
);

const NoAppointments = ({ type }: { type: "previous" | "upcoming" }) => (
  <div className={styles.tab_contentUpcoming}>
    <div className={styles.appointments_emptyList}>
      You have no {type} appointments.
    </div>
  </div>
);

// utils
const sortAppointments = (a: IAppointmentDTO, b: IAppointmentDTO) => {
  if (new Date(a.scheduledDate) < new Date(b.scheduledDate)) {
    return -1;
  }
  return 1;
};

const findUpcomingAppointments = (appointmentsData: IAppointmentDTO[]) =>
  appointmentsData.findIndex((appointment) => {
    const meetingEnd = addMinutes(
      new Date(appointment.scheduledDate),
      appointment.appointmentType.duration
    );
    return meetingEnd > new Date();
  });

const formatAppointments = (appointmentsData: IAppointmentDTO[] | null) => {
  if (!appointmentsData) return { upcoming: null, previous: null };

  const index = findUpcomingAppointments(appointmentsData);
  if (index === -1)
    return {
      upcoming: [],
      previous: appointmentsData,
    };

  let previous = appointmentsData.slice(0, index);
  const upcoming = appointmentsData.slice(index).filter((appointment) => {
    if (appointment.status === "Cancelled") {
      previous.push(appointment);
      return false;
    }
    return true;
  });

  previous = previous.sort(sortAppointments);

  return {
    upcoming,
    previous,
  };
};

export default AppointmentsList;
