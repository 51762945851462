import { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import InputBase from "@mui/material/InputBase";
import ValidationError from "components/shared/ValidationError";
import classNames from "classnames";
import { validateMonthDayYear } from "utils/validation/helpers";
import styles from "./styles.module.scss";

interface Props {
  initialValue: string;
  onChange: (dateOfBirth: string) => void;
  label: string;
  required?: boolean;
  error: string | undefined;
  showError: boolean;
  disabled?: boolean;
}

function DobPicker({
  initialValue,
  onChange,
  label,
  required = true,
  error,
  showError,
  disabled = false,
}: Props) {
  const initialDate: string[] = initialValue.split("/");

  const [month, setMonth] = useState<string>(initialDate[0]);
  const [day, setDay] = useState<string>(initialDate[1]);
  const [year, setYear] = useState<string>(initialDate[2]);

  const changeDateOfBirth = () => {
    onChange(`${month}/${day}/${year}`);
  };

  useEffect(() => {
    changeDateOfBirth();
  }, [month, day, year]);

  const handleChangeMonth = (e: React.ChangeEvent<HTMLInputElement>) =>
    setMonth(e.target.value);

  const handleChangeDay = (e: React.ChangeEvent<HTMLInputElement>) =>
    setDay(e.target.value);

  const handleChangeYear = (e: React.ChangeEvent<HTMLInputElement>) =>
    setYear(e.target.value);

  const displayLabel = `${label}${required ? "*" : " (optional)"}`;

  const [isMonthValid, isDayValid, isYearValid] = validateMonthDayYear(
    month,
    day,
    year
  );

  return (
    <div className={styles.inputFieldWrapper}>
      <InputLabel className={styles.label}>{displayLabel}</InputLabel>
      <div className={styles.dob_inputsWrapper}>
        <div>
          <InputLabel htmlFor="mm" className={styles.singleField_label}>
            Month (MM)
          </InputLabel>
          <InputBase
            value={month}
            onChange={handleChangeMonth}
            className={classNames(styles.inputField, {
              [styles.inputFieldError]: showError && error && !isMonthValid,
              disabledField: disabled,
            })}
            classes={{ disabled: "disabledInput" }}
            disabled={disabled}
            id="mm"
            inputProps={{ maxLength: 2, inputMode: "numeric" }}
          />
        </div>
        <div>
          <InputLabel htmlFor="dd" className={styles.singleField_label}>
            Day (DD)
          </InputLabel>
          <InputBase
            value={day}
            onChange={handleChangeDay}
            className={classNames(styles.inputField, {
              [styles.inputFieldError]: showError && error && !isDayValid,
              disabledField: disabled,
            })}
            classes={{ disabled: "disabledInput" }}
            disabled={disabled}
            id="dd"
            inputProps={{ maxLength: 2, inputMode: "numeric" }}
          />
        </div>
        <div>
          <InputLabel htmlFor="yyyy" className={styles.singleField_label}>
            Year (YYYY)
          </InputLabel>
          <InputBase
            value={year}
            onChange={handleChangeYear}
            className={classNames(styles.inputField, {
              [styles.inputFieldError]: showError && error && !isYearValid,
              disabledField: disabled,
            })}
            classes={{ disabled: "disabledInput" }}
            disabled={disabled}
            id="yyyy"
            inputProps={{ maxLength: 4, inputMode: "numeric" }}
          />
        </div>
      </div>
      <ValidationError show={showError} error={error} />
    </div>
  );
}

export default DobPicker;
