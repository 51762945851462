import { useNavigate } from "react-router-dom";
import FormContainer from "components/forms/FormContainer";
import PageContainer from "components/layout/PageContainer";
import { ReactComponent as GreenCheckmark } from "assets/images/greenCheckmark.svg";
import classNames from "classnames";
import ActionsContainer from "components/forms/FormContainer/ActionsContainer";
import Button from "components/ui/Button";

import styles from "./styles.module.scss";

function MedicalInformationComplete() {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate("/dashboard/appointments");
  };

  return (
    <div className="fullPage-blueBackground">
      <PageContainer>
        <FormContainer>
          {" "}
          <div className={styles.completeStepper}>
            <GreenCheckmark />
          </div>
          <h2
            className={classNames("FormComponent-title", [styles.titleGreen])}
          >
            Medical information complete
          </h2>
          <ActionsContainer stackButtons={true}>
            <Button
              text="Back to my dashboard"
              size="large"
              color="blue"
              handleClick={handleContinue}
              withArrow
            />
          </ActionsContainer>
        </FormContainer>
      </PageContainer>
    </div>
  );
}

export default MedicalInformationComplete;
