import EE from "eventemitter3";

type Listener = (...args: any[]) => void;

class EventEmitter {
  eventEmitter: EE<string | symbol, any>;

  private static instance: EventEmitter;

  private constructor() {
    this.eventEmitter = new EE();
  }

  public static getInstance(): EventEmitter {
    if (!EventEmitter.instance) {
      EventEmitter.instance = new EventEmitter();
    }
    return EventEmitter.instance;
  }

  public on(eventName: Events, listener: Listener) {
    this.eventEmitter.on(eventName, listener);
  }

  public remove(eventName: Events, listener: Listener) {
    this.eventEmitter.removeListener(eventName, listener);
  }

  public emit(event: Events, payload?: Object, error = false) {
    this.eventEmitter.emit(event, payload, error);
  }
}

const client = EventEmitter.getInstance();
export default client;

export enum Events {
  REGISTER_OPEN_EXIT_MODAL = "REGISTER_OPEN_EXIT_MODAL",
  PCP_OPEN_EXIT_MODAL = "PCP_OPEN_EXIT_MODAL",
  BOOKING_OPEN_EXIT_MODAL = "BOOKING_OPEN_EXIT_MODAL",
  SCROLL_TO_TOP = "SCROLL_TO_TOP",
  ENABLE_SCROLL = "ENABLE_SCROLL",
  DISABLE_SCROLL = "DISABLE_SCROLL",
}
