import React from "react";
import styles from "./styles.module.scss";

interface Props {
  children: JSX.Element | JSX.Element[] | React.ReactNode;
}

export default function FormContainer({ children }: Props) {
  return <div className={styles.formContainer_wrapper}>{children}</div>;
}
