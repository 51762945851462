import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import userService from "services/userService";
import { RootState } from "./store";

interface IAuthState {
  isLoading: boolean;
  jwt: string;
  refreshToken: string;
}

const initialState: IAuthState = {
  isLoading: false,
  jwt: "",
  refreshToken: "",
};

export const userLogin = createAsyncThunk(
  "auth/userLogin",
  async (
    credentials: { email: string; password: string },
    { rejectWithValue }
  ) => {
    const { email, password } = credentials;
    try {
      const response = await userService.login({
        email,
        password,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        "The email address and password you entered did not match our records. Please double-check and try again."
      );
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (
      state,
      action: PayloadAction<{ jwtToken: string; refreshToken: string }>
    ) => {
      const { jwtToken, refreshToken } = action.payload;
      state.jwt = jwtToken;
      state.refreshToken = refreshToken;
    },
    setNewJwt: (state, action: PayloadAction<{ jwt: string }>) => {
      if (action.payload.jwt) {
        state.jwt = action.payload.jwt;
      }
    },
    logout: (state) => {
      state.jwt = "";
      state.refreshToken = "";
    },
  },
  extraReducers(builder) {
    builder.addCase(userLogin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      const { jwtToken, refreshToken } = payload;
      state.jwt = jwtToken;
      state.refreshToken = refreshToken;
    });
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userLogin.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setCredentials, setNewJwt, logout } = authSlice.actions;

// Selectors
export const selectAuth = (state: RootState) => state.auth;
export const selectAuthLoading = (state: RootState) => state.auth.isLoading;

export default authSlice.reducer;
