import { useState } from "react";
import { ReactComponent as StripeLogo } from "assets/images/stripe.svg";
import LoadingSpinner from "components/shared/LoadingSpinner";
import classNames from "classnames";
import type { ServicePrice } from ".";

import styles from "./styles.module.scss";

const SelfPayTab = ({
  openConfirmBookingModal,
  price,
  isLoading,
}: {
  openConfirmBookingModal: () => void;
  price: ServicePrice | null;
  isLoading: boolean;
}) => {
  return (
    <>
      <div className={styles.singleTabContent}>
        <p className={styles.subTitle}>Payment options</p>
        <div className={styles.selfPayContentWrapper}>
          {price && !isLoading ? (
            <div className={styles.paymentInfo}>
              <div className={styles.textWrapper}>
                <span className={styles.paymentText}>
                  Payment amount: USD ${price.price}
                </span>
              </div>
            </div>
          ) : (
            <div className={styles.loadingSpinnerWrapper}>
              <LoadingSpinner size={30} classes={[styles.loadingSpinner]} />
            </div>
          )}
          <div className={styles.checkoutContentWrapper}>
            <div className={styles.checkoutContent}>
              <button
                className={classNames(styles.checkoutButton, {
                  [styles.disabled]: !price,
                })}
                onClick={openConfirmBookingModal}
                disabled={!price}
              >
                Confirm and Pay
              </button>
              <div className={styles.checkoutLogoStripe}>
                <span className={styles.checkoutLogoStripeText}>
                  Powered by
                </span>
                <StripeLogo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelfPayTab;
