import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppointmentTypeNameEnum } from "components/forms/ChooseYourService";
import { getCurrentHawaiiDate } from "utils/transform/formatDate";
import type { RootState } from "./store";

export interface IFirstBookingAppointmentStep {
  id: number | null;
  name: AppointmentTypeNameEnum | null;
  forAdults: boolean | null;
}

export interface ISecondBookingAppointmentStep {
  appointmentType: "video" | "phone" | null;
  appointmentPhone: string;
}

export interface IThirdBookingAppointmentStep {
  description: string;
}

export interface IFourthBookingAppointmentStep {
  appointmentDate: Date;
  timeSlot: string;
}

interface BookingAppointmentInitial {
  bookingActiveStep: number;
  bookingAppointmentGoBack: number;
  firstBookingAppointmentStep: IFirstBookingAppointmentStep;
  secondBookingAppointmentStep: ISecondBookingAppointmentStep;
  thirdBookingAppointmentStep: IThirdBookingAppointmentStep;
  fourthBookingAppointmentStep: IFourthBookingAppointmentStep;
}

const initialState: BookingAppointmentInitial = {
  bookingActiveStep: 1,
  bookingAppointmentGoBack: 0,
  firstBookingAppointmentStep: {
    id: null,
    forAdults: null,
    name: null,
  },
  secondBookingAppointmentStep: {
    appointmentType: null,
    appointmentPhone: "",
  },
  thirdBookingAppointmentStep: {
    description: "",
  },
  fourthBookingAppointmentStep: {
    appointmentDate: getCurrentHawaiiDate(),
    timeSlot: "",
  },
};

export const bookingAppointmentFlowSlice = createSlice({
  name: "bookingAppointmentFlow",
  initialState,
  reducers: {
    saveFirstBookingAppointmentStep: (
      state,
      action: PayloadAction<IFirstBookingAppointmentStep>
    ) => {
      state.firstBookingAppointmentStep = action.payload;
    },
    saveSecondBookingAppointmentStep: (
      state,
      action: PayloadAction<ISecondBookingAppointmentStep>
    ) => {
      state.secondBookingAppointmentStep = action.payload;
    },
    saveThirdBookingAppointmentStep: (
      state,
      action: PayloadAction<IThirdBookingAppointmentStep>
    ) => {
      state.thirdBookingAppointmentStep = action.payload;
    },
    saveFourthBookingAppointmentStep: (
      state,
      action: PayloadAction<IFourthBookingAppointmentStep>
    ) => {
      state.fourthBookingAppointmentStep = action.payload;
    },
    setBookingActiveStep: (state, action: PayloadAction<number>) => {
      state.bookingActiveStep = action.payload;
    },
    setBookingAppointmentGoBack: (state) => {
      state.bookingAppointmentGoBack += 1;
    },
    clearBookingAppointmentState: () => initialState,
  },
});

export const selectFirstBookingAppointmentStep = (state: RootState) =>
  state.bookingAppointment.firstBookingAppointmentStep;

export const selectSecondBookingAppointmentStep = (state: RootState) =>
  state.bookingAppointment.secondBookingAppointmentStep;

export const selectThirdBookingAppointmentStep = (state: RootState) =>
  state.bookingAppointment.thirdBookingAppointmentStep;

export const selectFourthBookingAppointmentStep = (state: RootState) =>
  state.bookingAppointment.fourthBookingAppointmentStep;

export const selectBookingAppointmentGoBack = (state: RootState) =>
  state.bookingAppointment.bookingAppointmentGoBack;

export const selectBookingActiveStep = (state: RootState) =>
  state.bookingAppointment.bookingActiveStep;

export const {
  saveFirstBookingAppointmentStep,
  setBookingActiveStep,
  setBookingAppointmentGoBack,
  clearBookingAppointmentState,
  saveSecondBookingAppointmentStep,
  saveThirdBookingAppointmentStep,
  saveFourthBookingAppointmentStep,
} = bookingAppointmentFlowSlice.actions;

export default bookingAppointmentFlowSlice.reducer;
