import { useState, ChangeEvent, useEffect } from "react";
import Button from "components/ui/Button";
import InputField from "components/ui/InputField";
import RadioGroup from "components/ui/RadioGroup";
import { useAppDispatch, useAppSelector } from "redux/store";
import { saveFirstPcpStep, selectFirstPcpStep } from "redux/pcpFlow.slice";
import useValidate from "utils/validation";
import {
  VALIDATE_PCP_ADDRESS,
  VALIDATE_PCP_NAME,
} from "utils/validation/constants";
import client, { Events } from "services/EventEmitter";
import ActionsContainer from "../FormContainer/ActionsContainer";

interface IProps {
  nextStep: (stepId: number) => void;
}

type RegisteredStatus = "yes" | "no" | null;

function PCPInitial({ nextStep }: IProps) {
  const dispatch = useAppDispatch();
  const firstPcpStep = useAppSelector(selectFirstPcpStep);

  const [alreadyHavePcp, setAlreadyHavePcp] = useState<RegisteredStatus>(
    firstPcpStep.alreadyHavePcp ? "yes" : "no"
  );
  const [pcpName, setPcpName] = useState<string>(firstPcpStep.currentPcpName);
  const [pcpAddress, setPcpAddress] = useState<string>(
    firstPcpStep.currentPcpAddress
  );

  const [isPcpNameValid, pcpNameErrors] = useValidate(
    pcpName,
    VALIDATE_PCP_NAME
  );
  const [isPcpAddressValid, pcpAddressErrors] = useValidate(
    pcpAddress,
    VALIDATE_PCP_ADDRESS
  );

  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    if (alreadyHavePcp === "yes") {
      setHaveErrors(!isPcpNameValid || !isPcpAddressValid);
    } else {
      setPcpName("");
      setPcpAddress("");
      setHaveErrors(false);
    }
  }, [alreadyHavePcp, isPcpNameValid, isPcpAddressValid]);

  const handleChangeAlreadyHavePcp = (value: RegisteredStatus) => {
    setAlreadyHavePcp(value);
  };

  const handleChangePcpName = (e: ChangeEvent<HTMLInputElement>) => {
    setPcpName(e.target.value);
  };

  const handleChangePcpAddress = (e: ChangeEvent<HTMLInputElement>) => {
    setPcpAddress(e.target.value);
  };

  const options = [
    { value: "yes", label: "Yes - I am registered" },
    { value: "no", label: "No - I am not registered" },
  ];

  const handleContinue = () => {
    if (haveErrors) {
      client.emit(Events.SCROLL_TO_TOP);
      setShowErrors(true);
      return;
    }

    dispatch(
      saveFirstPcpStep({
        alreadyHavePcp: alreadyHavePcp === "yes" ? true : false,
        currentPcpName: pcpName,
        currentPcpAddress: pcpAddress,
      })
    );

    nextStep(2);
  };

  return (
    <>
      <h2 className="FormComponent-title">
        Thanks for considering us as your PCP!
      </h2>
      <p className="FormComponent-description">
        If you have a current PCP, please provide details here so we can contact
        them to switch your registration over.
      </p>

      <RadioGroup
        options={options}
        activeValue={alreadyHavePcp}
        groupLabel="Are you currently registered with a Primary Care Physician?"
        onChange={handleChangeAlreadyHavePcp}
      />

      {alreadyHavePcp === "yes" && (
        <>
          <InputField
            value={pcpName}
            onChange={handleChangePcpName}
            error={pcpNameErrors[0]}
            showError={showErrors}
            label="Primary Care Physician name"
            id="pcpName"
          />
          <InputField
            value={pcpAddress}
            onChange={handleChangePcpAddress}
            error={pcpAddressErrors[0]}
            showError={showErrors}
            label="Primary Care Physician address"
            id="pcpAddress"
          />
        </>
      )}

      <ActionsContainer>
        <Button
          text="Continue"
          descriptionText="Choose appointment type"
          size="large"
          color="blue"
          handleClick={handleContinue}
          withArrow
        />
      </ActionsContainer>
    </>
  );
}

export default PCPInitial;
