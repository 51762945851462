import PageContainer from "components/layout/PageContainer";
import SignUpCompleteForm from "components/forms/SignUpComplete";

function SignUpComplete() {
  return (
    <div className="fullPage-blueBackground">
      <PageContainer>
        <SignUpCompleteForm />
      </PageContainer>
    </div>
  );
}

export default SignUpComplete;
