import { useState, useEffect } from "react";
import InputField from "components/ui/InputField";
import {
  VALIDATE_PHONE,
  VALIDATE_SECONDARY_PHONE,
  VALIDATE_ADDRESS,
  VALIDATE_CITY,
  VALIDATE_STATE,
  VALIDATE_ZIP,
} from "utils/validation/constants";
import {
  formatToPhone,
  unwrapPhoneNumber,
} from "utils/transform/formatToPhone";
import useValidate from "utils/validation";
import { useAppDispatch, useAppSelector } from "redux/store";
import { setUser, selectUser } from "redux/user.slice";
import Dropdown from "components/ui/Dropdown";
import statesOptions from "utils/statesOptions";
import Button from "components/ui/Button";
import userService from "services/userService";
import classNames from "classnames";
import styles from "./styles.module.scss";

function ContactDetails({ handleClose }: { handleClose: Function }) {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [phoneNumber, setPhoneNumber] = useState<string>(
    user?.phoneNo[user?.phoneNo.length - 1] as string
  );
  const [address, setAddress] = useState<string>(
    user?.addressLine[0] as string
  );
  const [city, setCity] = useState<string>(user?.city as string);
  const [state, setState] = useState<string>(user?.state as string);
  const [zip, setZip] = useState<string>(user?.zipCode as string);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const [isPhoneValid, phoneErrors] = useValidate(phoneNumber, VALIDATE_PHONE);
  const [isAddressValid, addressErrors] = useValidate(
    address,
    VALIDATE_ADDRESS
  );
  const [isCityValid, cityErrors] = useValidate(city, VALIDATE_CITY);
  const [isStateValid, stateErrors] = useValidate(state, VALIDATE_STATE);
  const [isZipValid, zipErrors] = useValidate(zip, VALIDATE_ZIP);

  const handleChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unformatedPhoneNumber = unwrapPhoneNumber(e.target.value);
    setPhoneNumber(unformatedPhoneNumber);
  };

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };

  const handleChangeCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCity(e.target.value);
  };

  const handleChangeState = (selectedState: string) => {
    setState(selectedState);
  };

  const handleChangeZip = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length > 5) return;
    setZip(value);
  };

  useEffect(() => {
    setHaveErrors(
      !isPhoneValid ||
        !isAddressValid ||
        !isCityValid ||
        !isStateValid ||
        !isZipValid
    );
  }, [isPhoneValid, isAddressValid, isCityValid, isStateValid, isZipValid]);

  const handleEditContactDetails = () => {
    if (haveErrors) {
      // client.emit(Events.SCROLL_TO_TOP);
      setShowErrors(true);
      return;
    }

    setIsLoading(true);
    userService
      .editContactDetails({
        userId: user?.userId,
        ehrId: user?.ehrId,
        phoneNumber,
        addressLine1: address,
        city,
        state,
        zipCode: zip,
      })
      .then((res) => {
        dispatch(setUser(res.data));
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className={classNames({ [styles.loadingCover]: isLoading })}>
        <InputField
          value={formatToPhone(phoneNumber)}
          onChange={handleChangePhoneNumber}
          error={phoneErrors[0]}
          showError={showErrors}
          label="Phone number"
          id="phoneNumber"
        />
        <InputField
          value={address}
          onChange={handleChangeAddress}
          error={addressErrors[0]}
          showError={showErrors}
          label="Address line 1"
          id="address"
        />
        <InputField
          value={city}
          onChange={handleChangeCity}
          error={cityErrors[0]}
          showError={showErrors}
          label="Town/city"
          id="city"
        />
        <Dropdown
          initialValue={state}
          options={statesOptions}
          onChange={handleChangeState}
          label="State"
          error={stateErrors[0]}
          showError={showErrors}
        />
        <InputField
          value={zip}
          onChange={handleChangeZip}
          error={zipErrors[0]}
          showError={showErrors}
          label="Zip code"
          id="zip"
        />
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          text="Cancel"
          size="small"
          color="outlined_blue"
          handleClick={handleClose}
        />
        <Button
          text="Save changes"
          size="small"
          color="blue"
          handleClick={handleEditContactDetails}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}

export default ContactDetails;
