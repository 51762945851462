import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import userService from "services/userService";
import { Gender } from "./registration.slice";
import { RootState } from "./store";

export interface IUserObject {
  userId: string;
  email: string;
  ehrId: string;
  practiceId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  sexAtBirth: Gender;
  gender: Gender;
  phoneNo: string[];
  zipCode: string;
  city: string;
  state: string;
  addressLine: string[];
  tags: PatientTagsEnum[];
  pcpName: string | null;
  pcpAddress: string | null;
  telehealthConsent: string | null;
}

interface IUserState {
  isLoading: boolean;
  isLoadingFetchUserTags: boolean;
  isLoadingGetUserTags: boolean;
  user: IUserObject | null;
}

type ItagsResponse = Pick<IUserObject, "tags">;

export enum PatientTagsEnum {
  PCP_PENDING = "PCP-Pending",
  PCP_CONFIRMED = "PCP-Confirmed",
  PCP_NOT_APPROPRIATE = "PCP-Not Appropriate",
  UC_ACTIVE = "UC-Active",
  UNDER_18 = "Under-18",
  HI_DOC = "Hi-Doc",
  MEDICAL_INFO_COMPLETED = "Medical-Info-Completed",
}

const initialState: IUserState = {
  isLoading: false,
  isLoadingFetchUserTags: false,
  isLoadingGetUserTags: false,
  user: null,
};

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (_, { rejectWithValue }) =>
    userService.getUserDetails().catch((err) => {
      console.log("fetch data error error: ", err);
      return rejectWithValue("User data could not be retrieved");
    })
);

export const fetchUserTags = createAsyncThunk<
  AxiosResponse<ItagsResponse, any>
>("user/fetchUserTags", async (_, { rejectWithValue }) =>
  userService.checkAndUpdateUserTags().catch((err) => {
    console.log("fetch data error error: ", err);
    return rejectWithValue("User data could not be retrieved");
  })
);

export const getUserTags = createAsyncThunk<AxiosResponse<ItagsResponse, any>>(
  "user/getUserTags",
  async (_, { rejectWithValue }) =>
    userService.getTags().catch((err) => {
      console.log("fetch data error error: ", err);
      return rejectWithValue("User data could not be retrieved");
    })
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserObject>) => {
      state.user = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUserData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.user = payload.data;
    });
    builder.addCase(fetchUserData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserData.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchUserTags.fulfilled, (state, { payload }) => {
      if (state.user) {
        state.user.tags = payload.data.tags;
      }
      state.isLoadingFetchUserTags = false;
    });
    builder.addCase(fetchUserTags.pending, (state) => {
      state.isLoadingFetchUserTags = true;
    });
    builder.addCase(fetchUserTags.rejected, (state) => {
      state.isLoadingFetchUserTags = false;
    });
    builder.addCase(getUserTags.fulfilled, (state, { payload }) => {
      if (state.user) state.user.tags = payload.data.tags;
      state.isLoadingGetUserTags = false;
    });
    builder.addCase(getUserTags.pending, (state) => {
      state.isLoadingGetUserTags = true;
    });
    builder.addCase(getUserTags.rejected, (state) => {
      state.isLoadingGetUserTags = false;
    });
  },
});

export const { setUser } = userSlice.actions;

// Selectors
export const selectUser = (state: RootState) => state.user.user;
export const selectIsUserLoading = (state: RootState) => state.user.isLoading;
export const selectUserElationId = createSelector(
  selectUser,
  (user) => user?.ehrId
);
export const selectTags = createSelector(selectUser, (user) => user?.tags);
export const selectTag = createSelector(selectUser, (user) =>
  user?.tags.find(
    (tag: PatientTagsEnum) =>
      tag === PatientTagsEnum.PCP_CONFIRMED ||
      tag === PatientTagsEnum.PCP_NOT_APPROPRIATE ||
      tag === PatientTagsEnum.PCP_PENDING ||
      tag === PatientTagsEnum.UNDER_18
  )
);

export const selectChildTag = createSelector(
  selectTag,
  (tag) => tag === PatientTagsEnum.UNDER_18
);

export const selectEmail = createSelector(selectUser, (user) => user?.email);

export const selectLoadingUserTags = (state: RootState) =>
  state.user.isLoadingGetUserTags || state.user.isLoadingFetchUserTags;

export const selectIsPcpConfirmed = createSelector(
  selectTag,
  (tag) => tag === PatientTagsEnum.PCP_CONFIRMED
);

export const selectPhoneNumber = createSelector(
  selectUser,
  (user) => user?.phoneNo[0] ?? ""
);

export const selectHideMedicalInformation = (state: RootState) => {
  const tags = state.user.user?.tags;
  const isChild = tags?.find((tag) => tag === PatientTagsEnum.UNDER_18);
  const formFilled = tags?.find(
    (tag) => tag === PatientTagsEnum.MEDICAL_INFO_COMPLETED
  );

  return Boolean(formFilled || isChild);
};

export const selectUsername = createSelector(selectUser, (user) =>
  user ? `${user.firstName} ${user.lastName}` : null
);

export default userSlice.reducer;
