import { useState, useEffect, ChangeEvent } from "react";
import {
  Link,
  useSearchParams,
  useParams,
  useNavigate,
} from "react-router-dom";
import InputField from "components/ui/InputField";
import Button from "components/ui/Button";
import useValidate from "utils/validation";
import { VALIDATE_EMAIL, VALIDATE_PASSWORD } from "utils/validation/constants";
import ValidationError from "components/shared/ValidationError";
import userService from "services/userService";
import jwt_decode, { JwtPayload } from "jwt-decode";
import Modal from "components/shared/Modal";
import ActionsContainer from "../FormContainer/ActionsContainer";
import PasswordErrors from "../RegisterForm/PasswordErrors";
import FormContainer from "../FormContainer";

import styles from "./styles.module.scss";

type ResetPasswordStep = "enter-email" | "check-email" | "new-password";

export default function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [activeForm, setActiveForm] =
    useState<ResetPasswordStep>("enter-email");

  useEffect(() => {
    if (token) setActiveForm("new-password");
  }, []);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showDontMatchError, setShowDontMatchError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [expirationModalOpened, setExpirationModalOpened] =
    useState<boolean>(false);
  const isEnterEmail: boolean = activeForm === "enter-email";
  const isCheckEmail: boolean = activeForm === "check-email";
  const isNewPassword: boolean = activeForm === "new-password";

  const [isEmailValid, emailErrors] = useValidate(email, VALIDATE_EMAIL);

  const [isPasswordValid, passwordErrors] = useValidate(
    password,
    VALIDATE_PASSWORD
  );

  useEffect(() => {
    setHaveErrors(!isPasswordValid);
  }, [isPasswordValid]);

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handleChangeConfirmPassword = (e: ChangeEvent<HTMLInputElement>) =>
    setConfirmPassword(e.target.value);

  const handleResetPassword = () => {};

  useEffect(() => {
    const resetToken = searchParams.get("token");

    if (resetToken) {
      try {
        const decoded: JwtPayload = jwt_decode(resetToken);
        const now = new Date();
        const expirationDate = decoded.exp
          ? new Date(decoded.exp * 1000)
          : new Date(0);
        if (now > expirationDate) {
          setExpirationModalOpened(true);
        } else {
          setActiveForm("new-password");
        }
      } catch (error) {
        setExpirationModalOpened(true);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    setHaveErrors(!isEmailValid);
  }, [isEmailValid]);

  const sendResetLink = () => {
    if (haveErrors) {
      setShowErrors(true);
      return;
    }
    userService.sendPasswordResetEmail(email);
    setActiveForm("check-email");
  };

  const handleResend = () => {
    setActiveForm("enter-email");
  };

  const getFormTitle = () =>
    isEnterEmail
      ? "Reset your password"
      : isCheckEmail
      ? "Thank you."
      : "Reset your password";

  const getFormDescription = () =>
    isEnterEmail
      ? "Enter the email address associated with your account and we'll send you a link to reset your password."
      : isCheckEmail
      ? "Check your email for instructions on how to reset your password."
      : "";

  const handleSaveNewPassword = () => {
    setShowDontMatchError(false);

    if (password !== confirmPassword) {
      setShowErrors(true);
      console.log("password doesn't match");
      setShowDontMatchError(true);
      return;
    }

    if (haveErrors) {
      setShowErrors(true);
      return;
    }

    setIsLoading(true);

    userService
      .saveNewPassword(token, password)
      .then((res: any) => {
        navigate("/");
      })
      .catch((err: any) => {
        console.log("err", err);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <FormContainer>
        <h2 className="FormComponent-title">{getFormTitle()}</h2>
        <p className="FormComponent-description">{getFormDescription()}</p>
        {isEnterEmail && (
          <InputField
            value={email}
            onChange={handleChangeEmail}
            error={emailErrors[0]}
            showError={showErrors}
            label="Email"
            id="email"
            type="email"
            showReturnToSignUp
          />
        )}

        {isNewPassword && (
          <>
            <InputField
              value={password}
              onChange={handleChangePassword}
              showError={showErrors}
              passwordError={showErrors && passwordErrors[0]}
              label="New password"
              id="password"
              type="password"
              hideForgotPasswordLink={true}
            />
            <InputField
              value={confirmPassword}
              onChange={handleChangeConfirmPassword}
              showError={showErrors}
              passwordError={showErrors && passwordErrors[0]}
              label="Confirm new password"
              id="password"
              type="password"
              hideForgotPasswordLink={true}
            />
            {showDontMatchError ? (
              <ValidationError
                show={showDontMatchError}
                error="Passwords do not match"
              />
            ) : (
              <PasswordErrors errors={passwordErrors} showErrors={showErrors} />
            )}
          </>
        )}

        <ActionsContainer>
          {isEnterEmail && (
            <Button
              text="Continue"
              size="large"
              color="blue"
              handleClick={sendResetLink}
              withArrow
            />
          )}
          {isCheckEmail && (
            <p className={styles.resendText}>
              If you haven&apos;t received an email in 5 minutes, check your
              spam or{" "}
              <span
                className="link"
                role="button"
                tabIndex={0}
                onClick={handleResend}
              >
                try again
              </span>
              .
            </p>
          )}

          {isNewPassword && (
            <Button
              text="Continue"
              size="large"
              color="blue"
              handleClick={handleSaveNewPassword}
              withArrow
              isLoading={isLoading}
            />
          )}
        </ActionsContainer>

        {isEnterEmail && (
          <div className={styles.signUp_cta}>
            Don’t have an account? <Link to="/register">Sign up here</Link>
          </div>
        )}
      </FormContainer>
      <Modal
        isOpened={expirationModalOpened}
        handleClose={() => setExpirationModalOpened(false)}
        title="Password reset link expired"
        buttons={
          <>
            <Button
              text="Continue"
              color="blue"
              size="small"
              handleClick={() => setExpirationModalOpened(false)}
            />
          </>
        }
      >
        <div className={styles.removeBrMobile}>
          Please request a new reset link.
        </div>
      </Modal>
    </>
  );
}
