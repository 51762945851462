import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import MuiModal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import classNames from "classnames";
import client, { Events } from "services/EventEmitter";
import styles from "./styles.module.scss";

interface Props {
  isOpened: boolean;
  handleClose: () => void;
  title: string | undefined;
  children: JSX.Element;
  buttons: JSX.Element;
  stackButtons?: boolean;
  classes?: any;
  warningColor?: boolean;
}
const modalDiv = document.getElementById("modal") as HTMLElement;

function Modal({
  isOpened,
  handleClose,
  title,
  children,
  buttons,
  stackButtons,
  classes,
  warningColor = false,
}: Props) {
  const [offset, setOffset] = useState(window.scrollY);

  useEffect(() => {
    if (isOpened) {
      setOffset(window.scrollY);
      client.emit(Events.DISABLE_SCROLL, window.scrollY);
      return;
    }
    client.emit(Events.ENABLE_SCROLL, offset);
  }, [isOpened]);

  return createPortal(
    <MuiModal
      open={isOpened}
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={title}
      disableScrollLock={true}
      BackdropProps={{
        timeout: 100,
      }}
    >
      <Fade in={isOpened} timeout={50} easing="0">
        <div className={styles.modalWrapper}>
          <h3
            className={classNames([styles.modalTitle], {
              [styles.warningColor]: warningColor,
            })}
          >
            {title}
          </h3>
          <div className={styles.modalDivider} />
          <section className={classNames([styles.modalContainer], [classes])}>
            {children}
          </section>
          <div
            className={classNames([styles.modalActions], {
              [styles.stackButtons]: stackButtons,
            })}
          >
            {buttons}
          </div>
        </div>
      </Fade>
    </MuiModal>,
    modalDiv
  );
}

export default Modal;
