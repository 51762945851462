import { ReactComponent as ErrorIcon } from "assets/images/errorIcon.svg";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface Props {
  show: boolean | undefined;
  error: string | undefined;
  className?: string | undefined;
}

function ValidationError({ show, error, className }: Props) {
  if (!show || !error) return null;

  return (
    <p
      className={classNames([styles.errorWrapper], className, {
        [styles.positionRelative]: true,
      })}
    >
      <ErrorIcon className={styles.errorIcon} />
      <p className={styles.error}>{error}</p>
    </p>
  );
}

export default ValidationError;
