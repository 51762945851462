import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "redux/auth.slice";
import userReducer from "redux/user.slice";
import registrationReducer from "redux/registration.slice";
import pcpFlowReducer from "redux/pcpFlow.slice";
import bookAppointmentFlowReducer from "./bookAppointmentFlow.slice";
import insuranceReducer from "./insurance.slice";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["registration", "pcpFlow", "bookingAppointment"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  insurance: insuranceReducer,
  registration: registrationReducer,
  pcpFlow: pcpFlowReducer,
  bookingAppointment: bookAppointmentFlowReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// Types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
