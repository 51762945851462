import { httpGet, httpPost } from "services/httpClient";

class BookingService {
  findAppointmentByDate = async (params: {}) => {
    try {
      const res = await httpGet("appointment/availability-by-day", params);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  bookAppointment = async (params: {}) => {
    try {
      const res = await httpPost("appointment", params);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  isAfterHours = async (params: {}) => {
    try {
      const res = await httpGet("appointment/currently-after-hours", params);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  isDateHoliday = async (params: {}) => {
    try {
      const res = await httpGet("appointment/holiday-status-by-day", params);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default new BookingService();
