import { ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "hooks/useAuth";

type IPublicOnlyRoute = ReactElement | null;

function PublicOnlyRoute(): IPublicOnlyRoute {
  const { isAuth } = useAuth();

  if (!isAuth) {
    return <Outlet />;
  }

  return <Navigate to="/" replace={true} />;
}

export default PublicOnlyRoute;
