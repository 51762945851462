import { useState, useEffect } from "react";
import PasswordErrors from "components/forms/RegisterForm/PasswordErrors";
import InputField from "components/ui/InputField";
import useValidate from "utils/validation";
import { VALIDATE_PASSWORD } from "utils/validation/constants";
import Button from "components/ui/Button";
import userService from "services/userService";
import ValidationError from "components/shared/ValidationError";
import classNames from "classnames";
import styles from "./styles.module.scss";

function LoginDetails({ handleClose }: { handleClose: Function }) {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [requestError, setRequestError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDontMatchError, setShowDontMatchError] = useState<boolean>(false);

  const [isPasswordValid, passwordErrors] = useValidate(
    password,
    VALIDATE_PASSWORD
  );

  const handleChangeCurrentPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setCurrentPassword(e.target.value);

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handleChangeConfirmPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setConfirmPassword(e.target.value);

  const [haveErrors, setHaveErrors] = useState<boolean>(true);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const clearState = () => {
    setCurrentPassword("");
    setPassword("");
    setConfirmPassword("");
    setRequestError("");
  };

  const handleSaveNewPassword = () => {
    setShowDontMatchError(false);

    if (currentPassword) {
      setRequestError("");
      setShowErrors(false);
    }

    if (!currentPassword) {
      setRequestError("Incorrect password");
      setShowErrors(true);
      return;
    }

    if (password !== confirmPassword) {
      setShowErrors(true);
      console.log("password doesn't match");
      setShowDontMatchError(true);
      return;
    }

    if (haveErrors) {
      // client.emit(Events.SCROLL_TO_TOP);
      setShowErrors(true);
      return;
    }

    setIsLoading(true);

    userService
      .changePassword(currentPassword, password)
      .then((res: any) => {
        clearState();
      })
      .catch((err: any) => {
        setRequestError(err.response.data.message);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setHaveErrors(!isPasswordValid);
  }, [isPasswordValid]);

  return (
    <>
      <div className={classNames({ [styles.loadingCover]: isLoading })}>
        <InputField
          value={currentPassword}
          onChange={handleChangeCurrentPassword}
          error={requestError}
          showError={Boolean(requestError)}
          label="CURRENT password"
          id="password"
          type="password"
          hideForgotPasswordLink={true}
        />
        <InputField
          value={password}
          onChange={handleChangePassword}
          showError={showErrors}
          passwordError={showErrors && passwordErrors[0]}
          label="NEW password"
          id="password"
          type="password"
          hideForgotPasswordLink={true}
        />
        <InputField
          value={confirmPassword}
          onChange={handleChangeConfirmPassword}
          showError={showErrors}
          passwordError={showErrors && passwordErrors[0]}
          label="Confirm NEW password"
          id="password"
          type="password"
          hideForgotPasswordLink={true}
        />
        {showDontMatchError ? (
          <ValidationError
            show={showDontMatchError}
            error="Passwords do not match"
          />
        ) : (
          <PasswordErrors errors={passwordErrors} showErrors={showErrors} />
        )}
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          text="Cancel"
          size="small"
          color="outlined_blue"
          handleClick={handleClose}
        />
        <Button
          text="Save changes"
          size="small"
          color="blue"
          handleClick={handleSaveNewPassword}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}

export default LoginDetails;
