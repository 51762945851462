import PageContainer from "components/layout/PageContainer";
import LoginForm from "components/forms/LoginForm";

function Login() {
  return (
    <div className="fullPage-blueBackground">
      <PageContainer>
        <LoginForm />
      </PageContainer>
    </div>
  );
}

export default Login;
