import classNames from "classnames";
import styles from "./styles.module.scss";

interface Props {
  id: number;
  title: string;
  description: string;
  form: JSX.Element;
  isExpanded: boolean;
  readOnly?: boolean;
  handleExpand: (id: number) => void;
}

function ExpandableForm({
  id,
  title,
  description,
  form,
  isExpanded,
  readOnly = false,
  handleExpand,
}: Props) {
  const handleEdit = () => handleExpand(id);
  const handleCancel = () => handleExpand(0);

  return (
    <div
      className={classNames({
        [styles.expandableWrapper]: !isExpanded,
        [styles.expandedFormWrapper]: isExpanded,
      })}
    >
      <div
        className={classNames([styles.expandableHead], {
          [styles.expandedHeader]: isExpanded,
        })}
      >
        <div className={styles.titleWrapper}>
          <p className={styles.title}>{title}</p>
          <div>
            {isExpanded ? (
              <span
                className={styles.cancel}
                role="button"
                tabIndex={0}
                onClick={handleCancel}
              >
                Cancel
              </span>
            ) : (
              <span
                className={styles.edit}
                role="button"
                tabIndex={0}
                onClick={handleEdit}
              >
                {readOnly ? "View" : "Edit"}
              </span>
            )}
          </div>
        </div>
        <p className={styles.description}>{description}</p>
      </div>
      {isExpanded && (
        <div className={styles.formContainer}>
          <div>{form}</div>
        </div>
      )}
    </div>
  );
}

export default ExpandableForm;
