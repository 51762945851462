// Validation function names
export const VALIDATE_FIRST_NAME = "validateFirstName";
export const VALIDATE_LAST_NAME = "validateLastName";
export const VALIDATE_DATE_OF_BIRTH = "validateDateOfBirth";
export const VALIDATE_IS_GENDER_SELECTED = "validateGenderSelected";
export const VALIDATE_EMAIL = "validateEmail";
export const VALIDATE_PASSWORD = "validatePassword";
export const VALIDATE_PHONE = "validatePhoneNumber";
export const VALIDATE_SECONDARY_PHONE = "validateSecondaryPhoneNumber";
export const VALIDATE_INSURANCE_ID = "validateInsuranceId";
export const VALIDATE_ADDRESS = "validateAddress";
export const VALIDATE_CITY = "validateCitySelected";
export const VALIDATE_STATE = "validateStateSelected";
export const VALIDATE_ZIP = "validateZip";
export const VALIDATE_PCP_NAME = "validatePcpName";
export const VALIDATE_PCP_ADDRESS = "validatePcpAddress";
export const VALIDATE_APPOINTMENT_PHONE_NUMBER =
  "validateAppointmentPhoneNumber";
export const VALIDATE_HEALTH_CONCERN_DESCRIPTION =
  "validateHealthConcernDescription";
export const VALIDATE_TIME_SLOT = "validateTimeSlot";
export const VALIDATE_INSURANCE_PROVIDER_SELECTED = "validateInsuranceProvider";
export const VALIDATE_INSURANCE_NUMBER_SELECTED = "validateInsuranceNumber";
export const VALIDATE_INSURANCE_REGISTERED_ADDRESS =
  "validateInsuranceRegisteredAddress";
