const enableScroll = (offset: number): void => {
  document.body.classList.remove("disable-scroll");
  window.scroll({ top: offset });
};

const disableScroll = (offset: number) => {
  document.body.style.top = `-${offset}px`;
  document.body.classList.add("disable-scroll");
};

export { disableScroll, enableScroll };
