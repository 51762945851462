import classNames from "classnames";
import PageContainer from "components/layout/PageContainer";
import LoadingSpinner from "components/shared/LoadingSpinner";
import styles from "./styles.module.scss";

const Loading = ({
  text = "Loading",
  withoutLoader = false,
  customShell = false,
}: {
  text?: string;
  withoutLoader?: boolean;
  customShell?: boolean;
}) => {
  return (
    <BackgroundWrapper customShell={customShell}>
      <PageContainer>
        <DashboardLoader
          text={text}
          withoutLoader={withoutLoader}
          customShell={customShell}
        />
      </PageContainer>
    </BackgroundWrapper>
  );
};

export const BackgroundWrapper = ({
  children,
  customShell = false,
}: {
  children: React.ReactNode;
  customShell?: boolean;
}) => (
  <div
    className={classNames("fullPage-blueBackground", {
      loadingStylesOverride: customShell,
    })}
  >
    {children}
  </div>
);

export const DashboardLoader = ({
  withoutLoader = false,
  text,
  customShell,
}: {
  withoutLoader?: boolean;
  customShell?: boolean;
  text: string;
}) => (
  <div
    className={classNames(styles.loadingFlex, {
      [styles.overrideHeight]: customShell,
    })}
  >
    <div className={styles.content}>
      {!withoutLoader && <LoadingSpinner size={30} />}
      <span className={styles.loadingText}>{text}</span>
    </div>
  </div>
);

export default Loading;
