import { parse, isValid, isFuture, isBefore } from "date-fns";
import { enUS } from "date-fns/locale";

export const isValidDate = (date: string) => {
  if (date.length !== 10) return false;

  const parsedDate = parse(date, "P", new Date(), {
    locale: enUS,
  });
  return isValid(parsedDate);
};

export const isFutureDate = (date: string) => {
  const parsedDate = parse(date, "P", new Date(), {
    locale: enUS,
  });

  return isFuture(parsedDate);
};

export const isOlderThan1900 = (date: string) => {
  const parsedDate = parse(date, "P", new Date(), {
    locale: enUS,
  });

  return isBefore(parsedDate, new Date(1900, 1, 1));
};

export const isValidEmail = (email: string) =>
  email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

export const hasNumber = (value: string) => /\d/.test(value);

export const hasUpperCase = (value: string) => /[A-Z]/.test(value);

export const hasSymbol = (value: string) =>
  value.match(/[!@#$%^&*(),.?":{}|<>]/);

export const longerThanEightChars = (value: string) => {
  return value.length > 8;
};

export const onlyNumbers = (value: string) => /^\d+$/.test(value);

export const isZipCodeValid = (value: string) =>
  /^\d{5}(?:[-\s]\d{4})?$/.test(value);

export const validateMonthDayYear = (mm: string, dd: string, yyyy: string) => {
  let isMonthValid: boolean = true;
  let isDayValid: boolean = true;
  let isYearValid: boolean = true;

  if (mm < "01" || mm > "12" || mm?.length !== 2) {
    isMonthValid = false;
  }
  if (dd < "01" || dd > "31" || dd?.length !== 2) {
    isDayValid = false;
  }
  if (
    yyyy < "1900" ||
    yyyy > new Date().getFullYear().toString() ||
    yyyy?.length !== 4
  ) {
    isYearValid = false;
  }

  const is30DayMonth = mm === "04" || mm === "06" || mm === "09" || mm === "11";
  if (is30DayMonth && dd === "31") {
    isMonthValid = false;
    isDayValid = false;
  }

  const isLeap = new Date(Number(yyyy), 1, 29).getDate() === 29;

  if (mm === "02" && dd === "29" && !isLeap) {
    isMonthValid = false;
    isDayValid = false;
  }

  if (mm === "02" && dd > "29") {
    isDayValid = false;
    if (dd === "30" || dd === "31") {
      isMonthValid = false;
    }
  }

  return [isMonthValid, isDayValid, isYearValid];
};
