import * as React from "react";
import { Box, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { hover } from "@testing-library/user-event/dist/hover";

interface IProps extends TooltipProps {
  button?: JSX.Element;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#DAE1E6",
    color: "#A80000",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#9c9c9c",
      [`& .${tooltipClasses.arrow}`]: {
        color: "#9c9c9c",
      },
    },
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#DAE1E6",
  },
}));

const ActionTooltip: React.FunctionComponent<IProps> = (props) => {
  const { children, button, ...rest } = props;

  return (
    <Box display="flex">
      <StyledTooltip arrow {...rest}>
        {children}
      </StyledTooltip>
    </Box>
  );
};

export default ActionTooltip;
