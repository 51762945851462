import { Outlet, NavLink } from "react-router-dom";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface Props {
  form: ReturnType<typeof Outlet>;
}

type NavlinkClassNames =
  | string
  | ((props: { isActive: boolean }) => string | undefined)
  | undefined;

const getClassNames: NavlinkClassNames = ({
  isActive,
}: {
  isActive: boolean;
}) => {
  return classNames([styles.navLink], {
    [styles.active_navLink]: isActive,
  });
};

function DashboardShell({ form }: Props) {
  return (
    <div className={styles.dashboardShell_wrapper}>
      <div className={styles.dashboardShell_menu}>
        <NavLink to="appointments" className={getClassNames}>
          Appointments
        </NavLink>
        {/* 
        <NavLink to="medical-information" className={getClassNames}>
          Medical information
        </NavLink> 
        */}
        <NavLink to="profile" className={getClassNames}>
          My profile
        </NavLink>
        <NavLink to="documents" className={getClassNames}>
          Send Document
        </NavLink>
        <NavLink to="chat" className={getClassNames}>
          Send Message
        </NavLink>
      </div>
      <section className={styles.dashboardShell_container}>{form}</section>
    </div>
  );
}

export default DashboardShell;
