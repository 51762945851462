import { Registration } from "redux/registration.slice";

const createNewUserFromState = (registrationState: Registration) => {
  const { firstStep, secondStep, thirdStep } = registrationState;

  const dob = firstStep.dateOfBirth.replaceAll("/", "-");
  const phoneNo = [
    thirdStep.phoneNumber,
    thirdStep.secondaryPhoneNumber,
  ].filter((val) => !!val);

  return {
    email: secondStep.email,
    password: secondStep.password,
    firstName: firstStep.firstName,
    lastName: firstStep.lastName,
    dateOfBirth: dob,
    sexAtBirth: firstStep.gender,
    gender: firstStep.gender,
    phoneNo,
    zipCode: thirdStep.zip,
    city: thirdStep.city,
    state: thirdStep.state,
    addressLine: [thirdStep.address],
    insuranceId: thirdStep.insuranceId,
  };
};

export default createNewUserFromState;
