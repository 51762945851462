import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import userService from "services/userService";
import type { RootState } from "./store";

export enum DocumentType {
  INSURANCE = "Insurance",
  MISC = "Misc",
}

export interface InsuranceDocument {
  id: string;
  title: string;
  side: number;
  type: {
    id?: string;
    type: DocumentType;
  };
}

interface InsuranceDTO {
  membershipId: string;
  registeredAddress: string;
}

export interface InsuranceDTORequest extends InsuranceDTO {
  provider: string;
  rank: 1 | 2;
}

export interface InsuranceDTOResponse extends InsuranceDTO {
  active: boolean;
  id: string;
  membershipId: string;
  rank: number;
  adress: string;
  provider: {
    id: string;
    name: string;
  };
  documents: InsuranceDocument[];
}

// type InsuranceInitialState = Omit<InsuranceDTOResponse, "documents"> & Pick<>;
interface InsuranceInitialState {
  insurance: InsuranceDTOResponse[] | [];
  isLoading: boolean;
}

const initialState: InsuranceInitialState = {
  isLoading: false,
  insurance: [],
};

export const fetchUserInsurance = createAsyncThunk(
  "use/fetchInsurance",
  async (_, { rejectWithValue }) =>
    userService.getInsuranceDetails().catch((err) => {
      console.log("insurance data error error: ", err);
      return rejectWithValue("User data could not be retrieved");
    })
);

export const insuranceSlice = createSlice({
  name: "insurance",
  initialState,
  reducers: {
    updateInsuranceData: (
      state,
      action: PayloadAction<InsuranceDTOResponse>
    ) => {
      state.insurance[0] = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUserInsurance.fulfilled, (state, { payload }) => {
      state.insurance = payload.data;
    });
    builder.addCase(fetchUserInsurance.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserInsurance.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { updateInsuranceData } = insuranceSlice.actions;

// Selectors
export const selectInsurance = (state: RootState) =>
  state.insurance.insurance[0];
export const selectInsuranceData = createSelector(
  selectInsurance,
  (insurance) => ({
    provider: insurance?.provider,
    membershipId: insurance?.membershipId,
    registeredAddress: insurance?.registeredAddress,
    id: insurance?.id,
  })
);

export default insuranceSlice.reducer;
