import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/store";
import { selectPcpActiveStep, setPcpGoBack } from "redux/pcpFlow.slice";
import {
  selectRegistrationActiveStep,
  setRegistrationGoBack,
} from "redux/registration.slice";
import client, { Events } from "services/EventEmitter";
import { ReactComponent as BackStep } from "assets/images/backStep.svg";
import Button from "components/ui/Button";
import {
  selectBookingActiveStep,
  setBookingAppointmentGoBack,
} from "redux/bookAppointmentFlow.slice";
import { flowAppointment } from "routes/paths";
import styles from "./styles.module.scss";

interface IProps {
  form: string | false;
}

function MobileFormNav({ form }: IProps) {
  const dispatch = useAppDispatch();
  const registrationActiveStep = useAppSelector(selectRegistrationActiveStep);
  const pcpActiveStep = useAppSelector(selectPcpActiveStep);
  const bookingActiveStep = useAppSelector(selectBookingActiveStep);

  const handleRegisterGoBack = () => dispatch(setRegistrationGoBack());
  const handlePcpGoBack = () => dispatch(setPcpGoBack());
  const handleBookinigAppointmentGoBack = () =>
    dispatch(setBookingAppointmentGoBack());
  const handleExitRegistration = () =>
    client.emit(Events.REGISTER_OPEN_EXIT_MODAL);
  const handleExitPcp = () => client.emit(Events.PCP_OPEN_EXIT_MODAL);
  const handleExitBookingAppointment = () =>
    client.emit(Events.BOOKING_OPEN_EXIT_MODAL);

  return (
    <div className={styles.mobileFormNav}>
      {form === "register" && (
        <Button
          text="Exit"
          size="small"
          color="outlined_blue"
          handleClick={handleExitRegistration}
        />
      )}
      {form === "register" && registrationActiveStep > 1 && (
        <BackStep
          onClick={handleRegisterGoBack}
          className={styles.backStepAction}
        />
      )}

      {form === "pcp" && (
        <Button
          text="Exit"
          size="small"
          color="outlined_blue"
          handleClick={handleExitPcp}
        />
      )}
      {form === "pcp" && pcpActiveStep > 1 && (
        <BackStep onClick={handlePcpGoBack} className={styles.backStepAction} />
      )}

      {form === flowAppointment && (
        <Button
          text="Exit"
          size="small"
          color="outlined_blue"
          handleClick={handleExitBookingAppointment}
        />
      )}
      {form === flowAppointment && bookingActiveStep > 1 && (
        <BackStep
          onClick={handleBookinigAppointmentGoBack}
          className={styles.backStepAction}
        />
      )}
    </div>
  );
}

export default MobileFormNav;
