import { useState, useEffect, ChangeEvent, ReactNode } from "react";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import styles from "./styles.module.scss";

interface Props {
  options: Option[];
  activeValue: string | null;
  onChange: Function;
  groupLabel: string;
  description?: ReactNode;
  required?: boolean;
  grayStroke?: boolean;
}

interface Option {
  value: string;
  label: string;
}

export default function RadioButton({
  options,
  activeValue,
  onChange,
  groupLabel,
  description,
  required = true,
  grayStroke = false,
}: Props) {
  const [selected, setSelected] = useState<string | null>(activeValue);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelected((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  const displayLabel = `${groupLabel}${required ? "*" : " (optional)"}`;

  return (
    <div className={styles.radioGroupWrapper}>
      <InputLabel className={styles.label}>{displayLabel}</InputLabel>
      {description}
      <RadioGroup
        aria-labelledby={groupLabel}
        name={groupLabel}
        value={selected}
        onChange={handleChange}
      >
        {options.map(({ value, label }) => (
          <FormControlLabel
            value={value}
            className={styles.radioFormControl}
            classes={{
              root: styles.controlRoot,
            }}
            control={
              <Radio
                disableFocusRipple
                disableRipple
                disableTouchRipple
                icon={<RadioFigma grayStroke />}
                checkedIcon={<RadioFigma checked grayStroke />}
                classes={{ root: styles.radioRoot }}
              />
            }
            label={label}
          />
        ))}
      </RadioGroup>
    </div>
  );
}

const RadioFigma = ({
  checked,
  grayStroke,
}: {
  checked?: boolean;
  grayStroke: boolean;
}) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="43" height="43" rx="21.5" fill="white" />
    <circle cx="22.5" cy="22.5" r="15.5" fill={checked ? "#3E8B4E" : ""} />
    <rect
      x="1"
      y="1"
      width="43"
      height="43"
      rx="21.5"
      stroke={grayStroke && !checked ? "#9C9C9C" : "#3E8B4E"}
      strokeWidth={grayStroke ? "1" : "2"}
    />
  </svg>
);
