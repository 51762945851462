import PageContainer from "components/layout/PageContainer";
import ResetPasswordForm from "components/forms/ResetPassword";

function ResetPassword() {
  return (
    <div className="fullPage-blueBackground">
      <PageContainer>
        <ResetPasswordForm />
      </PageContainer>
    </div>
  );
}

export default ResetPassword;
