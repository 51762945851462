import {
  ERROR_PASSWORD_LENGTH,
  ERROR_PASSWORD_ONE_NUMBER,
  ERROR_PASSWORD_ONE_SYMBOL,
  ERROR_PASSWORD_ONE_UPPERCASE_LETTER,
} from "utils/validation/messages";
import { ReactComponent as GreenCheckmark } from "assets/images/fullGreenCheckmark.svg";
import { ReactComponent as ErrorIcon } from "assets/images/circleErrorIcon.svg";
import styles from "./styles.module.scss";

interface Props {
  errors: string[];
  showErrors: boolean;
}

function PasswordErrors({ errors, showErrors }: Props) {
  return (
    <div className={styles.passwordValidation_box}>
      <SingleError
        errors={errors}
        showErrors={showErrors}
        error={ERROR_PASSWORD_ONE_NUMBER}
      />
      <SingleError
        errors={errors}
        showErrors={showErrors}
        error={ERROR_PASSWORD_ONE_UPPERCASE_LETTER}
      />
      <SingleError
        errors={errors}
        showErrors={showErrors}
        error={ERROR_PASSWORD_ONE_SYMBOL}
      />
      <SingleError
        errors={errors}
        showErrors={showErrors}
        error={ERROR_PASSWORD_LENGTH}
      />
    </div>
  );
}

const SingleError = ({
  errors,
  showErrors,
  error,
}: {
  errors: string[];
  showErrors: boolean;
  error: string;
}) => {
  if (!errors.includes(error)) {
    return (
      <p className={styles.validPasswordCheckmark}>
        <GreenCheckmark />
        {error}
      </p>
    );
  }

  if (!showErrors) {
    return <p>{error}</p>;
  }

  return (
    <p className={styles.passwordError}>
      <ErrorIcon />
      {error}
    </p>
  );
};

export default PasswordErrors;
